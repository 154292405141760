export class CivilizationMapModel {
   id: string | null = null;
   name: string = "";
   minPlayers: number | null = null;
   maxPlayers: number | null = null;

   public static sortByName(leader1: CivilizationMapModel, leader2: CivilizationMapModel) {
      if (leader1.name > leader2.name) {
         return 1;
      }

      if (leader1.name < leader2.name) {
         return -1;
      }

      return 0;
   }
}
