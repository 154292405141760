<h1 class="page-header">Orders of Battle</h1>
<div *ngIf="battleRecords?.length < 1">No battles on record</div>

<table>
   <tr>
      <th>Description</th>
      <th>Faction</th>
      <th>Mission</th>
      <th>Participants</th>
      <th class="view-column"> </th>
   </tr>

   <tr *ngFor="let battle of battleRecords" class="body-row">
      <td>{{ battle.description }}</td>
      <td>{{ battle.step }}</td>
      <td>{{ battle.mission }}</td>
      <th>{{ battle.participants}}</th>
      <td>
         <a [routerLink]="['/crusade/battle-records/' + battle.id]" class="button-link legacy">
            <i class="material-icons"> arrow_forward </i> view
         </a>
      </td>
   </tr>
</table>

<a [routerLink]="['add']" class="button-link legacy">Issue Challenge!</a>
