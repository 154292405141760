import { CreateCrusadeCardInput, UpdateCrusadeCardInput } from "src/app/API.service";
import { CrusadeCard } from "src/types/crusade-card";
import { v4 as uuidv4 } from "uuid";
import { CrusadeCardRanks } from "./crusade-card-rank.enum";
import { CrusadeHonourModel } from "./crusade-honour.model";

export class CrusadeCardModel {
   id: string | null = null;
   orderOfBattleID: string | null = null;
   name: string = "";
   powerRating: number = 0;
   crusadePoints: number = 0;
   experiencePoints: number = 0;
   battlefieldRole: string = "";
   crusadeFaction: string = "";
   selectableKeywords: string = "";
   unitType: string = "";
   equipment: string = "";
   psychicPowers: string = "";
   warlordTraits: string = "";
   relics: string = "";
   otherUpgrades: string = "";
   canGainExperience: boolean = true;
   isTitanic: boolean = true;
   battleTally: number = 0;
   killTally: number = 0;
   markedForGreatnessTally: number = 0;
   sortOrder: number = 0;
   isDeactivated: boolean = false;
   rank: CrusadeCardRanks = CrusadeCardRanks.Newb;

   honours: Array<CrusadeHonourModel> = new Array<CrusadeHonourModel>();

   rowExpanded: string = "expand_more";
   delete: boolean = false;

   uniqueIdentifier: string = uuidv4();

   static fromQueryModel(item: CrusadeCard): CrusadeCardModel {
      const result = new CrusadeCardModel();

      result.id = item.id;
      result.name = item.unitName;
      result.powerRating = item.powerRating;
      result.crusadePoints = item.crusadePoints;
      result.orderOfBattleID = item.orderOfBattleID;
      result.experiencePoints = item.experiencePoints ?? 0;
      result.crusadeFaction = item.crusadeFaction ?? "";
      result.battlefieldRole = item.battlefieldRole ?? "";
      result.selectableKeywords = item.selectableKeywords ?? "";
      result.unitType = item.unitType ?? "";
      result.equipment = item.equipment ?? "";
      result.psychicPowers = item.psychicPowers ?? "";
      result.warlordTraits = item.warlordTraits ?? "";
      result.relics = item.relics ?? "";
      result.otherUpgrades = item.otherUpgrades ?? "";
      result.canGainExperience = item.canGainExperience ?? true;
      result.isTitanic = item.isTitanic ?? true;
      result.battleTally = item.battleTally ?? 0;
      result.killTally = item.killTally ?? 0;
      result.markedForGreatnessTally = item.markedForGreatnessTally ?? 0;
      result.sortOrder = item.sortOrder ?? 0;
      result.rank = item.rank ?? CrusadeCardRanks.Newb;
      result.isDeactivated = item.isDeactivated ?? false;

      return result;
   }

   static toCreateModel(item: CrusadeCardModel): CreateCrusadeCardInput {
      if (!item.orderOfBattleID) {
         throw new Error("Cannot save card  - unknown order of battle");
      }
      const result: CreateCrusadeCardInput = {
         id: item.id,
         unitName: item.name,
         powerRating: item.powerRating,
         crusadePoints: item.crusadePoints,
         orderOfBattleID: item.orderOfBattleID,
         experiencePoints: item.experiencePoints,
         crusadeFaction: item.crusadeFaction,
         battlefieldRole: item.battlefieldRole,
         selectableKeywords: item.selectableKeywords,
         unitType: item.unitType,
         equipment: item.equipment,
         psychicPowers: item.psychicPowers,
         warlordTraits: item.warlordTraits,
         relics: item.relics,
         otherUpgrades: item.otherUpgrades,
         canGainExperience: item.canGainExperience,
         isTitanic: item.isTitanic,
         battleTally: item.battleTally,
         killTally: item.killTally,
         markedForGreatnessTally: item.markedForGreatnessTally,
         sortOrder: item.sortOrder,
         rank: item.rank,
         isDeactivated: item.isDeactivated,
      };
      return result;
   }

   static toUpdateModel(item: CrusadeCardModel): UpdateCrusadeCardInput {
      if (!item.orderOfBattleID) {
         throw new Error("Cannot save card  - unknown order of battle");
      }
      if (!item.id) {
         throw new Error("Cannot save card  - unknown id");
      }
      const result: UpdateCrusadeCardInput = {
         id: item.id,
         unitName: item.name,
         powerRating: item.powerRating,
         crusadePoints: item.crusadePoints,
         orderOfBattleID: item.orderOfBattleID,
         experiencePoints: item.experiencePoints,
         crusadeFaction: item.crusadeFaction,
         battlefieldRole: item.battlefieldRole,
         selectableKeywords: item.selectableKeywords,
         unitType: item.unitType,
         equipment: item.equipment,
         psychicPowers: item.psychicPowers,
         warlordTraits: item.warlordTraits,
         relics: item.relics,
         otherUpgrades: item.otherUpgrades,
         canGainExperience: item.canGainExperience,
         isTitanic: item.isTitanic,
         battleTally: item.battleTally,
         killTally: item.killTally,
         markedForGreatnessTally: item.markedForGreatnessTally,
         sortOrder: item.sortOrder,
         rank: item.rank,
         isDeactivated: item.isDeactivated,
      };
      return result;
   }
}
