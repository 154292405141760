import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";

export class FormHelper {
   static buildForm(model): FormGroup {
      const formGroup = new FormGroup({});

      const keys = Object.keys(model);
      keys.forEach((key) => {
         if (Array.isArray(model[key])) {
            const controls = new Array<FormGroup>();
            for (const item of model[key] as Array<any>) {
               controls.push(FormHelper.buildForm(item));
            }
            formGroup.addControl(key, new FormArray(controls));
         } else {
            formGroup.addControl(key, new FormControl(model[key]));
         }
      });

      return formGroup;
   }

   static getControl(parent: AbstractControl, ...controlName: Array<string>): AbstractControl {
      if (controlName.length < 1) {
         throw Error("Unable to locate control - control name is required");
      }
      let control: AbstractControl = parent;
      for (const name of controlName) {
         const childControl = control.get(name.toString());
         if (!childControl) {
            throw Error(`Unable to find control ${name} in form`);
         }
         control = childControl;
      }
      return control;
   }

   static getControlAsArray(parent: AbstractControl, ...controlName: Array<string>): FormArray {
      const control = FormHelper.getControl(parent, ...controlName);
      if (control instanceof FormArray) {
         return control;
      }
      throw Error("Unable to locate formArray - requested control is not a formArray");
   }

   static getErrors(control: AbstractControl): Array<string> {
      if (control.errors) {
         const errors = control.errors;
         return Object.keys(errors).map((key) => errors[key].message);
      } else {
         return new Array<string>();
      }
   }

   static disableForm(control: AbstractControl) {
      FormHelper.toggleFormEnabledState(control, false);
   }

   static enableForm(control: AbstractControl) {
      FormHelper.toggleFormEnabledState(control, true);
   }

   private static toggleFormEnabledState(control: AbstractControl, enable: boolean) {
      if (control instanceof FormArray) {
         const controls = (control as FormArray).controls;
         for (const child of controls) {
            FormHelper.toggleFormEnabledState(child, enable);
         }
      }
      if (control instanceof FormGroup) {
         const controls = (control as FormGroup).controls;
         for (const name in controls) {
            if (control[name] instanceof AbstractControl) {
               FormHelper.toggleFormEnabledState(controls[name], enable);
            }
         }
      }
      if (enable) {
         control.enable();
      } else {
         control.disable();
      }
   }
}
