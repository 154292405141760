import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormHelper } from "src/app/forms/form-helper";
import { DraftFormModel } from "./models/draft-form.model";

@Component({
   selector: "fun-draft-form",
   templateUrl: "./draft-form.component.html",
   styleUrls: ["./draft-form.component.scss"],
})
export class DraftFormComponent implements OnInit {
   @Input()
   formBusy: boolean = true;

   @Input()
   formDisabled: boolean = true;

   @Output()
   formSubmitted = new EventEmitter<DraftFormModel>();

   formGroup: FormGroup = FormHelper.buildForm(new DraftFormModel());

   constructor() {}

   ngOnInit() {}

   onSubmit() {
      this.formSubmitted.next(this.formGroup.getRawValue() as DraftFormModel);
   }
}
