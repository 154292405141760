import { UpdateCivilizationDraftParticipantInput } from "src/app/API.service";
import { DraftParticipant } from "src/types/civilization-drafter/draft-participant";
import { User } from "src/types/user";
import { DraftedLeaderModel } from "./drafted-leader.model";

export class DraftParticipantModel {
   id: string | null = null;
   userID: string;
   civilizationDraftID: string;
   bannedLeaderID: string | null = null;
   bannedMapID: string | null = null;
   votedMapID: string | null = null;

   user: User | null = null;

   leaders: Array<DraftedLeaderModel> = new Array<DraftedLeaderModel>();

   constructor(userID: string, civilizationDraftID: string) {
      this.userID = userID;
      this.civilizationDraftID = civilizationDraftID;
   }

   static fromQueryModel(item: DraftParticipant): DraftParticipantModel {
      const result = new DraftParticipantModel(item.userID, item.civilizationDraftID);

      result.id = item.id;
      result.bannedLeaderID = item.bannedLeaderID;
      result.bannedMapID = item.bannedMapID;
      result.votedMapID = item.votedMapID;

      if (item.user) {
         result.user = item.user;
      }

      if (item.draftedLeaders && item.draftedLeaders.items) {
         const leaders = new Array<DraftedLeaderModel>();
         for (const leader of item.draftedLeaders.items) {
            if (leader) {
               leaders.push(DraftedLeaderModel.fromQueryModel(leader));
            }
         }
         result.leaders = leaders;
      }

      return result;
   }

   static toUpdateModel(item: DraftParticipantModel): UpdateCivilizationDraftParticipantInput {
      if (!item.id) {
         throw new Error("Cannot save Order of Battle  - unknown id");
      }
      const result: UpdateCivilizationDraftParticipantInput = {
         id: item.id,
         userID: item.userID,
         civilizationDraftID: item.civilizationDraftID,
         bannedLeaderID: item.bannedLeaderID,
         bannedMapID: item.bannedMapID,
         votedMapID: item.votedMapID,
      };
      return result;
   }
}


