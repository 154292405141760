import { Component, OnInit } from "@angular/core";

@Component({
  selector: "fun-fundamegapocalypse",
  templateUrl: "./fundamegapocalypse.component.html",
  styleUrls: ["./fundamegapocalypse.component.scss"]
})
export class FundamegapocalypseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
