import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
   APIService,
   CreateCivilizationDraftDraftedMapsMutation,
   CreateCivilizationDraftParticipantDraftedLeadersInput
} from "src/app/API.service";
import { CurrentUserService } from "src/app/authentication/current-user.service";
import { CivilizationLeader } from "src/types/civilization-drafter/civilization-leader";
import { CivilizationMap } from "src/types/civilization-drafter/civilization-map";
import { DraftedLeader } from "src/types/civilization-drafter/drafted-leader";
import { User } from "src/types/user";
import { DraftFormModel } from "../draft-form/models/draft-form.model";
import { DraftParticipantModel } from "../draft-form/models/draft-participant.model";
import { DraftDataHelper } from "../helpers/draft-data.helper";

@Component({
   selector: "fun-draft-details",
   templateUrl: "./draft-details.component.html",
   styleUrls: ["./draft-details.component.scss"],
})
export class DraftDetailsComponent implements OnInit, OnDestroy {
   private static idParamKey = "id";

   private static joinCodeQueryParamKey = "joinCode";

   formBusy = true;

   formDisabled = true;

   draft: DraftFormModel | null = null;

   currentUser: Observable<User | null>;

   inviteLink: string = "";

   joinCodeFromUrl: string = "";

   leaders = new Array<CivilizationLeader>();

   maps = new Array<CivilizationMap>();

   constructor(
      private activatedRoute: ActivatedRoute,
      private apiService: APIService,
      private currentUserService: CurrentUserService
   ) {
      this.currentUser = this.currentUserService.userChanged;
   }

   private id: string | null = null;

   private unsubscribe = new Subject<void>();

   ngOnInit() {
      this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
         this.id = params[DraftDetailsComponent.idParamKey];
         if (!this.id) {
            throw new Error("Unable to load order of battle details - bad id");
         }
         this.load();
      });
      this.currentUser.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.load();
      });
      this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
         this.joinCodeFromUrl = queryParams[DraftDetailsComponent.joinCodeQueryParamKey];
      });

      DraftDataHelper.ListLeaders(this.apiService).then((leaders) => (this.leaders = leaders));
      DraftDataHelper.ListMaps(this.apiService).then((maps) => (this.maps = maps));
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onJoinDraftClicked(joinCode: string) {
      this.formBusy = true;
      if (!this.draft || this.draft.secretKey !== joinCode) {
         alert("Unable to join draft - invalid join code");
         return;
      }
      if (!this.draft.id || !this.currentUserService.currentUser?.id) {
         alert("Unable to join draft - unable to determine current user");
         return;
      }
      this.apiService
         .CreateCivilizationDraftParticipant({
            civilizationDraftID: this.draft.id,
            userID: this.currentUserService.currentUser.id,
         })
         .then((result) => {
            this.id = result.civilizationDraftID;
            this.load();
         })
         .catch((err) => {
            alert("Unable to join draft - an error ocurred while joining");
            console.log(err);
         });
   }

   onFormSubmitted(draft: DraftFormModel) {
      this.formBusy = true;
      if (!this.currentUserService.currentUser || !this.currentUserService.currentUser.id) {
         alert("Unable to save Draft. Unable to determine logged in user.");
         return;
      }
      draft.ownerID = this.currentUserService.currentUser.id;
      this.apiService
         .UpdateCivilizationDraft(DraftFormModel.ToUpdateModel(draft))
         .then((event) => {
            alert("Saved Successfully");
            this.id = event.id;
            this.load();
         })
         .catch((e) => {
            console.log("error updating Draft...", e);
            alert(`Error updating Draft: \r\n${e}`);
         });
   }

   onParticipationFormSubmitted(participant: DraftParticipantModel) {
      this.formBusy = true;

      if (!this.draft || !this.draft.id || !this.currentUserService.currentUser?.id) {
         alert("Unable to update preferences - unable to determine current draft or user");
         return;
      }
      this.apiService
         .UpdateCivilizationDraftParticipant(DraftParticipantModel.toUpdateModel(participant))
         .then((result) => {
            this.id = result.civilizationDraftID;
            this.load();
         })
         .catch((err) => {
            alert("Unable to join draft - an error ocurred while joining");
            console.log(err);
         });
   }

   private reload() {
      if (!this.id) {
         alert("Unable to reload draft - unable to determine which draft to load");
         return;
      }
      this.load();
   }

   private load() {
      if (!this.id || !this.currentUserService.currentUser) {
         return;
      }
      if (this.currentUserService.currentUser) {
         this.apiService
            .GetCivilizationDraft(this.id)
            .then((result) => {
               this.draft = DraftFormModel.fromQueryModel(result);
               this.formBusy = false;
               this.formDisabled = false;

               const currentPage = window.location.href.split("?")[0];
               this.inviteLink = `${currentPage}?joinCode=${this.draft?.secretKey}`;
            })
            .catch((err) => console.log(err));
      }
   }

   onDraftMapsClicked() {
      if (!this.draft || !this.draft.id) {
         alert("Unable to draft maps - draft not loaded");
         return;
      }
      if (this.draft.participants.length < 1) {
         alert("Unable to draft maps - need at least 1 participant first");
         return;
      }
      this.formBusy = true;

      const legalMaps = this.maps.filter(
         (m) =>
            (m.minPlayers == null || m.minPlayers <= (this.draft?.participants?.length ?? 0)) &&
            (m.maxPlayers == null || m.maxPlayers >= (this.draft?.participants?.length ?? 0))
      );

      const bannedMapIDs = this.draft.participants.map((p) => p.bannedMapID);

      const unbannedMaps = new Array<CivilizationMap>();
      for (const legalMap of legalMaps) {
         if (bannedMapIDs.indexOf(legalMap.id) >= 0) {
            continue;
         }
         unbannedMaps.push(legalMap);
      }

      const mapsForVote = 4;

      const draftedMaps = new Array<CivilizationMap>();

      for (let i = 0; i < mapsForVote; i++) {
         const randomIndex = Math.random() * unbannedMaps.length;
         draftedMaps.push(unbannedMaps.splice(randomIndex, 1)[0]);
      }
      const mapDraftPromises = new Array<Promise<CreateCivilizationDraftDraftedMapsMutation>>();
      for (const map of draftedMaps) {
         if (!map.id) {
            continue;
         }
         mapDraftPromises.push(
            this.apiService.CreateCivilizationDraftDraftedMaps({
               civilizationDraftID: this.draft.id,
               mapId: map.id,
            })
         );
      }

      Promise.all(mapDraftPromises).then(() => this.reload());
   }

   onDraftLeadersClicked() {
      if (!this.draft || !this.draft.id) {
         alert("Unable to draft leaders - draft not loaded");
         return;
      }
      if (this.draft.participants.length < 1) {
         alert("Unable to draft leaders - need at least 1 participant first");
         return;
      }
      if (this.draft.maps.length < 1) {
         alert("Unable to draft leaders - must draft maps first");
         return;
      }
      this.formBusy = true;

      const leadersPerPlayer = 3;

      const availableLeaders = new Array<CivilizationLeader>();

      for (const leader of this.leaders) {
         availableLeaders.push(leader);
      }

      const bannedLeaderIDs = this.draft.participants.map((p) => p.bannedLeaderID);

      const unbannedLeaders = new Array<CivilizationLeader>();
      for (const availableLeader of availableLeaders) {
         if (bannedLeaderIDs.indexOf(availableLeader.id) >= 0) {
            continue;
         }
         unbannedLeaders.push(availableLeader);
      }

      const draftedLeaderPromises = new Array<Promise<CreateCivilizationDraftParticipantDraftedLeadersInput>>();

      for (const participant of this.draft.participants) {
         if (!participant.id) {
            throw new Error("unable to draft - unable to determine participant");
         }

         for (let i = 0; i < leadersPerPlayer; i++) {
            const randomIndex = Math.random() * availableLeaders.length;
            const leader = availableLeaders.splice(randomIndex, 1)[0];
            if (!leader.id) {
               throw new Error("unable to draft - unable to determine leader");
            }
            const draftedLeader: DraftedLeader = {
               id: null,
               civilizationDraftParticipantID: participant.id,
               draftedLeaderID: leader.id,
               selected: false,
            };
            draftedLeaderPromises.push(this.apiService.CreateCivilizationDraftParticipantDraftedLeaders(draftedLeader));
         }
      }

      Promise.all(draftedLeaderPromises).then(() => this.reload());
   }
}
