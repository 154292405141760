import { APIService } from "src/app/API.service";
import { CivilizationLeader } from "src/types/civilization-drafter/civilization-leader";
import { CivilizationMap } from "src/types/civilization-drafter/civilization-map";
import { CivilizationLeaderModel } from "../models/civilization-leader.model";
import { CivilizationMapModel } from "../models/civilization-map.model";

export class DraftDataHelper {
   static ListLeaders(apiService: APIService): Promise<Array<CivilizationLeader>> {
      return apiService.ListCivilizationLeaders().then((result) => {
         let sortedItems = new Array<CivilizationLeader>();
         if (result && result.items) {
            const leaders = new Array<CivilizationLeader>();
            for (const leader of result.items) {
               if (leader) {
                  leaders.push(leader);
               }
            }
            sortedItems = leaders.sort(CivilizationLeaderModel.sortByName);
         }
         return sortedItems;
      });
   }

   static ListMaps(apiService: APIService): Promise<Array<CivilizationMap>> {
      return apiService.ListCivilizationMaps().then((result) => {
         let sortedItems = new Array<CivilizationMap>();
         if (result && result.items) {
            const leaders = new Array<CivilizationMap>();
            for (const map of result.items) {
               if (map) {
                  leaders.push(map);
               }
            }
            sortedItems = leaders.sort(CivilizationMapModel.sortByName);
         }
         return sortedItems;
      });
   }
}
