import {
   CreateCrusadeBattleParticipantInput,


   UpdateCrusadeBattleParticipantInput
} from "src/app/API.service";
import { OrderOfBattleModel } from "../order-of-battle.model";
import { CrusadeCardBattleRecordModel } from "./crusade-card-battle-record.model";

export class CrusadeBattleRecordParticipantModel {
   id: string | null = null;
   crusadeBattleRecordID: string | null = null;
   userID: string | null = null;

   orderOfBattleID: string | null = null;

   armyTotalPL: number = 0;
   armyTotalCrusadePoints: number = 0;
   bonusCommandPoints: number = 0;
   warlordCrusadeCardID: string | null = null;

   isAttacker: boolean = false;
   isGoingFirst: boolean = false;
   challengerOrderOfBattle: OrderOfBattleModel | null = null;
   selectedCrusadeCards: Array<CrusadeCardBattleRecordModel> = new Array<CrusadeCardBattleRecordModel>();

   static toCreateModel(item: CrusadeBattleRecordParticipantModel): CreateCrusadeBattleParticipantInput {
      if (!item.userID) {
         throw new Error("Cannot save participant  - unknown User");
      }
      if (!item.crusadeBattleRecordID) {
         throw new Error("Cannot save participant  - unknown Battle Record");
      }
      const result: CreateCrusadeBattleParticipantInput = {
         id: item.id,
         crusadeBattleRecordID: item.crusadeBattleRecordID,
         userID: item.userID,
         orderOfBattleID: item.orderOfBattleID,
         armyTotalPL: item.armyTotalPL,
         armyTotalCrusadePoints: item.armyTotalCrusadePoints,
         bonusCommandPoints: item.bonusCommandPoints,
         warlordCrusadeCardID: item.warlordCrusadeCardID,
         isAttacker: item.isAttacker,
         isGoingFirst: item.isGoingFirst,
      };
      return result;
   }

   static toUpdateModel(item: CrusadeBattleRecordParticipantModel): UpdateCrusadeBattleParticipantInput {
      if (!item.userID) {
         throw new Error("Cannot save participant  - unknown User");
      }
      if (!item.crusadeBattleRecordID) {
         throw new Error("Cannot save participant  - unknown Battle Record");
      }
      if (!item.id) {
         throw new Error("Cannot save participant  - unknown id");
      }
      const result: UpdateCrusadeBattleParticipantInput = {
         id: item.id,
         crusadeBattleRecordID: item.crusadeBattleRecordID,
         userID: item.userID,
         orderOfBattleID: item.orderOfBattleID,
         armyTotalPL: item.armyTotalPL,
         armyTotalCrusadePoints: item.armyTotalCrusadePoints,
         bonusCommandPoints: item.bonusCommandPoints,
         warlordCrusadeCardID: item.warlordCrusadeCardID,
         isAttacker: item.isAttacker,
         isGoingFirst: item.isGoingFirst,
      };
      return result;
   }
}
