<form class="legacy"  [class.disabled]="formDisabled" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
   <div class="fields">
      <div class="field-wrapper">
         <label>Combatant</label>
         <select [formControlName]="'participant1Id'">
            <option disabled selected>-- select a user --</option>
            <option *ngFor="let user of users; let i = index" [value]="user.id">
               {{ user.displayName }}
            </option>
         </select>
      </div>
      <div class="field-wrapper">
         <label>Combatant</label>
         <select [formControlName]="'participant2Id'">
            <option disabled selected>-- select a user --</option>
            <option *ngFor="let user of users; let i = index" [value]="user.id">
               {{ user.displayName }}
            </option>
         </select>
      </div>
      <div class="field-wrapper">
         <label>Battle Size</label>
         <select [formControlName]="'gameSizeID'">
            <option [value]="crusadeBattleSizes.CombatPatrol">Combat Patrol</option>
            <option [value]="crusadeBattleSizes.Incursion">Incursion</option>
            <option [value]="crusadeBattleSizes.StrikeForce">Strike Force</option>
            <option [value]="crusadeBattleSizes.Onslaught">Onslaught</option>
         </select>
      </div>
   </div>
   <br />
   <button type="submit" class="legacy"  [disabled]="formGroup.invalid || formBusy">
      <span *ngIf="!formBusy">Fight!</span>
      <i class="material-icons" *ngIf="formBusy">access_time</i>
   </button>
</form>
