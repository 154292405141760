import { Component, OnInit } from "@angular/core";
import { FundamalleusAPIService } from "src/fundamalleus-api.service";
import { DraftTableModel } from "./models/draft-table.model";

@Component({
   selector: "fun-draft-list",
   templateUrl: "./draft-list.component.html",
   styleUrls: ["./draft-list.component.scss"],
})
export class DraftListComponent implements OnInit {
   drafts: Array<DraftTableModel>;

   constructor(private fundamalleusAPIService: FundamalleusAPIService) {
      this.drafts = new Array<DraftTableModel>();
   }

   ngOnInit() {
      this.fundamalleusAPIService.ListDraftsForTable().then((event) => {
         if (event?.items) {
            const drafts = new Array<DraftTableModel>();
            for (const item of event.items) {
               if (item) {
                  drafts.push(DraftTableModel.fromQueryModel(item));
               }
            }
            drafts.sort(DraftTableModel.sortByDate);
            this.drafts = drafts;
         }
      });
   }
}
