import { Component, OnInit } from "@angular/core";

@Component({
  selector: "fun-fundapocalypse",
  templateUrl: "./fundapocalypse.component.html",
  styleUrls: ["./fundapocalypse.component.scss"]
})
export class FundapocalypseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
