import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { CurrentUserService } from "./current-user.service";

@Injectable()
export class AuthenticationRequiredGuard implements CanActivate {
   static returnUrlQueryParamName = "returnUrl";

   constructor(public currentUserService: CurrentUserService, public router: Router) {}

   canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.currentUserService.userChanged.pipe(
         map((user) => {
            if (user) {
               return true;
            } else {
               this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
               return false;
            }
         })
      );
   }

   canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.canActivate(route, state).pipe(take(1));
   }
}
