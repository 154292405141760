<h1 class="page-header">
   Fundapocalypse
</h1>
<p>
   Fundapocalypse consists of two main components: Updated datasheets, and optimized rules for huge games. Using these
   rules requires a copy of the Apocalypse box, since it uses the rule book and command asset cards.
</p>

<h1 class="page-header">
   Fundapocalypse Data Sheets
</h1>
<p>
   We are building and modifying the Apocalypse Datasheets to account for new models and rules since Apocalypse was
   released. You can follow the Fundapocalypse Datasheet project on github, which includes instructions for using the
   data in your copy of Battlescribe, and a wiki with our reasoning for various changes:
</p>
<a href="https://github.com/normanthesquid/wh40k-fundapocalypse" class="button-link legacy">
   Fundapocalypse Github Repository
</a>
<a href="https://github.com/normanthesquid/wh40k-fundapocalypse/wiki" class="button-link legacy">
   Fundapocalypse Github wiki
</a>
<a href="https://battlescribe.net/" class="button-link legacy">Battlescribe</a>

<br />

<br />

<h1 class="page-header">
   Instructions for using Fundapocalypse Battlescribe Data
</h1>
<p>
   Data Url: https://www.fundamalleus.com/assets/battlescribe-data/index.bsi
</p>
<p>
   On PC you go Manage Data (three rectangles) -> Import Data -> Import Data from URL -> use our data URL.
</p>
<p>
   On Mobile go Manage Data (three rectangles) -> 3 dots -> Add data index url -> use our data URL.
</p>

<br />

<h1 class="page-header">
   FUNDAMEGAPOCALYPSE
</h1>
<p
   >The FUNDAMEGAPOCALYPSE rules are designed to further optimize and streamline Fundapocalypse games for large numbers
   of players, roughly 10 or more.
</p>
<ol>
   <li>
      Two teams maximum
      <ol>
         <li>
            The game really bogs down when you have more than 2 people on each of 4 teams activating detachments - the
            game will only move as quickly as the slowest person on each team
         </li>
      </ol>
   </li>
   <li>
      Streamlined Command Assets
      <ol>
         <li>
            Instead of building a deck and drawing cards every turn, players build a Command Asset Array and acivate
            cards using command points
         </li>
         <li>
            Some Command Assets are banned, since they slow the game down.
         </li>
      </ol>
   </li>
</ol>
<p>
   <a [routerLink]="['/fundapocalypse/FUNDAMEGAPOCALYPSE']" class="button-link legacy">Comprehensive Rules</a>
</p>
