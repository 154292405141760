import { CreateCivilizationMapInput, UpdateCivilizationMapInput } from "src/app/API.service";
import { CivilizationMap } from "src/types/civilization-drafter/civilization-map";
import { CivilizationMapModel } from "../../models/civilization-map.model";

export class CivilizationMapAdminModel extends CivilizationMapModel {
   delete: boolean = false;

   static FromQueryModel(leader: CivilizationMap): any {
      const result = new CivilizationMapAdminModel();

      result.id = leader.id;
      result.name = leader.name;
      result.minPlayers = leader.minPlayers;
      result.maxPlayers = leader.maxPlayers;

      return result;
   }

   static toCreateModel(map: CivilizationMapAdminModel): CreateCivilizationMapInput {
      const result: CreateCivilizationMapInput = {
         id: map.id,
         name: map.name,
         minPlayers: map.minPlayers,
         maxPlayers: map.maxPlayers,
      };
      return result;
   }

   static toUpdateModel(map: CivilizationMapAdminModel): UpdateCivilizationMapInput {
      if (!map.id) {
         throw new Error("Cannot save map  - unknown id");
      }
      const result: UpdateCivilizationMapInput = {
         id: map.id,
         name: map.name,
         minPlayers: map.minPlayers,
         maxPlayers: map.maxPlayers,
      };
      return result;
   }
}
