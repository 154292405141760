import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { APIService } from "src/app/API.service";
import { CurrentUserService } from "src/app/authentication/current-user.service";
import { DraftFormModel } from "../draft-form/models/draft-form.model";

@Component({
   selector: "fun-add-draft",
   templateUrl: "./add-draft.component.html",
   styleUrls: ["./add-draft.component.scss"],
})
export class AddDraftComponent implements OnInit {
   formBusy = false;

   formDisabled = false;

   constructor(
      private currentUserService: CurrentUserService,
      private apiService: APIService,
      private router: Router
   ) {}

   ngOnInit() {}

   onFormSubmitted(draft: DraftFormModel) {
      this.formBusy = true;
      if (!this.currentUserService.currentUser || !this.currentUserService.currentUser.id) {
         alert("Unable to save Draft. Unable to determine logged in user.");
         return;
      }
      draft.ownerID = this.currentUserService.currentUser.id;
      this.apiService
         .CreateCivilizationDraft(DraftFormModel.ToCreateModel(draft))
         .then((event) => {
            alert("Saved Successfully");
            this.router.navigate([`civ-drafter/${event.id}`]);
         })
         .catch((e) => {
            console.log("error creating Draft...", e);
            alert(`Error creating Draft: \r\n${e}`);
         });
   }
}
