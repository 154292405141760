import { Component, OnInit } from "@angular/core";
import { FundamalleusAPIService } from "src/fundamalleus-api.service";
import { OrderOfBattleTableModel } from "./models/order-of-battle-table.model";

@Component({
   selector: "fun-crusade-order-of-battle-list",
   templateUrl: "./crusade-order-of-battle-list.component.html",
   styleUrls: ["./crusade-order-of-battle-list.component.scss"],
})
export class CrusadeOrderOfBattleListComponent implements OnInit {
   ordersOfBattle: Array<OrderOfBattleTableModel>;

   constructor(private fundamalleusAPIService: FundamalleusAPIService) {
      this.ordersOfBattle = new Array<OrderOfBattleTableModel>();
   }

   ngOnInit() {
      this.fundamalleusAPIService.ListOrdersOfBattleForTable().then((event) => {
         if (event.items) {
            for (const item of event.items) {
               if (item) {
                  this.ordersOfBattle.push(OrderOfBattleTableModel.fromQueryModel(item));
               }
            }
         }
      });
   }
}
