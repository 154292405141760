<h1 class="page-header">Date: {{ draft?.date?.toDateString() }}</h1>
<h1 class="page-header">{{ draftStatusMessage }}</h1>
<mat-tab-group>
   <mat-tab
      label="Results"
      [disabled]="(currentParticipationForm && currentParticipationForm.dirty) || (formGroup && formGroup.dirty)"
   >
      <div class="participants" *ngIf="currentUserParticipation || currentUserOwnsDraft">
         <br />
         <h1 class="page-header">
            Participants
         </h1>
         <div *ngIf="draft?.participants?.length < 1">No participants have joined</div>
         <ng-container *ngFor="let participant of draft?.participants">
            <div>{{ participant.user?.displayName }} </div>
            <ng-container *ngIf="!draft?.isBlind">
               <div class="drafted-leader" *ngFor="let leader of participant?.leaders">
                  <a *ngIf="leader.infoUrl" [href]="leader.infoUrl" target="_blank">{{ leader.name }} </a>
                  <label *ngIf="!leader.infoUrl">{{ leader.name }} </label>
               </div>
            </ng-container>
         </ng-container>
      </div>
      <div class="maps" *ngIf="currentUserParticipation || currentUserOwnsDraft">
         <br />
         <h1 class="page-header">
            Maps
         </h1>
         <div *ngIf="draftStatus == draftStatuses.WaitingForMapBans">
            <ng-container *ngIf="currentUserParticipation">
               Waiting for draft to start. Enter your preferences on the preferences tab
               <br />
            </ng-container>
            <button
               mat-raised-button
               color="primary"
               [disabled]="formGroup.invalid || formBusy"
               *ngIf="currentUserOwnsDraft"
               (click)="draftMaps()"
            >
               <span *ngIf="!formBusy">Draft Maps</span>
               <i class="material-icons" *ngIf="formBusy">access_time</i>
            </button>
         </div>
         <div *ngFor="let draftedMap of draftedMaps">{{ draftedMap }} </div>
      </div>
      <div>
         <br />
         <ng-container *ngIf="currentUserParticipation">
            <h1 class="page-header">
               Leaders
            </h1>
            <div *ngIf="currentUserParticipation.leaders.length == 0">
               Awaiting draft
            </div>
            <ng-container *ngIf="currentUserOwnsDraft && draftStatus == draftStatuses.WaitingForMapVotes">
               <br />
               <button
                  mat-raised-button
                  color="primary"
                  [disabled]="formGroup.invalid || formBusy"
                  *ngIf="currentUserOwnsDraft"
                  (click)="draftLeaders()"
               >
                  <span *ngIf="!formBusy">Draft Leaders</span>
                  <i class="material-icons" *ngIf="formBusy">access_time</i>
               </button>
               <br />
            </ng-container>
            <div *ngFor="let leader of currentUserParticipation.leaders">
               <a *ngIf="leader.infoUrl" [href]="leader.infoUrl" target="_blank">{{ leader.name }} </a>
               <label *ngIf="!leader.infoUrl">{{ leader.name }} </label>
            </div>
         </ng-container>

         <div *ngIf="!currentUserParticipation" class="join-draft-section">
            <br />
            You are not a participant in this draft
            <br />
            <br />
            <h1 class="page-header">
               Join Draft
            </h1>
            <mat-form-field *ngIf="draftStatus != draftStatuses.DraftReady">
               <mat-label>Join Code</mat-label>
               <input matInput [(ngModel)]="joinDraftCode" [ngModelOptions]="{ standalone: true }" />
               <button
                  mat-raised-button
                  matSuffix
                  color="primary"
                  [disabled]="formBusy || !joinDraftCode"
                  (click)="joinDraft()"
               >
                  <span *ngIf="!formBusy">Join Draft</span>
                  <i class="material-icons" *ngIf="formBusy">access_time</i>
               </button>
            </mat-form-field>
         </div>
      </div>
   </mat-tab>
   <mat-tab label="Preferences" *ngIf="currentUserParticipation" [disabled]="formGroup && formGroup.dirty">
      <ng-container *ngIf="currentUserParticipation && currentParticipationForm">
         <div>
            <br />
            <h1 class="page-header">
               My Draft Preferences
            </h1>
            <form
               [class.disabled]="formDisabled"
               [formGroup]="currentParticipationForm"
               (ngSubmit)="onSubmitParticipation()"
               autocomplete="off"
            >
               <mat-form-field>
                  <mat-label>Leader Ban</mat-label>
                  <select matNativeControl [formControlName]="'bannedLeaderID'">
                     <option value="" selected></option>
                     <option *ngFor="let leader of leaders" [value]="leader.id">
                        {{ leader.name }}
                     </option>
                  </select>
               </mat-form-field>
               <br />

               <mat-form-field>
                  <mat-label>Map Ban</mat-label>
                  <select matNativeControl [formControlName]="'bannedMapID'">
                     <option value="" selected></option>
                     <option *ngFor="let map of maps" [value]="map.id">
                        {{ map.name }}
                     </option>
                  </select>
               </mat-form-field>
               <br />
               <mat-form-field>
                  <mat-label>Map Vote</mat-label>
                  <select matNativeControl [formControlName]="'votedMapID'">
                     <option value=""></option>
                     <option *ngFor="let map of draft?.maps" [value]="map.mapID">
                        {{ map.name }}
                     </option>
                  </select>
               </mat-form-field>
               <br />
               <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  [disabled]="currentParticipationForm?.invalid || formBusy"
               >
                  <span *ngIf="!formBusy">Save</span>
                  <i class="material-icons" *ngIf="formBusy">access_time</i>
               </button>
            </form>
         </div>
      </ng-container>
   </mat-tab>
   <mat-tab
      label="Setup"
      *ngIf="currentUserOwnsDraft"
      [disabled]="currentParticipationForm && currentParticipationForm.dirty"
   >
      <br />
      <form [class.disabled]="formDisabled" [formGroup]="formGroup" (ngSubmit)="onSubmit()" autocomplete="off">
         <div class="fields">
            <mat-form-field>
               <mat-label>Date</mat-label>
               <input matInput [matDatepicker]="picker" [formControlName]="'date'" />
               <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="currentUserOwnsDraft"></mat-datepicker-toggle>
               <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
         </div>
         <mat-checkbox [formControlName]="'isBlind'">
            Blind Draft
         </mat-checkbox>
         <div class="invite-players-section">
            <br />
            <h1 class="page-header">
               Invite Players
            </h1>
            <mat-form-field>
               <mat-label>Join Code</mat-label>
               <input matInput [formControlName]="'secretKey'" />
               <button
                  mat-raised-button
                  matSuffix
                  type="button"
                  color="primary"
                  [disabled]="!inviteLink || formBusy || formGroup.controls['secretKey']?.dirty"
                  [cdkCopyToClipboard]="inviteLink"
                  *ngIf="draftStatus != draftStatuses.DraftReady"
               >
                  <span *ngIf="!formBusy">{{
                     formGroup.controls["secretKey"]?.dirty ? disabledInviteLinkText : inviteLinkText
                  }}</span>
                  <i class="material-icons" *ngIf="formBusy">access_time</i>
               </button>
            </mat-form-field>
            <br />
         </div>
         <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="formGroup.invalid || formBusy"
            [hidden]="!currentUserOwnsDraft"
         >
            <span *ngIf="!formBusy">Save</span>
            <i class="material-icons" *ngIf="formBusy">access_time</i>
         </button>
      </form>
   </mat-tab>
</mat-tab-group>
