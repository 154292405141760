import { Component, OnInit } from "@angular/core";
import { FundamalleusAPIService } from "src/fundamalleus-api.service";
import { CrusadeBattleRecordListModel } from "./models/crusade-battle-record-list.model";

@Component({
   selector: "fun-crusade-battle-record-list",
   templateUrl: "./crusade-battle-record-list.component.html",
   styleUrls: ["./crusade-battle-record-list.component.scss"],
})
export class CrusadeBattleRecordListComponent implements OnInit {
   battleRecords = new Array<CrusadeBattleRecordListModel>();

   constructor(private fundamalleusAPIService: FundamalleusAPIService) {}

   ngOnInit() {
      this.fundamalleusAPIService.ListBattleRecordsForTable().then((event) => {
         if (event.items) {
            for (const item of event.items) {
               if (item) {
                  this.battleRecords.push(CrusadeBattleRecordListModel.fromQueryModel(item));
               }
            }
         }
      });
   }
}
