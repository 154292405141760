import {
   CreateCrusadeOrderOfBattleInput,
   GetCrusadeOrderOfBattleQuery,
   UpdateCrusadeOrderOfBattleInput
} from "src/app/API.service";
import { CrusadeCard } from "src/types/crusade-card";
import { CrusadeHonour } from "src/types/crusade-honour";
import { CrusadeRequisition } from "src/types/crusade-requisition";
import { CrusadeCardModel } from "./crusade-card.model";
import { CrusadeHonourModel } from "./crusade-honour.model";
import { CrusadeRequisitionModel } from "./crusade-requisition.model";

export class OrderOfBattleModel {
   id: string | null = null;
   userId: string = "";
   name: string = "";
   faction: string = "";
   supplyLimit: number = 50;
   supplyUsed: number = 0;

   battleTally: number = 0;
   battlesWon: number = 0;
   requisitionPoints: number = 5;

   requisitionPointsUsed: number = 0;
   showInactiveCards: boolean = false;

   cards: Array<CrusadeCardModel> | null = new Array<CrusadeCardModel>();

   requisitions: Array<CrusadeRequisitionModel> | null = new Array<CrusadeRequisitionModel>();

   static fromQueryModel(
      item: GetCrusadeOrderOfBattleQuery,
      cards?: Array<CrusadeCard | null> | null,
      requisitions?: Array<CrusadeRequisition | null> | null,
      honours?: Array<CrusadeHonour | null> | null
   ): OrderOfBattleModel {
      const result = new OrderOfBattleModel();

      result.id = item.id;
      result.userId = item.userId;
      result.name = item.name;
      result.faction = item.faction;
      result.supplyLimit = item.supplyLimit;
      result.supplyUsed = item.supplyUsed;
      result.battleTally = item.battleTally ?? 0;
      result.battlesWon = item.battlesWon ?? 0;
      result.requisitionPoints = item.requisitionPoints ?? 0;

      result.cards = new Array<CrusadeCardModel>();

      const cardHonourMap = new Map<string, Array<CrusadeHonour>>();
      if (honours) {
         honours = honours.sort(this.sortHonours);
         for (const honour of honours) {
            if (honour) {
               if (!cardHonourMap.has(honour.crusadeCardID)) {
                  cardHonourMap.set(honour.crusadeCardID, new Array<CrusadeHonour>());
               }
               cardHonourMap.get(honour.crusadeCardID)?.push(honour);
            }
         }
      }

      const cardIDMap = new Map<string, string>();

      cards = cards ?? item.cards?.items;
      if (cards) {
         let cardModels = new Array<CrusadeCardModel>();
         for (const card of cards) {
            if (card) {
               const cardModel = CrusadeCardModel.fromQueryModel(card);
               if (cardModel.id) {
                  cardIDMap.set(cardModel.id, cardModel.uniqueIdentifier);

                  if (cardHonourMap.has(cardModel.id)) {
                     const matchingHonours = cardHonourMap.get(cardModel.id);
                     if (matchingHonours) {
                        cardModel.honours = matchingHonours.map((honour) => CrusadeHonourModel.fromQueryModel(honour));
                     }
                  }
               }
               cardModels.push(cardModel);
            }
         }
         cardModels = cardModels.sort((c1, c2) => OrderOfBattleModel.sortCards(c1, c2));

         result.cards = cardModels;
      }

      result.requisitions = new Array<CrusadeRequisitionModel>();

      requisitions = requisitions ?? item.requisitions?.items;
      if (requisitions) {
         requisitions = requisitions.sort(this.sortRequisitions);
         const requisitionModels = new Array<CrusadeRequisitionModel>();
         for (const requisition of requisitions) {
            if (requisition) {
               const requisitionModel = CrusadeRequisitionModel.fromQueryModel(requisition);
               if (requisitionModel.crusadeCardID && cardIDMap.has(requisitionModel.crusadeCardID)) {
                  requisitionModel.affectedCardIdentifier = cardIDMap.get(requisitionModel.crusadeCardID) ?? null;
               }
               requisitionModels.push(requisitionModel);
            }
         }
         result.requisitions = requisitionModels;
      }

      return result;
   }

   private static sortHonours(honour1: CrusadeHonour, honour2: CrusadeHonour) {
      if (honour1.createdAt > honour2.createdAt) {
         return 1;
      }

      if (honour1.createdAt < honour2.createdAt) {
         return -1;
      }

      return 0;
   }

   private static sortRequisitions(req1: CrusadeRequisition, req2: CrusadeRequisition) {
      if (req1.createdAt > req2.createdAt) {
         return 1;
      }

      if (req1.createdAt < req2.createdAt) {
         return -1;
      }

      return 0;
   }

   private static sortCards(card1: CrusadeCardModel, card2: CrusadeCardModel) {
      if (card1.sortOrder > card2.sortOrder) {
         return 1;
      }

      if (card1.sortOrder < card2.sortOrder) {
         return -1;
      }

      return 0;
   }

   static toCreateModel(item: OrderOfBattleModel): CreateCrusadeOrderOfBattleInput {
      const result: CreateCrusadeOrderOfBattleInput = {
         id: item.id,
         userId: item.userId,
         name: item.name,
         faction: item.faction,
         supplyLimit: item.supplyLimit,
         supplyUsed: item.supplyUsed,
         battleTally: item.battleTally,
         battlesWon: item.battlesWon,
         requisitionPoints: item.requisitionPoints,
      };
      return result;
   }

   static toUpdateModel(item: OrderOfBattleModel): UpdateCrusadeOrderOfBattleInput {
      if (!item.id) {
         throw new Error("Cannot save Order of Battle  - unknown id");
      }
      const result: UpdateCrusadeOrderOfBattleInput = {
         id: item.id,
         userId: item.userId,
         name: item.name,
         faction: item.faction,
         supplyLimit: item.supplyLimit,
         supplyUsed: item.supplyUsed,
         battleTally: item.battleTally,
         battlesWon: item.battlesWon,
         requisitionPoints: item.requisitionPoints,
      };
      return result;
   }
}
