<form [class.disabled]="formDisabled" [formGroup]="formGroup" (ngSubmit)="onSubmit()" autocomplete="off">
   <div class="fields">
      <mat-form-field appearance="fill">
         <mat-label>Join Code</mat-label>
         <input matInput [formControlName]="'secretKey'" />
      </mat-form-field>
      <mat-form-field appearance="fill">
         <mat-label>Date</mat-label>
         <input matInput [matDatepicker]="picker" [formControlName]="'date'" />
         <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
         <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
   </div>
   <br />
   <button mat-raised-button color="primary" type="submit" [disabled]="formGroup.invalid || formBusy">
      <span *ngIf="!formBusy">Save</span>
      <i class="material-icons" *ngIf="formBusy">access_time</i>
   </button>
</form>
