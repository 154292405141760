export enum CrusadeBattleRecordSteps {
   BattleCancelled = -1,
   ChallengeIssued = 0,
   ArmiesMustered = 1,
   MissionDetermined = 2,
   AgendasSelected = 3,
   PregameComplete = 4,
   InProgress = 5,
   DeterminingVictor = 6,
   SufferingCasualties = 7,
   AwardingExperience = 8,
   GameComplete = 9,
}
