import { CreateCivilizationDraftInput, UpdateCivilizationDraftInput } from "src/app/API.service";
import { DateHelper } from "src/app/dates/date-helper";
import { CivilizationDraft } from "src/types/civilization-drafter/civilization-draft";
import { DraftParticipantModel } from "./draft-participant.model";
import { DraftedMapModel } from "./drafted-map.model";

export class DraftFormModel {
   id: string | null = null;
   ownerID: string | null = null;
   date: Date | null = null;
   isBlind: boolean = false;
   winnerID: string | null = null;
   secretKey: string | null = null;

   maps = new Array<DraftedMapModel>();

   participants = new Array<DraftParticipantModel>();

   static fromQueryModel(item: CivilizationDraft): DraftFormModel {
      const result = new DraftFormModel();

      result.id = item.id;
      result.ownerID = item.ownerID;
      if (item.date) {
         result.date = DateHelper.ConvertDate(item.date);
      }
      result.winnerID = item.winnerID;
      result.secretKey = item.secretKey;
      result.isBlind = item.isBlind ?? false;

      if (item.maps && item.maps.items) {
         const maps = new Array<DraftedMapModel>();
         for (const map of item.maps.items) {
            if (map) {
               maps.push(DraftedMapModel.fromQueryModel(map));
            }
         }
         result.maps = maps;
      }

      if (item.participants && item.participants.items) {
         const participants = new Array<DraftParticipantModel>();
         for (const participant of item.participants.items) {
            if (participant) {
               participants.push(DraftParticipantModel.fromQueryModel(participant));
            }
         }
         result.participants = participants;
      }
      return result;
   }

   static ToCreateModel(draft: DraftFormModel): CreateCivilizationDraftInput {
      if (!draft.ownerID) {
         throw new Error("Cannot save draft  - unknown owner");
      }
      const result: CreateCivilizationDraftInput = {
         id: draft.id,
         ownerID: draft.ownerID,
         date: null,
         winnerID: draft.winnerID,
         secretKey: draft.secretKey,
         isBlind: draft.isBlind
      };

      if (draft.date) {
         result.date = new Date(draft.date).toISOString().split("T")[0];
      }
      return result;
   }

   static ToUpdateModel(draft: DraftFormModel): UpdateCivilizationDraftInput {
      if (!draft.ownerID) {
         throw new Error("Cannot save draft  - unknown owner");
      }
      if (!draft.id) {
         throw new Error("Cannot update draft  - unknown draft");
      }
      const result: UpdateCivilizationDraftInput = {
         id: draft.id,
         ownerID: draft.ownerID,
         date: null,
         winnerID: draft.winnerID,
         secretKey: draft.secretKey,
         isBlind: draft.isBlind
      };

      if (draft.date) {
         result.date = new Date(draft.date).toISOString().split("T")[0];
      }
      return result;
   }
}
