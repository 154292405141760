<div *ngIf="!loggingIn" class="top-bar">
   <a [routerLink]="['/']" class="fundamalleus-logo">
      <img class="logo-image" alt="Fundamalleus Logo" src="assets/big-hammer.png" />
      <span class="logo-text">FUNDAMALLEUS</span>
   </a>
   <button *ngIf="loggedIn" class="signout-button legacy" (click)="signOut()">
      Sign Out
   </button>
   <a *ngIf="!loggedIn" [routerLink]="['/login']" class="button-link signout-button legacy">
      Sign In
   </a>
</div>
<div *ngIf="loggingIn" class="fundamalleus-logo unauthorized-logo">
   <a [routerLink]="['/']" class="fundamalleus-logo">
      <img class="logo-image" alt="Fundamalleus Logo" src="assets/big-hammer.png" />
      <span class="logo-text">FUNDAMALLEUS</span>
   </a>
</div>
<div class="content-body">
   <router-outlet></router-outlet>
</div>
<div class="user-name-footer">{{ userName }}</div>
