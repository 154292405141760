import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Auth, Hub } from "aws-amplify";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CurrentUserService } from "./authentication/current-user.service";

@Component({
   selector: "fun-root",
   templateUrl: "./app.component.html",
   styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
   loggedIn = false;

   loggingIn: boolean;

   title = "fundamalleus";

   userName = "";

   private unsubscribe = new Subject<void>();

   constructor(
      private currentUserService: CurrentUserService,
      private ngZone: NgZone,
      private changeDetectorRef: ChangeDetectorRef
   ) {
      this.loggingIn = false;
   }

   ngOnInit() {
      Hub.listen("auth", (data) => {
         this.ngZone.run(() => this.authStateChanged(data.payload.event));
      });

      this.currentUserService.userChanged.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         if (this.currentUserService.currentUser?.displayName) {
            this.userName = `logged in as: ${this.currentUserService.currentUser?.displayName}`;
         } else {
            this.userName = "";
         }
      });

      this.currentUserService.loggingIn.pipe(takeUntil(this.unsubscribe)).subscribe((loggingIn) => {
         this.loggingIn = loggingIn;

         this.changeDetectorRef.detectChanges();
      });

      Auth.currentAuthenticatedUser()
         .then((data) => {
            this.processSignIn();
         })
         .catch((err) => {
            this.currentUserService.clearCurrentUser();
            console.log(err);
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   signOut() {
      Auth.signOut()
         .then((data) => {
            this.processSignOut();
         })
         .catch((data) => {
            this.processSignOut();
            console.log(data);
         });
   }

   private processSignIn() {
      this.loggedIn = true;
      this.currentUserService.loadCurrentUser();
   }

   private processSignOut() {
      this.loggedIn = false;
      this.currentUserService.clearCurrentUser();
   }

   private authStateChanged(event: string) {
      switch (event) {
         case "signIn":
            this.processSignIn();
            break;
         case "signOut":
            this.processSignOut();
            break;
      }
   }
}
