import { Component, OnInit } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import {
   APIService,
   CreateCivilizationLeaderMutation,
   CreateCivilizationMapMutation,


   UpdateCivilizationLeaderMutation,
   UpdateCivilizationMapMutation
} from "src/app/API.service";
import { FormHelper } from "src/app/forms/form-helper";
import { CivilizationLeader } from "src/types/civilization-drafter/civilization-leader";
import { CivilizationMap } from "src/types/civilization-drafter/civilization-map";
import { DraftDataHelper } from "../helpers/draft-data.helper";
import { CivilizationLeaderAdminModel } from "./models/civilization-leader-admin.model";
import { CivilizationMapAdminModel } from "./models/civilization-map-admin.model";

@Component({
   selector: "fun-draft-admin",
   templateUrl: "./draft-admin.component.html",
   styleUrls: ["./draft-admin.component.scss"],
})
export class DraftAdminComponent implements OnInit {
   formGroup: FormGroup;

   leadersFormArray: FormArray;

   mapsFormArray: FormArray;

   formDisabled = true;

   formBusy = true;

   formHelper = FormHelper;

   constructor(private apiService: APIService) {
      this.formGroup = new FormGroup({
         leaders: new FormArray([]),
         maps: new FormArray([]),
      });
      this.leadersFormArray = FormHelper.getControlAsArray(this.formGroup, "leaders");
      this.mapsFormArray = FormHelper.getControlAsArray(this.formGroup, "maps");
   }

   ngOnInit() {
      this.load();
   }

   onSubmit() {
      this.formBusy = true;
      const promises = new Array<
         Promise<
            | Array<CreateCivilizationLeaderMutation | UpdateCivilizationLeaderMutation>
            | Array<CreateCivilizationMapMutation | UpdateCivilizationMapMutation>
            | null
         >
      >();

      promises.push(
         this.saveLeaders(this.leadersFormArray.getRawValue().map((item) => item as CivilizationLeaderAdminModel))
      );

      promises.push(this.saveMaps(this.mapsFormArray.getRawValue().map((item) => item as CivilizationMapAdminModel)));

      Promise.all(promises).then(() => {
         alert("Save Successful!");
         this.load();
      });
   }

   addLeader(leader?: CivilizationLeader) {
      if (leader) {
         this.leadersFormArray.push(FormHelper.buildForm(CivilizationLeaderAdminModel.FromQueryModel(leader)));
      } else {
         this.leadersFormArray.push(FormHelper.buildForm(new CivilizationLeaderAdminModel()));
      }
   }

   addMap(map?: CivilizationMap) {
      if (map) {
         this.mapsFormArray.push(FormHelper.buildForm(CivilizationMapAdminModel.FromQueryModel(map)));
      } else {
         this.mapsFormArray.push(FormHelper.buildForm(new CivilizationMapAdminModel()));
      }
   }

   private load() {
      this.formBusy = true;
      const promises = new Array<Promise<void>>();

      promises.push(
         DraftDataHelper.ListLeaders(this.apiService).then((result) => {
            while (this.leadersFormArray.length !== 0) {
               this.leadersFormArray.removeAt(0);
            }
            for (const item of result) {
               this.addLeader(item);
            }
            return;
         })
      );

      promises.push(
         DraftDataHelper.ListMaps(this.apiService).then((result) => {
            while (this.leadersFormArray.length !== 0) {
               this.leadersFormArray.removeAt(0);
            }
            for (const item of result) {
               this.addMap(item);
            }
            return;
         })
      );

      Promise.all(promises).then(() => {
         this.formBusy = false;
         this.formDisabled = false;
      });
   }

   private saveLeaders(
      leaders: Array<CivilizationLeaderAdminModel> | null
   ): Promise<Array<CreateCivilizationLeaderMutation | UpdateCivilizationLeaderMutation> | null> {
      if (!leaders || leaders.length === 0) {
         return Promise.resolve(null);
      }
      const leaderSavePromises = new Array<
         Promise<CreateCivilizationLeaderMutation | UpdateCivilizationLeaderMutation>
      >();
      for (const leader of leaders) {
         if (leader.delete) {
            if (leader.id) {
               leaderSavePromises.push(this.apiService.DeleteCivilizationLeader({ id: leader.id }));
            }
         } else {
            if (leader.id) {
               leaderSavePromises.push(
                  this.apiService.UpdateCivilizationLeader(CivilizationLeaderAdminModel.toUpdateModel(leader))
               );
            } else {
               leaderSavePromises.push(
                  this.apiService.CreateCivilizationLeader(CivilizationLeaderAdminModel.toCreateModel(leader))
               );
            }
         }
      }
      return Promise.all(leaderSavePromises);
   }
   private saveMaps(
      maps: Array<CivilizationMapAdminModel> | null
   ): Promise<Array<CreateCivilizationMapMutation | UpdateCivilizationMapMutation> | null> {
      if (!maps || maps.length === 0) {
         return Promise.resolve(null);
      }
      const mapSavePromises = new Array<Promise<CreateCivilizationMapMutation | UpdateCivilizationMapMutation>>();
      for (const map of maps) {
         if (map.delete) {
            if (map.id) {
               mapSavePromises.push(this.apiService.DeleteCivilizationMap({ id: map.id }));
            }
         } else {
            if (map.id) {
               mapSavePromises.push(
                  this.apiService.UpdateCivilizationMap(CivilizationMapAdminModel.toUpdateModel(map))
               );
            } else {
               mapSavePromises.push(
                  this.apiService.CreateCivilizationMap(CivilizationMapAdminModel.toCreateModel(map))
               );
            }
         }
      }
      return Promise.all(mapSavePromises);
   }
}
