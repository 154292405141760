import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { APIService } from "src/app/API.service";
import { FormHelper } from "src/app/forms/form-helper";
import { User } from "src/types/user";

@Component({
   selector: "fun-view-user",
   templateUrl: "./view-user.component.html",
   styleUrls: ["./view-user.component.scss"],
})
export class ViewUserComponent implements OnInit {
   formGroup: FormGroup | null = null;

   private id: string | null = null;

   constructor(
      private apiService: APIService,
      private formBuilder: FormBuilder,
      private activatedRoute: ActivatedRoute
   ) {}

   ngOnInit() {
      this.activatedRoute.params.subscribe((params) => {
         this.id = params["id"];

         if (!this.id) {
            throw new Error("Unable to show details - unknown user id");
         }
         this.load(this.id);
      });
   }

   onSubmit() {
      if(!this.formGroup){
         return;
      }
      const userValue = this.formGroup.value as User;
      if (!userValue.id) {
         throw new Error("Unable to save - unknown user id");
      }

      this.apiService
         .UpdateUser({
            id: userValue.id,
            displayName: userValue.displayName,
         })
         .then((event) => {
            console.log("item saved!");
            this.id = event.id;
            this.load(event.id);
         })
         .catch((e) => {
            console.log("error creating user...", e);
            alert(`error creating user: \r\n${e}`);
         });
   }

   private load(id: string) {
      this.apiService
         .GetUser(id)
         .then((userResult) => {
            this.formGroup = FormHelper.buildForm(userResult);
         })
         .catch((err) => console.log(err));
   }
}
