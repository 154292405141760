<h1 class="page-header">Civilization Drafts</h1>
<div *ngIf="drafts?.length < 1">No Drafts on record</div>

<table>
   <tr>
      <th>Date</th>
      <th>Owner</th>
      <th class="view-column"> </th>
   </tr>

   <tr *ngFor="let draft of drafts" class="body-row">
      <td>{{ draft.date?.toDateString() }}</td>
      <td>{{ draft.ownerDisplayName }}</td>
      <td>
         <a [routerLink]="[draft.id]" class="button-link legacy">
            <i class="material-icons"> arrow_forward </i> view
         </a>
      </td>
   </tr>
</table>
<a [routerLink]="['add']" class="button-link legacy">Create Draft</a>
