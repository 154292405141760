import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { APIService } from "src/app/API.service";
import { CurrentUserService } from "src/app/authentication/current-user.service";
import { User } from "src/types/user";
import { CrusadeBattleRecordModel } from "../../models/battle-record/crusade-battle-record.model";
import { CrusadeBattleRecordSaveService } from "../services/crusade-battle-record-save.service";

@Component({
   selector: "fun-add-battle-record",
   templateUrl: "./add-battle-record.component.html",
   styleUrls: ["./add-battle-record.component.scss"],
   providers: [CrusadeBattleRecordSaveService],
})
export class AddBattleRecordComponent implements OnInit {
   formBusy = false;

   formDisabled = false;

   users = new Array<User>();

   currentUser: Observable<User | null>;

   constructor(
      private router: Router,
      private apiService: APIService,
      private currentUserService: CurrentUserService,
      private crusadeBattleRecordSaveService: CrusadeBattleRecordSaveService
   ) {
      this.currentUser = this.currentUserService.userChanged;
      this.apiService.ListUsers().then((event) => {
         if (!event.items) {
            throw new Error("Failed to load users");
         }
         const results = new Array<User>();

         for (const item of event.items) {
            if (item) {
               results.push(item);
            }
         }
         this.users = results;
      });
   }

   ngOnInit() {}

   onFormSubmitted(battleRecord: CrusadeBattleRecordModel) {
      this.formBusy = true;
      if (!this.currentUserService.currentUser || !this.currentUserService.currentUser.id) {
         alert("Unable to save Order Of Battle. Unable to determine logged in user.");
         return;
      }

      this.crusadeBattleRecordSaveService.createBattleRecord(battleRecord)
         .then((event) => {
            alert("Saved Successfully");
            this.router.navigate([`crusade/battle-records/${event.id}`]);
         })
         .catch((e) => {
            console.log("error creating Order Of Battle Card...", e);
            alert(`Error creating Order Of Battle: \r\n${e}`);
         });
   }
}
