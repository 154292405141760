<h1 class="page-header">Schedule Draft</h1>
<fun-draft-form
   [formBusy]="formBusy"
   [formDisabled]="formDisabled"
   (formSubmitted)="onFormSubmitted($event)"
></fun-draft-form>
<br />
<a mat-raised-button color="primary" [routerLink]="['/civ-drafter/']">
   <i class="material-icons"> keyboard_backspace </i>
   back to list
</a>
