import { Component, OnInit } from "@angular/core";

@Component({
   selector: "fun-crusade-battle",
   templateUrl: "./crusade-battle.component.html",
   styleUrls: ["./crusade-battle.component.scss"],
})
export class CrusadeBattleComponent implements OnInit {
   constructor() {}

   ngOnInit() {}
}
