import { Injectable } from "@angular/core";
import {
   APIService,
   CreateCrusadeCardMutation,
   CreateCrusadeHonourMutation,
   CreateCrusadeRequisitionMutation,
   UpdateCrusadeCardMutation,
   UpdateCrusadeHonourMutation,
   UpdateCrusadeRequisitionMutation
} from "src/app/API.service";
import { CrusadeCardModel } from "../models/crusade-card.model";
import { CrusadeHonourModel } from "../models/crusade-honour.model";
import { CrusadeRequisitionModel } from "../models/crusade-requisition.model";
import { OrderOfBattleModel } from "../models/order-of-battle.model";

@Injectable()
export class OrderOfBattleSaveService {
   constructor(private apiService: APIService) {}

   createOrderOfBattle(orderOfBattle: OrderOfBattleModel): Promise<OrderOfBattleModel> {
      return this.apiService
         .CreateCrusadeOrderOfBattle(OrderOfBattleModel.toCreateModel(orderOfBattle))
         .then((event) => {
            if (!event) {
               throw new Error("Error occurred while saving.");
            }
            orderOfBattle.id = event.id;
            return this.saveDependentObjects(orderOfBattle);
         });
   }

   updateOrderOfBattle(orderOfBattle: OrderOfBattleModel): Promise<OrderOfBattleModel> {
      return this.apiService
         .UpdateCrusadeOrderOfBattle(OrderOfBattleModel.toUpdateModel(orderOfBattle))
         .then((event) => {
            if (!event) {
               throw new Error("Error occurred while saving.");
            }
            orderOfBattle.id = event.id;
            return this.saveDependentObjects(orderOfBattle);
         });
   }

   private saveDependentObjects(orderOfBattle: OrderOfBattleModel): Promise<OrderOfBattleModel> {
      if (!orderOfBattle.id) {
         throw new Error("Error occurred while saving.");
      }
      const orderOfBattleID = orderOfBattle.id;
      if (orderOfBattle.cards) {
         return this.saveCards(orderOfBattleID, orderOfBattle.cards).then((cardIDMap) => {
            return this.saveRequisitions(orderOfBattleID, orderOfBattle.requisitions, cardIDMap).then(() =>
               Promise.resolve(orderOfBattle)
            );
         });
      } else {
         return this.saveRequisitions(orderOfBattleID, orderOfBattle.requisitions, new Map<string, string>()).then(() =>
            Promise.resolve(orderOfBattle)
         );
      }
   }

   private saveCards(orderOfBattleID: string, cards: Array<CrusadeCardModel>): Promise<Map<string, string>> {
      const cardSavePromises = new Array<
         Promise<
            | CreateCrusadeCardMutation
            | UpdateCrusadeCardMutation
            | Array<CreateCrusadeHonourMutation | UpdateCrusadeHonourMutation>
         >
      >();
      const cardIDMap = new Map<string, string>();
      for (let i = 0; i < cards.length; i++) {
         const card = cards[i];
         card.sortOrder = i;
         if (card.delete) {
            if (card.id) {
               cardSavePromises.push(this.apiService.DeleteCrusadeCard({ id: card.id }));
            }
         } else {
            if (card.id) {
               cardSavePromises.push(
                  this.apiService.UpdateCrusadeCard(CrusadeCardModel.toUpdateModel(card)).then((result) => {
                     if (card.uniqueIdentifier) {
                        cardIDMap.set(card.uniqueIdentifier, result.id);
                     }

                     return this.saveHonours(orderOfBattleID, result.id, card.honours);
                  })
               );
            } else {
               card.orderOfBattleID = orderOfBattleID;
               cardSavePromises.push(
                  this.apiService.CreateCrusadeCard(CrusadeCardModel.toCreateModel(card)).then((result) => {
                     if (card.uniqueIdentifier) {
                        cardIDMap.set(card.uniqueIdentifier, result.id);
                     }
                     return this.saveHonours(orderOfBattleID, result.id, card.honours);
                  })
               );
            }
         }
      }
      return Promise.all(cardSavePromises).then(() => cardIDMap);
   }

   private saveHonours(
      orderOfBattleID: string,
      cardID: string,
      honours: Array<CrusadeHonourModel>,
   ): Promise<Array<CreateCrusadeHonourMutation | UpdateCrusadeHonourMutation>> {
      const honourPromises = new Array<Promise<CreateCrusadeHonourMutation | UpdateCrusadeHonourMutation>>();
      for (const honour of honours) {
         honour.orderOfBattleID = orderOfBattleID;
         honour.crusadeCardID = cardID;
         if (honour.delete) {
            if (honour.id) {
               honourPromises.push(this.apiService.DeleteCrusadeHonour({ id: honour.id }));
            }
         } else {
            if (honour.id) {
               honourPromises.push(this.apiService.UpdateCrusadeHonour(CrusadeHonourModel.toUpdateModel(honour)));
            } else {
               honour.orderOfBattleID = orderOfBattleID;
               honourPromises.push(this.apiService.CreateCrusadeHonour(CrusadeHonourModel.toCreateModel(honour)));
            }
         }
      }
      return Promise.all(honourPromises);
   }

   private saveRequisitions(
      orderOfBattleID: string,
      requisitions: Array<CrusadeRequisitionModel> | null,
      cardIDMap: Map<string, string>
   ): Promise<Array<CreateCrusadeRequisitionMutation | UpdateCrusadeRequisitionMutation> | null> {
      if (!requisitions || requisitions.length === 0) {
         return Promise.resolve(null);
      }
      const requisitionSavePromises = new Array<
         Promise<CreateCrusadeRequisitionMutation | UpdateCrusadeRequisitionMutation>
      >();
      for (const requisition of requisitions) {
         if (requisition.delete) {
            if (requisition.id) {
               requisitionSavePromises.push(this.apiService.DeleteCrusadeRequisition({ id: requisition.id }));
            }
         } else {
            if (requisition.affectedCardIdentifier && cardIDMap.has(requisition.affectedCardIdentifier)) {
               requisition.crusadeCardID = cardIDMap.get(requisition.affectedCardIdentifier) ?? null;
            }
            if (requisition.id) {
               requisitionSavePromises.push(
                  this.apiService.UpdateCrusadeRequisition(CrusadeRequisitionModel.toUpdateModel(requisition))
               );
            } else {
               requisition.orderOfBattleID = orderOfBattleID;
               requisitionSavePromises.push(
                  this.apiService.CreateCrusadeRequisition(CrusadeRequisitionModel.toCreateModel(requisition))
               );
            }
         }
      }
      return Promise.all(requisitionSavePromises);
   }
}
