import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AddCrusadeOrderOfBattleComponent } from "./add-crusade-order-of-battle/add-crusade-order-of-battle.component";
import { AddBattleRecordComponent } from "./battle-record/add-battle-record/add-battle-record.component";
import { BattleRecordChallengeFormComponent } from "./battle-record/battle-record-challenge-form/battle-record-challenge-form.component";
import { CrusadeBattleRecordListComponent } from "./battle-record/crusade-battle-list/crusade-battle-record-list.component";
import { CrusadeBattleComponent } from "./crusade-battle/crusade-battle.component";
import { CrusadeOrderOfBattleListComponent } from "./crusade-order-of-battle-list/crusade-order-of-battle-list.component";
import { CrusadeOrderOfBattleComponent } from "./crusade-order-of-battle/crusade-order-of-battle.component";
import { OrderOfBattleFormComponent } from "./order-of-battle-form/order-of-battle-form.component";


@NgModule({
   imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
   declarations: [
      CrusadeOrderOfBattleListComponent,
      CrusadeOrderOfBattleComponent,
      AddCrusadeOrderOfBattleComponent,
      OrderOfBattleFormComponent,
      CrusadeBattleRecordListComponent,
      CrusadeBattleComponent,
      AddBattleRecordComponent,
      BattleRecordChallengeFormComponent
   ],
})
export class CrusadeModule {}
