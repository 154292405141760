import { DateHelper } from "src/app/dates/date-helper";
import { CivilizationDraftListItem } from "./CivilizationDraftListItem";

export class DraftTableModel {
   id: string = "";
   ownerID: string | null = null;
   ownerDisplayName: string = "";
   date:  Date | null = null;

   static fromQueryModel(item: CivilizationDraftListItem) {
      const result = new DraftTableModel();
      if (!item) {
         throw new Error("Cannot load data - unable to load ID for Order of Battle");
      }
      if (!item.id) {
         throw new Error("Cannot load data - unable to load ID for Order of Battle");
      }
      result.id = item.id;
      result.ownerID = item.ownerID;
      
      if (item.date) {
         result.date = DateHelper.ConvertDate(item.date);
      }

      if (item.owner && item.owner.displayName) {
         result.ownerDisplayName = item.owner.displayName;
      } else {
         result.ownerDisplayName = "Random Randy";
      }

      return result;
   }

   public static sortByDate(draft1: DraftTableModel, draft2: DraftTableModel) {
      if (draft1.date && (!draft2.date || (draft1.date > draft2.date))) {
         return -1;
      }

      if (draft2.date && (!draft1.date || (draft1.date < draft2.date))) {
         return 1;
      }

      return 0;
   }
}


