import { Component, OnInit } from "@angular/core";

@Component({
  selector: "fun-fundamegapocalypse-1-0-0",
  templateUrl: "./fundamegapocalypse_1_0_0.component.html",
  styleUrls: ["./fundamegapocalypse_1_0_0.component.scss"]
})
// tslint:disable-next-line: component-class-suffix
export class FundamegapocalypseComponent_1_0_0 implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
