import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthenticationRequiredGuard } from "./authentication/authentication-required.guard";
import { CurrentUserService } from "./authentication/current-user.service";
import { LoginModule } from "./authentication/login/login.module";
import { CivDrafterModule } from "./civ-drafter/civ-drafter.module";
import { CrusadeModule } from "./crusade/crusade.module";
import { FundapocalypseModule } from "./fundapocalypse/fundapocalypse.module";
import { HomeModule } from "./home/home.module";
import { UsersModule } from "./users/users.module";

@NgModule({
   declarations: [AppComponent],
   imports: [
      BrowserModule,
      RouterModule,
      AppRoutingModule,
      AmplifyUIAngularModule,
      UsersModule,
      HomeModule,
      CrusadeModule,
      CivDrafterModule,
      BrowserAnimationsModule,
      LoginModule,
      FundapocalypseModule,
   ],
   providers: [CurrentUserService, AuthenticationRequiredGuard],
   bootstrap: [AppComponent],
})
export class AppModule {}
