/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://6pogqru6ufcrvhzgk5hykozjry.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:c341b39c-0eff-4790-bc95-48e244648ac8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_pFbcUCKn2",
    "aws_user_pools_web_client_id": "4u6q3ff2hit63u13df9k2ug97c",
    "oauth": {},
    "aws_content_delivery_bucket": "www.fundamalleus.com-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://djgn1mxpzcttc.cloudfront.net"
};


export default awsmobile;
