import { CivilizationDraftedMap } from "src/types/civilization-drafter/civilization-drafted-map";

export class DraftedMapModel {
   id: string | null = null;
   civilizationDraftID: string | null = null;
   mapID: string | null = null;
   selected = false;
   name = "";

   static fromQueryModel(item: CivilizationDraftedMap): DraftedMapModel {
      const result = new DraftedMapModel();

      result.id = item.id;
      result.civilizationDraftID = item.civilizationDraftID;
      result.mapID = item.mapId;
      result.selected = item.selected ?? false;

      if (item.map) {
         result.name = item.map.name;
      }

      return result;
   }
}
