/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateUserInput = {
  id?: string | null;
  cognitoSub?: string | null;
  displayName?: string | null;
};

export type ModelUserConditionInput = {
  cognitoSub?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type UpdateUserInput = {
  id: string;
  cognitoSub?: string | null;
  displayName?: string | null;
};

export type DeleteUserInput = {
  id?: string | null;
};

export type CreateCrusadeOrderOfBattleInput = {
  id?: string | null;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally?: number | null;
  battlesWon?: number | null;
  requisitionPoints?: number | null;
  notes?: string | null;
};

export type ModelCrusadeOrderOfBattleConditionInput = {
  userId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  faction?: ModelStringInput | null;
  supplyLimit?: ModelIntInput | null;
  supplyUsed?: ModelIntInput | null;
  battleTally?: ModelIntInput | null;
  battlesWon?: ModelIntInput | null;
  requisitionPoints?: ModelIntInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelCrusadeOrderOfBattleConditionInput | null> | null;
  or?: Array<ModelCrusadeOrderOfBattleConditionInput | null> | null;
  not?: ModelCrusadeOrderOfBattleConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCrusadeOrderOfBattleInput = {
  id: string;
  userId?: string | null;
  name?: string | null;
  faction?: string | null;
  supplyLimit?: number | null;
  supplyUsed?: number | null;
  battleTally?: number | null;
  battlesWon?: number | null;
  requisitionPoints?: number | null;
  notes?: string | null;
};

export type DeleteCrusadeOrderOfBattleInput = {
  id?: string | null;
};

export type CreateCrusadeCardInput = {
  id?: string | null;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints?: number | null;
  battlefieldRole?: string | null;
  crusadeFaction?: string | null;
  selectableKeywords?: string | null;
  unitType?: string | null;
  equipment?: string | null;
  psychicPowers?: string | null;
  warlordTraits?: string | null;
  relics?: string | null;
  otherUpgrades?: string | null;
  canGainExperience?: boolean | null;
  isTitanic?: boolean | null;
  battleTally?: number | null;
  killTally?: number | null;
  markedForGreatnessTally?: number | null;
  sortOrder?: number | null;
  rank?: number | null;
  isDeactivated?: boolean | null;
};

export type ModelCrusadeCardConditionInput = {
  orderOfBattleID?: ModelIDInput | null;
  unitName?: ModelStringInput | null;
  powerRating?: ModelIntInput | null;
  crusadePoints?: ModelIntInput | null;
  experiencePoints?: ModelIntInput | null;
  battlefieldRole?: ModelStringInput | null;
  crusadeFaction?: ModelStringInput | null;
  selectableKeywords?: ModelStringInput | null;
  unitType?: ModelStringInput | null;
  equipment?: ModelStringInput | null;
  psychicPowers?: ModelStringInput | null;
  warlordTraits?: ModelStringInput | null;
  relics?: ModelStringInput | null;
  otherUpgrades?: ModelStringInput | null;
  canGainExperience?: ModelBooleanInput | null;
  isTitanic?: ModelBooleanInput | null;
  battleTally?: ModelIntInput | null;
  killTally?: ModelIntInput | null;
  markedForGreatnessTally?: ModelIntInput | null;
  sortOrder?: ModelIntInput | null;
  rank?: ModelIntInput | null;
  isDeactivated?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeCardConditionInput | null> | null;
  or?: Array<ModelCrusadeCardConditionInput | null> | null;
  not?: ModelCrusadeCardConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCrusadeCardInput = {
  id: string;
  orderOfBattleID?: string | null;
  unitName?: string | null;
  powerRating?: number | null;
  crusadePoints?: number | null;
  experiencePoints?: number | null;
  battlefieldRole?: string | null;
  crusadeFaction?: string | null;
  selectableKeywords?: string | null;
  unitType?: string | null;
  equipment?: string | null;
  psychicPowers?: string | null;
  warlordTraits?: string | null;
  relics?: string | null;
  otherUpgrades?: string | null;
  canGainExperience?: boolean | null;
  isTitanic?: boolean | null;
  battleTally?: number | null;
  killTally?: number | null;
  markedForGreatnessTally?: number | null;
  sortOrder?: number | null;
  rank?: number | null;
  isDeactivated?: boolean | null;
};

export type DeleteCrusadeCardInput = {
  id?: string | null;
};

export type CreateCrusadeRequisitionInput = {
  id?: string | null;
  orderOfBattleID: string;
  crusadeCardID?: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints?: number | null;
  free?: boolean | null;
};

export type ModelCrusadeRequisitionConditionInput = {
  orderOfBattleID?: ModelIDInput | null;
  crusadeCardID?: ModelIDInput | null;
  type?: ModelIntInput | null;
  description?: ModelStringInput | null;
  requisitionPointCost?: ModelIntInput | null;
  affectedCardCrusadePoints?: ModelIntInput | null;
  free?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeRequisitionConditionInput | null> | null;
  or?: Array<ModelCrusadeRequisitionConditionInput | null> | null;
  not?: ModelCrusadeRequisitionConditionInput | null;
};

export type UpdateCrusadeRequisitionInput = {
  id: string;
  orderOfBattleID?: string | null;
  crusadeCardID?: string | null;
  type?: number | null;
  description?: string | null;
  requisitionPointCost?: number | null;
  affectedCardCrusadePoints?: number | null;
  free?: boolean | null;
};

export type DeleteCrusadeRequisitionInput = {
  id?: string | null;
};

export type CreateCrusadeHonourInput = {
  id?: string | null;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier?: string | null;
  crusadePoints: number;
  isDeactivated?: boolean | null;
};

export type ModelCrusadeHonourConditionInput = {
  orderOfBattleID?: ModelIDInput | null;
  crusadeCardID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  crusadeHonourTypeID?: ModelIntInput | null;
  crusadeHonourSourceID?: ModelIntInput | null;
  crusadeHonourSourceIdentifier?: ModelStringInput | null;
  crusadePoints?: ModelIntInput | null;
  isDeactivated?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeHonourConditionInput | null> | null;
  or?: Array<ModelCrusadeHonourConditionInput | null> | null;
  not?: ModelCrusadeHonourConditionInput | null;
};

export type UpdateCrusadeHonourInput = {
  id: string;
  orderOfBattleID?: string | null;
  crusadeCardID?: string | null;
  description?: string | null;
  crusadeHonourTypeID?: number | null;
  crusadeHonourSourceID?: number | null;
  crusadeHonourSourceIdentifier?: string | null;
  crusadePoints?: number | null;
  isDeactivated?: boolean | null;
};

export type DeleteCrusadeHonourInput = {
  id?: string | null;
};

export type CreateCrusadeBattleRecordInput = {
  id?: string | null;
  gameSizeID: number;
  step: number;
  date?: string | null;
  mission?: string | null;
  description?: string | null;
  twitchUrl?: string | null;
};

export type ModelCrusadeBattleRecordConditionInput = {
  gameSizeID?: ModelIntInput | null;
  step?: ModelIntInput | null;
  date?: ModelStringInput | null;
  mission?: ModelStringInput | null;
  description?: ModelStringInput | null;
  twitchUrl?: ModelStringInput | null;
  and?: Array<ModelCrusadeBattleRecordConditionInput | null> | null;
  or?: Array<ModelCrusadeBattleRecordConditionInput | null> | null;
  not?: ModelCrusadeBattleRecordConditionInput | null;
};

export type UpdateCrusadeBattleRecordInput = {
  id: string;
  gameSizeID?: number | null;
  step?: number | null;
  date?: string | null;
  mission?: string | null;
  description?: string | null;
  twitchUrl?: string | null;
};

export type DeleteCrusadeBattleRecordInput = {
  id?: string | null;
};

export type CreateCrusadeBattleParticipantInput = {
  id?: string | null;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID?: string | null;
  armyTotalPL?: number | null;
  armyTotalCrusadePoints?: number | null;
  bonusCommandPoints?: number | null;
  warlordCrusadeCardID?: string | null;
  isAttacker?: boolean | null;
  isGoingFirst?: boolean | null;
};

export type ModelCrusadeBattleParticipantConditionInput = {
  crusadeBattleRecordID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  orderOfBattleID?: ModelIDInput | null;
  armyTotalPL?: ModelIntInput | null;
  armyTotalCrusadePoints?: ModelIntInput | null;
  bonusCommandPoints?: ModelIntInput | null;
  warlordCrusadeCardID?: ModelIDInput | null;
  isAttacker?: ModelBooleanInput | null;
  isGoingFirst?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeBattleParticipantConditionInput | null> | null;
  or?: Array<ModelCrusadeBattleParticipantConditionInput | null> | null;
  not?: ModelCrusadeBattleParticipantConditionInput | null;
};

export type UpdateCrusadeBattleParticipantInput = {
  id: string;
  crusadeBattleRecordID?: string | null;
  userID?: string | null;
  orderOfBattleID?: string | null;
  armyTotalPL?: number | null;
  armyTotalCrusadePoints?: number | null;
  bonusCommandPoints?: number | null;
  warlordCrusadeCardID?: string | null;
  isAttacker?: boolean | null;
  isGoingFirst?: boolean | null;
};

export type DeleteCrusadeBattleParticipantInput = {
  id?: string | null;
};

export type CreateCivilizationDraftInput = {
  id?: string | null;
  ownerID: string;
  date?: string | null;
  isBlind?: boolean | null;
  winnerID?: string | null;
  secretKey?: string | null;
};

export type ModelCivilizationDraftConditionInput = {
  ownerID?: ModelIDInput | null;
  date?: ModelStringInput | null;
  isBlind?: ModelBooleanInput | null;
  winnerID?: ModelIDInput | null;
  secretKey?: ModelStringInput | null;
  and?: Array<ModelCivilizationDraftConditionInput | null> | null;
  or?: Array<ModelCivilizationDraftConditionInput | null> | null;
  not?: ModelCivilizationDraftConditionInput | null;
};

export type UpdateCivilizationDraftInput = {
  id: string;
  ownerID?: string | null;
  date?: string | null;
  isBlind?: boolean | null;
  winnerID?: string | null;
  secretKey?: string | null;
};

export type DeleteCivilizationDraftInput = {
  id?: string | null;
};

export type CreateCivilizationDraftParticipantInput = {
  id?: string | null;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID?: string | null;
  bannedMapID?: string | null;
  votedMapID?: string | null;
};

export type ModelCivilizationDraftParticipantConditionInput = {
  userID?: ModelIDInput | null;
  civilizationDraftID?: ModelIDInput | null;
  bannedLeaderID?: ModelIDInput | null;
  bannedMapID?: ModelIDInput | null;
  votedMapID?: ModelIDInput | null;
  and?: Array<ModelCivilizationDraftParticipantConditionInput | null> | null;
  or?: Array<ModelCivilizationDraftParticipantConditionInput | null> | null;
  not?: ModelCivilizationDraftParticipantConditionInput | null;
};

export type UpdateCivilizationDraftParticipantInput = {
  id: string;
  userID?: string | null;
  civilizationDraftID?: string | null;
  bannedLeaderID?: string | null;
  bannedMapID?: string | null;
  votedMapID?: string | null;
};

export type DeleteCivilizationDraftParticipantInput = {
  id?: string | null;
};

export type CreateCivilizationDraftParticipantDraftedLeadersInput = {
  id?: string | null;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected?: boolean | null;
};

export type ModelCivilizationDraftParticipantDraftedLeadersConditionInput = {
  civilizationDraftParticipantID?: ModelIDInput | null;
  draftedLeaderID?: ModelIDInput | null;
  selected?: ModelBooleanInput | null;
  and?: Array<ModelCivilizationDraftParticipantDraftedLeadersConditionInput | null> | null;
  or?: Array<ModelCivilizationDraftParticipantDraftedLeadersConditionInput | null> | null;
  not?: ModelCivilizationDraftParticipantDraftedLeadersConditionInput | null;
};

export type UpdateCivilizationDraftParticipantDraftedLeadersInput = {
  id: string;
  civilizationDraftParticipantID?: string | null;
  draftedLeaderID?: string | null;
  selected?: boolean | null;
};

export type DeleteCivilizationDraftParticipantDraftedLeadersInput = {
  id?: string | null;
};

export type CreateCivilizationDraftDraftedMapsInput = {
  id?: string | null;
  civilizationDraftID: string;
  mapId: string;
  selected?: boolean | null;
};

export type ModelCivilizationDraftDraftedMapsConditionInput = {
  civilizationDraftID?: ModelIDInput | null;
  mapId?: ModelIDInput | null;
  selected?: ModelBooleanInput | null;
  and?: Array<ModelCivilizationDraftDraftedMapsConditionInput | null> | null;
  or?: Array<ModelCivilizationDraftDraftedMapsConditionInput | null> | null;
  not?: ModelCivilizationDraftDraftedMapsConditionInput | null;
};

export type UpdateCivilizationDraftDraftedMapsInput = {
  id: string;
  civilizationDraftID?: string | null;
  mapId?: string | null;
  selected?: boolean | null;
};

export type DeleteCivilizationDraftDraftedMapsInput = {
  id?: string | null;
};

export type CreateCivilizationLeaderInput = {
  id?: string | null;
  name: string;
  civilizationName: string;
  infoUrl?: string | null;
};

export type ModelCivilizationLeaderConditionInput = {
  name?: ModelStringInput | null;
  civilizationName?: ModelStringInput | null;
  infoUrl?: ModelStringInput | null;
  and?: Array<ModelCivilizationLeaderConditionInput | null> | null;
  or?: Array<ModelCivilizationLeaderConditionInput | null> | null;
  not?: ModelCivilizationLeaderConditionInput | null;
};

export type UpdateCivilizationLeaderInput = {
  id: string;
  name?: string | null;
  civilizationName?: string | null;
  infoUrl?: string | null;
};

export type DeleteCivilizationLeaderInput = {
  id?: string | null;
};

export type CreateCivilizationMapInput = {
  id?: string | null;
  name: string;
  minPlayers?: number | null;
  maxPlayers?: number | null;
};

export type ModelCivilizationMapConditionInput = {
  name?: ModelStringInput | null;
  minPlayers?: ModelIntInput | null;
  maxPlayers?: ModelIntInput | null;
  and?: Array<ModelCivilizationMapConditionInput | null> | null;
  or?: Array<ModelCivilizationMapConditionInput | null> | null;
  not?: ModelCivilizationMapConditionInput | null;
};

export type UpdateCivilizationMapInput = {
  id: string;
  name?: string | null;
  minPlayers?: number | null;
  maxPlayers?: number | null;
};

export type DeleteCivilizationMapInput = {
  id?: string | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  cognitoSub?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelCrusadeOrderOfBattleFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  faction?: ModelStringInput | null;
  supplyLimit?: ModelIntInput | null;
  supplyUsed?: ModelIntInput | null;
  battleTally?: ModelIntInput | null;
  battlesWon?: ModelIntInput | null;
  requisitionPoints?: ModelIntInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelCrusadeOrderOfBattleFilterInput | null> | null;
  or?: Array<ModelCrusadeOrderOfBattleFilterInput | null> | null;
  not?: ModelCrusadeOrderOfBattleFilterInput | null;
};

export type ModelCrusadeCardFilterInput = {
  id?: ModelIDInput | null;
  orderOfBattleID?: ModelIDInput | null;
  unitName?: ModelStringInput | null;
  powerRating?: ModelIntInput | null;
  crusadePoints?: ModelIntInput | null;
  experiencePoints?: ModelIntInput | null;
  battlefieldRole?: ModelStringInput | null;
  crusadeFaction?: ModelStringInput | null;
  selectableKeywords?: ModelStringInput | null;
  unitType?: ModelStringInput | null;
  equipment?: ModelStringInput | null;
  psychicPowers?: ModelStringInput | null;
  warlordTraits?: ModelStringInput | null;
  relics?: ModelStringInput | null;
  otherUpgrades?: ModelStringInput | null;
  canGainExperience?: ModelBooleanInput | null;
  isTitanic?: ModelBooleanInput | null;
  battleTally?: ModelIntInput | null;
  killTally?: ModelIntInput | null;
  markedForGreatnessTally?: ModelIntInput | null;
  sortOrder?: ModelIntInput | null;
  rank?: ModelIntInput | null;
  isDeactivated?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeCardFilterInput | null> | null;
  or?: Array<ModelCrusadeCardFilterInput | null> | null;
  not?: ModelCrusadeCardFilterInput | null;
};

export type ModelCrusadeRequisitionFilterInput = {
  id?: ModelIDInput | null;
  orderOfBattleID?: ModelIDInput | null;
  crusadeCardID?: ModelIDInput | null;
  type?: ModelIntInput | null;
  description?: ModelStringInput | null;
  requisitionPointCost?: ModelIntInput | null;
  affectedCardCrusadePoints?: ModelIntInput | null;
  free?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeRequisitionFilterInput | null> | null;
  or?: Array<ModelCrusadeRequisitionFilterInput | null> | null;
  not?: ModelCrusadeRequisitionFilterInput | null;
};

export type ModelCrusadeHonourFilterInput = {
  id?: ModelIDInput | null;
  orderOfBattleID?: ModelIDInput | null;
  crusadeCardID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  crusadeHonourTypeID?: ModelIntInput | null;
  crusadeHonourSourceID?: ModelIntInput | null;
  crusadeHonourSourceIdentifier?: ModelStringInput | null;
  crusadePoints?: ModelIntInput | null;
  isDeactivated?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeHonourFilterInput | null> | null;
  or?: Array<ModelCrusadeHonourFilterInput | null> | null;
  not?: ModelCrusadeHonourFilterInput | null;
};

export type ModelCrusadeBattleRecordFilterInput = {
  id?: ModelIDInput | null;
  gameSizeID?: ModelIntInput | null;
  step?: ModelIntInput | null;
  date?: ModelStringInput | null;
  mission?: ModelStringInput | null;
  description?: ModelStringInput | null;
  twitchUrl?: ModelStringInput | null;
  and?: Array<ModelCrusadeBattleRecordFilterInput | null> | null;
  or?: Array<ModelCrusadeBattleRecordFilterInput | null> | null;
  not?: ModelCrusadeBattleRecordFilterInput | null;
};

export type ModelCrusadeBattleParticipantFilterInput = {
  id?: ModelIDInput | null;
  crusadeBattleRecordID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  orderOfBattleID?: ModelIDInput | null;
  armyTotalPL?: ModelIntInput | null;
  armyTotalCrusadePoints?: ModelIntInput | null;
  bonusCommandPoints?: ModelIntInput | null;
  warlordCrusadeCardID?: ModelIDInput | null;
  isAttacker?: ModelBooleanInput | null;
  isGoingFirst?: ModelBooleanInput | null;
  and?: Array<ModelCrusadeBattleParticipantFilterInput | null> | null;
  or?: Array<ModelCrusadeBattleParticipantFilterInput | null> | null;
  not?: ModelCrusadeBattleParticipantFilterInput | null;
};

export type ModelCivilizationDraftFilterInput = {
  id?: ModelIDInput | null;
  ownerID?: ModelIDInput | null;
  date?: ModelStringInput | null;
  isBlind?: ModelBooleanInput | null;
  winnerID?: ModelIDInput | null;
  secretKey?: ModelStringInput | null;
  and?: Array<ModelCivilizationDraftFilterInput | null> | null;
  or?: Array<ModelCivilizationDraftFilterInput | null> | null;
  not?: ModelCivilizationDraftFilterInput | null;
};

export type ModelCivilizationDraftParticipantFilterInput = {
  id?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  civilizationDraftID?: ModelIDInput | null;
  bannedLeaderID?: ModelIDInput | null;
  bannedMapID?: ModelIDInput | null;
  votedMapID?: ModelIDInput | null;
  and?: Array<ModelCivilizationDraftParticipantFilterInput | null> | null;
  or?: Array<ModelCivilizationDraftParticipantFilterInput | null> | null;
  not?: ModelCivilizationDraftParticipantFilterInput | null;
};

export type ModelCivilizationDraftParticipantDraftedLeadersFilterInput = {
  id?: ModelIDInput | null;
  civilizationDraftParticipantID?: ModelIDInput | null;
  draftedLeaderID?: ModelIDInput | null;
  selected?: ModelBooleanInput | null;
  and?: Array<ModelCivilizationDraftParticipantDraftedLeadersFilterInput | null> | null;
  or?: Array<ModelCivilizationDraftParticipantDraftedLeadersFilterInput | null> | null;
  not?: ModelCivilizationDraftParticipantDraftedLeadersFilterInput | null;
};

export type ModelCivilizationDraftDraftedMapsFilterInput = {
  id?: ModelIDInput | null;
  civilizationDraftID?: ModelIDInput | null;
  mapId?: ModelIDInput | null;
  selected?: ModelBooleanInput | null;
  and?: Array<ModelCivilizationDraftDraftedMapsFilterInput | null> | null;
  or?: Array<ModelCivilizationDraftDraftedMapsFilterInput | null> | null;
  not?: ModelCivilizationDraftDraftedMapsFilterInput | null;
};

export type ModelCivilizationLeaderFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  civilizationName?: ModelStringInput | null;
  infoUrl?: ModelStringInput | null;
  and?: Array<ModelCivilizationLeaderFilterInput | null> | null;
  or?: Array<ModelCivilizationLeaderFilterInput | null> | null;
  not?: ModelCivilizationLeaderFilterInput | null;
};

export type ModelCivilizationMapFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  minPlayers?: ModelIntInput | null;
  maxPlayers?: ModelIntInput | null;
  and?: Array<ModelCivilizationMapFilterInput | null> | null;
  or?: Array<ModelCivilizationMapFilterInput | null> | null;
  not?: ModelCivilizationMapFilterInput | null;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  cognitoSub: string | null;
  displayName: string | null;
  ordersOfBattle: {
    __typename: "ModelCrusadeOrderOfBattleConnection";
    items: Array<{
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  cognitoSub: string | null;
  displayName: string | null;
  ordersOfBattle: {
    __typename: "ModelCrusadeOrderOfBattleConnection";
    items: Array<{
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  cognitoSub: string | null;
  displayName: string | null;
  ordersOfBattle: {
    __typename: "ModelCrusadeOrderOfBattleConnection";
    items: Array<{
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCrusadeOrderOfBattleMutation = {
  __typename: "CrusadeOrderOfBattle";
  id: string;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally: number | null;
  battlesWon: number | null;
  requisitionPoints: number | null;
  notes: string | null;
  cards: {
    __typename: "ModelCrusadeCardConnection";
    items: Array<{
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  requisitions: {
    __typename: "ModelCrusadeRequisitionConnection";
    items: Array<{
      __typename: "CrusadeRequisition";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string | null;
      type: number;
      description: string;
      requisitionPointCost: number;
      affectedCardCrusadePoints: number | null;
      free: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCrusadeOrderOfBattleMutation = {
  __typename: "CrusadeOrderOfBattle";
  id: string;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally: number | null;
  battlesWon: number | null;
  requisitionPoints: number | null;
  notes: string | null;
  cards: {
    __typename: "ModelCrusadeCardConnection";
    items: Array<{
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  requisitions: {
    __typename: "ModelCrusadeRequisitionConnection";
    items: Array<{
      __typename: "CrusadeRequisition";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string | null;
      type: number;
      description: string;
      requisitionPointCost: number;
      affectedCardCrusadePoints: number | null;
      free: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCrusadeOrderOfBattleMutation = {
  __typename: "CrusadeOrderOfBattle";
  id: string;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally: number | null;
  battlesWon: number | null;
  requisitionPoints: number | null;
  notes: string | null;
  cards: {
    __typename: "ModelCrusadeCardConnection";
    items: Array<{
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  requisitions: {
    __typename: "ModelCrusadeRequisitionConnection";
    items: Array<{
      __typename: "CrusadeRequisition";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string | null;
      type: number;
      description: string;
      requisitionPointCost: number;
      affectedCardCrusadePoints: number | null;
      free: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCrusadeCardMutation = {
  __typename: "CrusadeCard";
  id: string;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints: number | null;
  battlefieldRole: string | null;
  crusadeFaction: string | null;
  selectableKeywords: string | null;
  unitType: string | null;
  equipment: string | null;
  psychicPowers: string | null;
  warlordTraits: string | null;
  relics: string | null;
  otherUpgrades: string | null;
  canGainExperience: boolean | null;
  isTitanic: boolean | null;
  battleTally: number | null;
  killTally: number | null;
  markedForGreatnessTally: number | null;
  sortOrder: number | null;
  rank: number | null;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  honours: {
    __typename: "ModelCrusadeHonourConnection";
    items: Array<{
      __typename: "CrusadeHonour";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string;
      description: string;
      crusadeHonourTypeID: number;
      crusadeHonourSourceID: number;
      crusadeHonourSourceIdentifier: string | null;
      crusadePoints: number;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      card: {
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCrusadeCardMutation = {
  __typename: "CrusadeCard";
  id: string;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints: number | null;
  battlefieldRole: string | null;
  crusadeFaction: string | null;
  selectableKeywords: string | null;
  unitType: string | null;
  equipment: string | null;
  psychicPowers: string | null;
  warlordTraits: string | null;
  relics: string | null;
  otherUpgrades: string | null;
  canGainExperience: boolean | null;
  isTitanic: boolean | null;
  battleTally: number | null;
  killTally: number | null;
  markedForGreatnessTally: number | null;
  sortOrder: number | null;
  rank: number | null;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  honours: {
    __typename: "ModelCrusadeHonourConnection";
    items: Array<{
      __typename: "CrusadeHonour";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string;
      description: string;
      crusadeHonourTypeID: number;
      crusadeHonourSourceID: number;
      crusadeHonourSourceIdentifier: string | null;
      crusadePoints: number;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      card: {
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCrusadeCardMutation = {
  __typename: "CrusadeCard";
  id: string;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints: number | null;
  battlefieldRole: string | null;
  crusadeFaction: string | null;
  selectableKeywords: string | null;
  unitType: string | null;
  equipment: string | null;
  psychicPowers: string | null;
  warlordTraits: string | null;
  relics: string | null;
  otherUpgrades: string | null;
  canGainExperience: boolean | null;
  isTitanic: boolean | null;
  battleTally: number | null;
  killTally: number | null;
  markedForGreatnessTally: number | null;
  sortOrder: number | null;
  rank: number | null;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  honours: {
    __typename: "ModelCrusadeHonourConnection";
    items: Array<{
      __typename: "CrusadeHonour";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string;
      description: string;
      crusadeHonourTypeID: number;
      crusadeHonourSourceID: number;
      crusadeHonourSourceIdentifier: string | null;
      crusadePoints: number;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      card: {
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCrusadeRequisitionMutation = {
  __typename: "CrusadeRequisition";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints: number | null;
  free: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCrusadeRequisitionMutation = {
  __typename: "CrusadeRequisition";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints: number | null;
  free: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCrusadeRequisitionMutation = {
  __typename: "CrusadeRequisition";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints: number | null;
  free: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCrusadeHonourMutation = {
  __typename: "CrusadeHonour";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier: string | null;
  crusadePoints: number;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  card: {
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCrusadeHonourMutation = {
  __typename: "CrusadeHonour";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier: string | null;
  crusadePoints: number;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  card: {
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCrusadeHonourMutation = {
  __typename: "CrusadeHonour";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier: string | null;
  crusadePoints: number;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  card: {
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCrusadeBattleRecordMutation = {
  __typename: "CrusadeBattleRecord";
  id: string;
  gameSizeID: number;
  step: number;
  date: string | null;
  mission: string | null;
  description: string | null;
  twitchUrl: string | null;
  participants: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCrusadeBattleRecordMutation = {
  __typename: "CrusadeBattleRecord";
  id: string;
  gameSizeID: number;
  step: number;
  date: string | null;
  mission: string | null;
  description: string | null;
  twitchUrl: string | null;
  participants: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCrusadeBattleRecordMutation = {
  __typename: "CrusadeBattleRecord";
  id: string;
  gameSizeID: number;
  step: number;
  date: string | null;
  mission: string | null;
  description: string | null;
  twitchUrl: string | null;
  participants: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCrusadeBattleParticipantMutation = {
  __typename: "CrusadeBattleParticipant";
  id: string;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID: string | null;
  armyTotalPL: number | null;
  armyTotalCrusadePoints: number | null;
  bonusCommandPoints: number | null;
  warlordCrusadeCardID: string | null;
  isAttacker: boolean | null;
  isGoingFirst: boolean | null;
  battleRecord: {
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCrusadeBattleParticipantMutation = {
  __typename: "CrusadeBattleParticipant";
  id: string;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID: string | null;
  armyTotalPL: number | null;
  armyTotalCrusadePoints: number | null;
  bonusCommandPoints: number | null;
  warlordCrusadeCardID: string | null;
  isAttacker: boolean | null;
  isGoingFirst: boolean | null;
  battleRecord: {
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCrusadeBattleParticipantMutation = {
  __typename: "CrusadeBattleParticipant";
  id: string;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID: string | null;
  armyTotalPL: number | null;
  armyTotalCrusadePoints: number | null;
  bonusCommandPoints: number | null;
  warlordCrusadeCardID: string | null;
  isAttacker: boolean | null;
  isGoingFirst: boolean | null;
  battleRecord: {
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCivilizationDraftMutation = {
  __typename: "CivilizationDraft";
  id: string;
  ownerID: string;
  date: string | null;
  isBlind: boolean | null;
  winnerID: string | null;
  secretKey: string | null;
  owner: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  participants: {
    __typename: "ModelCivilizationDraftParticipantConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipant";
      id: string;
      userID: string;
      civilizationDraftID: string;
      bannedLeaderID: string | null;
      bannedMapID: string | null;
      votedMapID: string | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      draftedLeaders: {
        __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
        items: Array<{
          __typename: "CivilizationDraftParticipantDraftedLeaders";
          id: string;
          civilizationDraftParticipantID: string;
          draftedLeaderID: string;
          selected: boolean | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  maps: {
    __typename: "ModelCivilizationDraftDraftedMapsConnection";
    items: Array<{
      __typename: "CivilizationDraftDraftedMaps";
      id: string;
      civilizationDraftID: string;
      mapId: string;
      selected: boolean | null;
      map: {
        __typename: "CivilizationMap";
        id: string;
        name: string;
        minPlayers: number | null;
        maxPlayers: number | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCivilizationDraftMutation = {
  __typename: "CivilizationDraft";
  id: string;
  ownerID: string;
  date: string | null;
  isBlind: boolean | null;
  winnerID: string | null;
  secretKey: string | null;
  owner: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  participants: {
    __typename: "ModelCivilizationDraftParticipantConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipant";
      id: string;
      userID: string;
      civilizationDraftID: string;
      bannedLeaderID: string | null;
      bannedMapID: string | null;
      votedMapID: string | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      draftedLeaders: {
        __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
        items: Array<{
          __typename: "CivilizationDraftParticipantDraftedLeaders";
          id: string;
          civilizationDraftParticipantID: string;
          draftedLeaderID: string;
          selected: boolean | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  maps: {
    __typename: "ModelCivilizationDraftDraftedMapsConnection";
    items: Array<{
      __typename: "CivilizationDraftDraftedMaps";
      id: string;
      civilizationDraftID: string;
      mapId: string;
      selected: boolean | null;
      map: {
        __typename: "CivilizationMap";
        id: string;
        name: string;
        minPlayers: number | null;
        maxPlayers: number | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCivilizationDraftMutation = {
  __typename: "CivilizationDraft";
  id: string;
  ownerID: string;
  date: string | null;
  isBlind: boolean | null;
  winnerID: string | null;
  secretKey: string | null;
  owner: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  participants: {
    __typename: "ModelCivilizationDraftParticipantConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipant";
      id: string;
      userID: string;
      civilizationDraftID: string;
      bannedLeaderID: string | null;
      bannedMapID: string | null;
      votedMapID: string | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      draftedLeaders: {
        __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
        items: Array<{
          __typename: "CivilizationDraftParticipantDraftedLeaders";
          id: string;
          civilizationDraftParticipantID: string;
          draftedLeaderID: string;
          selected: boolean | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  maps: {
    __typename: "ModelCivilizationDraftDraftedMapsConnection";
    items: Array<{
      __typename: "CivilizationDraftDraftedMaps";
      id: string;
      civilizationDraftID: string;
      mapId: string;
      selected: boolean | null;
      map: {
        __typename: "CivilizationMap";
        id: string;
        name: string;
        minPlayers: number | null;
        maxPlayers: number | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCivilizationDraftParticipantMutation = {
  __typename: "CivilizationDraftParticipant";
  id: string;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID: string | null;
  bannedMapID: string | null;
  votedMapID: string | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  draftedLeaders: {
    __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipantDraftedLeaders";
      id: string;
      civilizationDraftParticipantID: string;
      draftedLeaderID: string;
      selected: boolean | null;
      leader: {
        __typename: "CivilizationLeader";
        id: string;
        name: string;
        civilizationName: string;
        infoUrl: string | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type UpdateCivilizationDraftParticipantMutation = {
  __typename: "CivilizationDraftParticipant";
  id: string;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID: string | null;
  bannedMapID: string | null;
  votedMapID: string | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  draftedLeaders: {
    __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipantDraftedLeaders";
      id: string;
      civilizationDraftParticipantID: string;
      draftedLeaderID: string;
      selected: boolean | null;
      leader: {
        __typename: "CivilizationLeader";
        id: string;
        name: string;
        civilizationName: string;
        infoUrl: string | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type DeleteCivilizationDraftParticipantMutation = {
  __typename: "CivilizationDraftParticipant";
  id: string;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID: string | null;
  bannedMapID: string | null;
  votedMapID: string | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  draftedLeaders: {
    __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipantDraftedLeaders";
      id: string;
      civilizationDraftParticipantID: string;
      draftedLeaderID: string;
      selected: boolean | null;
      leader: {
        __typename: "CivilizationLeader";
        id: string;
        name: string;
        civilizationName: string;
        infoUrl: string | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type CreateCivilizationDraftParticipantDraftedLeadersMutation = {
  __typename: "CivilizationDraftParticipantDraftedLeaders";
  id: string;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected: boolean | null;
  leader: {
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type UpdateCivilizationDraftParticipantDraftedLeadersMutation = {
  __typename: "CivilizationDraftParticipantDraftedLeaders";
  id: string;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected: boolean | null;
  leader: {
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type DeleteCivilizationDraftParticipantDraftedLeadersMutation = {
  __typename: "CivilizationDraftParticipantDraftedLeaders";
  id: string;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected: boolean | null;
  leader: {
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type CreateCivilizationDraftDraftedMapsMutation = {
  __typename: "CivilizationDraftDraftedMaps";
  id: string;
  civilizationDraftID: string;
  mapId: string;
  selected: boolean | null;
  map: {
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type UpdateCivilizationDraftDraftedMapsMutation = {
  __typename: "CivilizationDraftDraftedMaps";
  id: string;
  civilizationDraftID: string;
  mapId: string;
  selected: boolean | null;
  map: {
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type DeleteCivilizationDraftDraftedMapsMutation = {
  __typename: "CivilizationDraftDraftedMaps";
  id: string;
  civilizationDraftID: string;
  mapId: string;
  selected: boolean | null;
  map: {
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type CreateCivilizationLeaderMutation = {
  __typename: "CivilizationLeader";
  id: string;
  name: string;
  civilizationName: string;
  infoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type UpdateCivilizationLeaderMutation = {
  __typename: "CivilizationLeader";
  id: string;
  name: string;
  civilizationName: string;
  infoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type DeleteCivilizationLeaderMutation = {
  __typename: "CivilizationLeader";
  id: string;
  name: string;
  civilizationName: string;
  infoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type CreateCivilizationMapMutation = {
  __typename: "CivilizationMap";
  id: string;
  name: string;
  minPlayers: number | null;
  maxPlayers: number | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type UpdateCivilizationMapMutation = {
  __typename: "CivilizationMap";
  id: string;
  name: string;
  minPlayers: number | null;
  maxPlayers: number | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type DeleteCivilizationMapMutation = {
  __typename: "CivilizationMap";
  id: string;
  name: string;
  minPlayers: number | null;
  maxPlayers: number | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  cognitoSub: string | null;
  displayName: string | null;
  ordersOfBattle: {
    __typename: "ModelCrusadeOrderOfBattleConnection";
    items: Array<{
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCrusadeOrderOfBattleQuery = {
  __typename: "CrusadeOrderOfBattle";
  id: string;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally: number | null;
  battlesWon: number | null;
  requisitionPoints: number | null;
  notes: string | null;
  cards: {
    __typename: "ModelCrusadeCardConnection";
    items: Array<{
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  requisitions: {
    __typename: "ModelCrusadeRequisitionConnection";
    items: Array<{
      __typename: "CrusadeRequisition";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string | null;
      type: number;
      description: string;
      requisitionPointCost: number;
      affectedCardCrusadePoints: number | null;
      free: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCrusadeOrderOfBattlesQuery = {
  __typename: "ModelCrusadeOrderOfBattleConnection";
  items: Array<{
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCrusadeCardQuery = {
  __typename: "CrusadeCard";
  id: string;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints: number | null;
  battlefieldRole: string | null;
  crusadeFaction: string | null;
  selectableKeywords: string | null;
  unitType: string | null;
  equipment: string | null;
  psychicPowers: string | null;
  warlordTraits: string | null;
  relics: string | null;
  otherUpgrades: string | null;
  canGainExperience: boolean | null;
  isTitanic: boolean | null;
  battleTally: number | null;
  killTally: number | null;
  markedForGreatnessTally: number | null;
  sortOrder: number | null;
  rank: number | null;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  honours: {
    __typename: "ModelCrusadeHonourConnection";
    items: Array<{
      __typename: "CrusadeHonour";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string;
      description: string;
      crusadeHonourTypeID: number;
      crusadeHonourSourceID: number;
      crusadeHonourSourceIdentifier: string | null;
      crusadePoints: number;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      card: {
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCrusadeCardsQuery = {
  __typename: "ModelCrusadeCardConnection";
  items: Array<{
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCrusadeRequisitionQuery = {
  __typename: "CrusadeRequisition";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints: number | null;
  free: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCrusadeRequisitionsQuery = {
  __typename: "ModelCrusadeRequisitionConnection";
  items: Array<{
    __typename: "CrusadeRequisition";
    id: string;
    orderOfBattleID: string;
    crusadeCardID: string | null;
    type: number;
    description: string;
    requisitionPointCost: number;
    affectedCardCrusadePoints: number | null;
    free: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCrusadeHonourQuery = {
  __typename: "CrusadeHonour";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier: string | null;
  crusadePoints: number;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  card: {
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCrusadeHonoursQuery = {
  __typename: "ModelCrusadeHonourConnection";
  items: Array<{
    __typename: "CrusadeHonour";
    id: string;
    orderOfBattleID: string;
    crusadeCardID: string;
    description: string;
    crusadeHonourTypeID: number;
    crusadeHonourSourceID: number;
    crusadeHonourSourceIdentifier: string | null;
    crusadePoints: number;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    card: {
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCrusadeBattleRecordQuery = {
  __typename: "CrusadeBattleRecord";
  id: string;
  gameSizeID: number;
  step: number;
  date: string | null;
  mission: string | null;
  description: string | null;
  twitchUrl: string | null;
  participants: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCrusadeBattleRecordsQuery = {
  __typename: "ModelCrusadeBattleRecordConnection";
  items: Array<{
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCrusadeBattleParticipantQuery = {
  __typename: "CrusadeBattleParticipant";
  id: string;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID: string | null;
  armyTotalPL: number | null;
  armyTotalCrusadePoints: number | null;
  bonusCommandPoints: number | null;
  warlordCrusadeCardID: string | null;
  isAttacker: boolean | null;
  isGoingFirst: boolean | null;
  battleRecord: {
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCrusadeBattleParticipantsQuery = {
  __typename: "ModelCrusadeBattleParticipantConnection";
  items: Array<{
    __typename: "CrusadeBattleParticipant";
    id: string;
    crusadeBattleRecordID: string;
    userID: string;
    orderOfBattleID: string | null;
    armyTotalPL: number | null;
    armyTotalCrusadePoints: number | null;
    bonusCommandPoints: number | null;
    warlordCrusadeCardID: string | null;
    isAttacker: boolean | null;
    isGoingFirst: boolean | null;
    battleRecord: {
      __typename: "CrusadeBattleRecord";
      id: string;
      gameSizeID: number;
      step: number;
      date: string | null;
      mission: string | null;
      description: string | null;
      twitchUrl: string | null;
      participants: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    user: {
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCivilizationDraftQuery = {
  __typename: "CivilizationDraft";
  id: string;
  ownerID: string;
  date: string | null;
  isBlind: boolean | null;
  winnerID: string | null;
  secretKey: string | null;
  owner: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  participants: {
    __typename: "ModelCivilizationDraftParticipantConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipant";
      id: string;
      userID: string;
      civilizationDraftID: string;
      bannedLeaderID: string | null;
      bannedMapID: string | null;
      votedMapID: string | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      draftedLeaders: {
        __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
        items: Array<{
          __typename: "CivilizationDraftParticipantDraftedLeaders";
          id: string;
          civilizationDraftParticipantID: string;
          draftedLeaderID: string;
          selected: boolean | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  maps: {
    __typename: "ModelCivilizationDraftDraftedMapsConnection";
    items: Array<{
      __typename: "CivilizationDraftDraftedMaps";
      id: string;
      civilizationDraftID: string;
      mapId: string;
      selected: boolean | null;
      map: {
        __typename: "CivilizationMap";
        id: string;
        name: string;
        minPlayers: number | null;
        maxPlayers: number | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCivilizationDraftsQuery = {
  __typename: "ModelCivilizationDraftConnection";
  items: Array<{
    __typename: "CivilizationDraft";
    id: string;
    ownerID: string;
    date: string | null;
    isBlind: boolean | null;
    winnerID: string | null;
    secretKey: string | null;
    owner: {
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    participants: {
      __typename: "ModelCivilizationDraftParticipantConnection";
      items: Array<{
        __typename: "CivilizationDraftParticipant";
        id: string;
        userID: string;
        civilizationDraftID: string;
        bannedLeaderID: string | null;
        bannedMapID: string | null;
        votedMapID: string | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        draftedLeaders: {
          __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    maps: {
      __typename: "ModelCivilizationDraftDraftedMapsConnection";
      items: Array<{
        __typename: "CivilizationDraftDraftedMaps";
        id: string;
        civilizationDraftID: string;
        mapId: string;
        selected: boolean | null;
        map: {
          __typename: "CivilizationMap";
          id: string;
          name: string;
          minPlayers: number | null;
          maxPlayers: number | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCivilizationDraftParticipantQuery = {
  __typename: "CivilizationDraftParticipant";
  id: string;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID: string | null;
  bannedMapID: string | null;
  votedMapID: string | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  draftedLeaders: {
    __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipantDraftedLeaders";
      id: string;
      civilizationDraftParticipantID: string;
      draftedLeaderID: string;
      selected: boolean | null;
      leader: {
        __typename: "CivilizationLeader";
        id: string;
        name: string;
        civilizationName: string;
        infoUrl: string | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type ListCivilizationDraftParticipantsQuery = {
  __typename: "ModelCivilizationDraftParticipantConnection";
  items: Array<{
    __typename: "CivilizationDraftParticipant";
    id: string;
    userID: string;
    civilizationDraftID: string;
    bannedLeaderID: string | null;
    bannedMapID: string | null;
    votedMapID: string | null;
    user: {
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    draftedLeaders: {
      __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
      items: Array<{
        __typename: "CivilizationDraftParticipantDraftedLeaders";
        id: string;
        civilizationDraftParticipantID: string;
        draftedLeaderID: string;
        selected: boolean | null;
        leader: {
          __typename: "CivilizationLeader";
          id: string;
          name: string;
          civilizationName: string;
          infoUrl: string | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCivilizationDraftParticipantDraftedLeadersQuery = {
  __typename: "CivilizationDraftParticipantDraftedLeaders";
  id: string;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected: boolean | null;
  leader: {
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type ListCivilizationDraftParticipantDraftedLeaderssQuery = {
  __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
  items: Array<{
    __typename: "CivilizationDraftParticipantDraftedLeaders";
    id: string;
    civilizationDraftParticipantID: string;
    draftedLeaderID: string;
    selected: boolean | null;
    leader: {
      __typename: "CivilizationLeader";
      id: string;
      name: string;
      civilizationName: string;
      infoUrl: string | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCivilizationDraftDraftedMapsQuery = {
  __typename: "CivilizationDraftDraftedMaps";
  id: string;
  civilizationDraftID: string;
  mapId: string;
  selected: boolean | null;
  map: {
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type ListCivilizationDraftDraftedMapssQuery = {
  __typename: "ModelCivilizationDraftDraftedMapsConnection";
  items: Array<{
    __typename: "CivilizationDraftDraftedMaps";
    id: string;
    civilizationDraftID: string;
    mapId: string;
    selected: boolean | null;
    map: {
      __typename: "CivilizationMap";
      id: string;
      name: string;
      minPlayers: number | null;
      maxPlayers: number | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCivilizationLeaderQuery = {
  __typename: "CivilizationLeader";
  id: string;
  name: string;
  civilizationName: string;
  infoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type ListCivilizationLeadersQuery = {
  __typename: "ModelCivilizationLeaderConnection";
  items: Array<{
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCivilizationMapQuery = {
  __typename: "CivilizationMap";
  id: string;
  name: string;
  minPlayers: number | null;
  maxPlayers: number | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type ListCivilizationMapsQuery = {
  __typename: "ModelCivilizationMapConnection";
  items: Array<{
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  cognitoSub: string | null;
  displayName: string | null;
  ordersOfBattle: {
    __typename: "ModelCrusadeOrderOfBattleConnection";
    items: Array<{
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  cognitoSub: string | null;
  displayName: string | null;
  ordersOfBattle: {
    __typename: "ModelCrusadeOrderOfBattleConnection";
    items: Array<{
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  cognitoSub: string | null;
  displayName: string | null;
  ordersOfBattle: {
    __typename: "ModelCrusadeOrderOfBattleConnection";
    items: Array<{
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCrusadeOrderOfBattleSubscription = {
  __typename: "CrusadeOrderOfBattle";
  id: string;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally: number | null;
  battlesWon: number | null;
  requisitionPoints: number | null;
  notes: string | null;
  cards: {
    __typename: "ModelCrusadeCardConnection";
    items: Array<{
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  requisitions: {
    __typename: "ModelCrusadeRequisitionConnection";
    items: Array<{
      __typename: "CrusadeRequisition";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string | null;
      type: number;
      description: string;
      requisitionPointCost: number;
      affectedCardCrusadePoints: number | null;
      free: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCrusadeOrderOfBattleSubscription = {
  __typename: "CrusadeOrderOfBattle";
  id: string;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally: number | null;
  battlesWon: number | null;
  requisitionPoints: number | null;
  notes: string | null;
  cards: {
    __typename: "ModelCrusadeCardConnection";
    items: Array<{
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  requisitions: {
    __typename: "ModelCrusadeRequisitionConnection";
    items: Array<{
      __typename: "CrusadeRequisition";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string | null;
      type: number;
      description: string;
      requisitionPointCost: number;
      affectedCardCrusadePoints: number | null;
      free: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCrusadeOrderOfBattleSubscription = {
  __typename: "CrusadeOrderOfBattle";
  id: string;
  userId: string;
  name: string;
  faction: string;
  supplyLimit: number;
  supplyUsed: number;
  battleTally: number | null;
  battlesWon: number | null;
  requisitionPoints: number | null;
  notes: string | null;
  cards: {
    __typename: "ModelCrusadeCardConnection";
    items: Array<{
      __typename: "CrusadeCard";
      id: string;
      orderOfBattleID: string;
      unitName: string;
      powerRating: number;
      crusadePoints: number;
      experiencePoints: number | null;
      battlefieldRole: string | null;
      crusadeFaction: string | null;
      selectableKeywords: string | null;
      unitType: string | null;
      equipment: string | null;
      psychicPowers: string | null;
      warlordTraits: string | null;
      relics: string | null;
      otherUpgrades: string | null;
      canGainExperience: boolean | null;
      isTitanic: boolean | null;
      battleTally: number | null;
      killTally: number | null;
      markedForGreatnessTally: number | null;
      sortOrder: number | null;
      rank: number | null;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      honours: {
        __typename: "ModelCrusadeHonourConnection";
        items: Array<{
          __typename: "CrusadeHonour";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string;
          description: string;
          crusadeHonourTypeID: number;
          crusadeHonourSourceID: number;
          crusadeHonourSourceIdentifier: string | null;
          crusadePoints: number;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  requisitions: {
    __typename: "ModelCrusadeRequisitionConnection";
    items: Array<{
      __typename: "CrusadeRequisition";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string | null;
      type: number;
      description: string;
      requisitionPointCost: number;
      affectedCardCrusadePoints: number | null;
      free: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      cognitoSub: string | null;
      displayName: string | null;
      ordersOfBattle: {
        __typename: "ModelCrusadeOrderOfBattleConnection";
        items: Array<{
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  battleParticipations: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCrusadeCardSubscription = {
  __typename: "CrusadeCard";
  id: string;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints: number | null;
  battlefieldRole: string | null;
  crusadeFaction: string | null;
  selectableKeywords: string | null;
  unitType: string | null;
  equipment: string | null;
  psychicPowers: string | null;
  warlordTraits: string | null;
  relics: string | null;
  otherUpgrades: string | null;
  canGainExperience: boolean | null;
  isTitanic: boolean | null;
  battleTally: number | null;
  killTally: number | null;
  markedForGreatnessTally: number | null;
  sortOrder: number | null;
  rank: number | null;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  honours: {
    __typename: "ModelCrusadeHonourConnection";
    items: Array<{
      __typename: "CrusadeHonour";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string;
      description: string;
      crusadeHonourTypeID: number;
      crusadeHonourSourceID: number;
      crusadeHonourSourceIdentifier: string | null;
      crusadePoints: number;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      card: {
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCrusadeCardSubscription = {
  __typename: "CrusadeCard";
  id: string;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints: number | null;
  battlefieldRole: string | null;
  crusadeFaction: string | null;
  selectableKeywords: string | null;
  unitType: string | null;
  equipment: string | null;
  psychicPowers: string | null;
  warlordTraits: string | null;
  relics: string | null;
  otherUpgrades: string | null;
  canGainExperience: boolean | null;
  isTitanic: boolean | null;
  battleTally: number | null;
  killTally: number | null;
  markedForGreatnessTally: number | null;
  sortOrder: number | null;
  rank: number | null;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  honours: {
    __typename: "ModelCrusadeHonourConnection";
    items: Array<{
      __typename: "CrusadeHonour";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string;
      description: string;
      crusadeHonourTypeID: number;
      crusadeHonourSourceID: number;
      crusadeHonourSourceIdentifier: string | null;
      crusadePoints: number;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      card: {
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCrusadeCardSubscription = {
  __typename: "CrusadeCard";
  id: string;
  orderOfBattleID: string;
  unitName: string;
  powerRating: number;
  crusadePoints: number;
  experiencePoints: number | null;
  battlefieldRole: string | null;
  crusadeFaction: string | null;
  selectableKeywords: string | null;
  unitType: string | null;
  equipment: string | null;
  psychicPowers: string | null;
  warlordTraits: string | null;
  relics: string | null;
  otherUpgrades: string | null;
  canGainExperience: boolean | null;
  isTitanic: boolean | null;
  battleTally: number | null;
  killTally: number | null;
  markedForGreatnessTally: number | null;
  sortOrder: number | null;
  rank: number | null;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  honours: {
    __typename: "ModelCrusadeHonourConnection";
    items: Array<{
      __typename: "CrusadeHonour";
      id: string;
      orderOfBattleID: string;
      crusadeCardID: string;
      description: string;
      crusadeHonourTypeID: number;
      crusadeHonourSourceID: number;
      crusadeHonourSourceIdentifier: string | null;
      crusadePoints: number;
      isDeactivated: boolean | null;
      orderOfBattle: {
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      card: {
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCrusadeRequisitionSubscription = {
  __typename: "CrusadeRequisition";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints: number | null;
  free: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCrusadeRequisitionSubscription = {
  __typename: "CrusadeRequisition";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints: number | null;
  free: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCrusadeRequisitionSubscription = {
  __typename: "CrusadeRequisition";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string | null;
  type: number;
  description: string;
  requisitionPointCost: number;
  affectedCardCrusadePoints: number | null;
  free: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCrusadeHonourSubscription = {
  __typename: "CrusadeHonour";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier: string | null;
  crusadePoints: number;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  card: {
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCrusadeHonourSubscription = {
  __typename: "CrusadeHonour";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier: string | null;
  crusadePoints: number;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  card: {
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCrusadeHonourSubscription = {
  __typename: "CrusadeHonour";
  id: string;
  orderOfBattleID: string;
  crusadeCardID: string;
  description: string;
  crusadeHonourTypeID: number;
  crusadeHonourSourceID: number;
  crusadeHonourSourceIdentifier: string | null;
  crusadePoints: number;
  isDeactivated: boolean | null;
  orderOfBattle: {
    __typename: "CrusadeOrderOfBattle";
    id: string;
    userId: string;
    name: string;
    faction: string;
    supplyLimit: number;
    supplyUsed: number;
    battleTally: number | null;
    battlesWon: number | null;
    requisitionPoints: number | null;
    notes: string | null;
    cards: {
      __typename: "ModelCrusadeCardConnection";
      items: Array<{
        __typename: "CrusadeCard";
        id: string;
        orderOfBattleID: string;
        unitName: string;
        powerRating: number;
        crusadePoints: number;
        experiencePoints: number | null;
        battlefieldRole: string | null;
        crusadeFaction: string | null;
        selectableKeywords: string | null;
        unitType: string | null;
        equipment: string | null;
        psychicPowers: string | null;
        warlordTraits: string | null;
        relics: string | null;
        otherUpgrades: string | null;
        canGainExperience: boolean | null;
        isTitanic: boolean | null;
        battleTally: number | null;
        killTally: number | null;
        markedForGreatnessTally: number | null;
        sortOrder: number | null;
        rank: number | null;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        honours: {
          __typename: "ModelCrusadeHonourConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    requisitions: {
      __typename: "ModelCrusadeRequisitionConnection";
      items: Array<{
        __typename: "CrusadeRequisition";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string | null;
        type: number;
        description: string;
        requisitionPointCost: number;
        affectedCardCrusadePoints: number | null;
        free: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  card: {
    __typename: "CrusadeCard";
    id: string;
    orderOfBattleID: string;
    unitName: string;
    powerRating: number;
    crusadePoints: number;
    experiencePoints: number | null;
    battlefieldRole: string | null;
    crusadeFaction: string | null;
    selectableKeywords: string | null;
    unitType: string | null;
    equipment: string | null;
    psychicPowers: string | null;
    warlordTraits: string | null;
    relics: string | null;
    otherUpgrades: string | null;
    canGainExperience: boolean | null;
    isTitanic: boolean | null;
    battleTally: number | null;
    killTally: number | null;
    markedForGreatnessTally: number | null;
    sortOrder: number | null;
    rank: number | null;
    isDeactivated: boolean | null;
    orderOfBattle: {
      __typename: "CrusadeOrderOfBattle";
      id: string;
      userId: string;
      name: string;
      faction: string;
      supplyLimit: number;
      supplyUsed: number;
      battleTally: number | null;
      battlesWon: number | null;
      requisitionPoints: number | null;
      notes: string | null;
      cards: {
        __typename: "ModelCrusadeCardConnection";
        items: Array<{
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      requisitions: {
        __typename: "ModelCrusadeRequisitionConnection";
        items: Array<{
          __typename: "CrusadeRequisition";
          id: string;
          orderOfBattleID: string;
          crusadeCardID: string | null;
          type: number;
          description: string;
          requisitionPointCost: number;
          affectedCardCrusadePoints: number | null;
          free: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      user: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      battleParticipations: {
        __typename: "ModelCrusadeBattleParticipantConnection";
        items: Array<{
          __typename: "CrusadeBattleParticipant";
          id: string;
          crusadeBattleRecordID: string;
          userID: string;
          orderOfBattleID: string | null;
          armyTotalPL: number | null;
          armyTotalCrusadePoints: number | null;
          bonusCommandPoints: number | null;
          warlordCrusadeCardID: string | null;
          isAttacker: boolean | null;
          isGoingFirst: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    honours: {
      __typename: "ModelCrusadeHonourConnection";
      items: Array<{
        __typename: "CrusadeHonour";
        id: string;
        orderOfBattleID: string;
        crusadeCardID: string;
        description: string;
        crusadeHonourTypeID: number;
        crusadeHonourSourceID: number;
        crusadeHonourSourceIdentifier: string | null;
        crusadePoints: number;
        isDeactivated: boolean | null;
        orderOfBattle: {
          __typename: "CrusadeOrderOfBattle";
          id: string;
          userId: string;
          name: string;
          faction: string;
          supplyLimit: number;
          supplyUsed: number;
          battleTally: number | null;
          battlesWon: number | null;
          requisitionPoints: number | null;
          notes: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        card: {
          __typename: "CrusadeCard";
          id: string;
          orderOfBattleID: string;
          unitName: string;
          powerRating: number;
          crusadePoints: number;
          experiencePoints: number | null;
          battlefieldRole: string | null;
          crusadeFaction: string | null;
          selectableKeywords: string | null;
          unitType: string | null;
          equipment: string | null;
          psychicPowers: string | null;
          warlordTraits: string | null;
          relics: string | null;
          otherUpgrades: string | null;
          canGainExperience: boolean | null;
          isTitanic: boolean | null;
          battleTally: number | null;
          killTally: number | null;
          markedForGreatnessTally: number | null;
          sortOrder: number | null;
          rank: number | null;
          isDeactivated: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCrusadeBattleRecordSubscription = {
  __typename: "CrusadeBattleRecord";
  id: string;
  gameSizeID: number;
  step: number;
  date: string | null;
  mission: string | null;
  description: string | null;
  twitchUrl: string | null;
  participants: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCrusadeBattleRecordSubscription = {
  __typename: "CrusadeBattleRecord";
  id: string;
  gameSizeID: number;
  step: number;
  date: string | null;
  mission: string | null;
  description: string | null;
  twitchUrl: string | null;
  participants: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCrusadeBattleRecordSubscription = {
  __typename: "CrusadeBattleRecord";
  id: string;
  gameSizeID: number;
  step: number;
  date: string | null;
  mission: string | null;
  description: string | null;
  twitchUrl: string | null;
  participants: {
    __typename: "ModelCrusadeBattleParticipantConnection";
    items: Array<{
      __typename: "CrusadeBattleParticipant";
      id: string;
      crusadeBattleRecordID: string;
      userID: string;
      orderOfBattleID: string | null;
      armyTotalPL: number | null;
      armyTotalCrusadePoints: number | null;
      bonusCommandPoints: number | null;
      warlordCrusadeCardID: string | null;
      isAttacker: boolean | null;
      isGoingFirst: boolean | null;
      battleRecord: {
        __typename: "CrusadeBattleRecord";
        id: string;
        gameSizeID: number;
        step: number;
        date: string | null;
        mission: string | null;
        description: string | null;
        twitchUrl: string | null;
        participants: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCrusadeBattleParticipantSubscription = {
  __typename: "CrusadeBattleParticipant";
  id: string;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID: string | null;
  armyTotalPL: number | null;
  armyTotalCrusadePoints: number | null;
  bonusCommandPoints: number | null;
  warlordCrusadeCardID: string | null;
  isAttacker: boolean | null;
  isGoingFirst: boolean | null;
  battleRecord: {
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCrusadeBattleParticipantSubscription = {
  __typename: "CrusadeBattleParticipant";
  id: string;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID: string | null;
  armyTotalPL: number | null;
  armyTotalCrusadePoints: number | null;
  bonusCommandPoints: number | null;
  warlordCrusadeCardID: string | null;
  isAttacker: boolean | null;
  isGoingFirst: boolean | null;
  battleRecord: {
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCrusadeBattleParticipantSubscription = {
  __typename: "CrusadeBattleParticipant";
  id: string;
  crusadeBattleRecordID: string;
  userID: string;
  orderOfBattleID: string | null;
  armyTotalPL: number | null;
  armyTotalCrusadePoints: number | null;
  bonusCommandPoints: number | null;
  warlordCrusadeCardID: string | null;
  isAttacker: boolean | null;
  isGoingFirst: boolean | null;
  battleRecord: {
    __typename: "CrusadeBattleRecord";
    id: string;
    gameSizeID: number;
    step: number;
    date: string | null;
    mission: string | null;
    description: string | null;
    twitchUrl: string | null;
    participants: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCivilizationDraftSubscription = {
  __typename: "CivilizationDraft";
  id: string;
  ownerID: string;
  date: string | null;
  isBlind: boolean | null;
  winnerID: string | null;
  secretKey: string | null;
  owner: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  participants: {
    __typename: "ModelCivilizationDraftParticipantConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipant";
      id: string;
      userID: string;
      civilizationDraftID: string;
      bannedLeaderID: string | null;
      bannedMapID: string | null;
      votedMapID: string | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      draftedLeaders: {
        __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
        items: Array<{
          __typename: "CivilizationDraftParticipantDraftedLeaders";
          id: string;
          civilizationDraftParticipantID: string;
          draftedLeaderID: string;
          selected: boolean | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  maps: {
    __typename: "ModelCivilizationDraftDraftedMapsConnection";
    items: Array<{
      __typename: "CivilizationDraftDraftedMaps";
      id: string;
      civilizationDraftID: string;
      mapId: string;
      selected: boolean | null;
      map: {
        __typename: "CivilizationMap";
        id: string;
        name: string;
        minPlayers: number | null;
        maxPlayers: number | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCivilizationDraftSubscription = {
  __typename: "CivilizationDraft";
  id: string;
  ownerID: string;
  date: string | null;
  isBlind: boolean | null;
  winnerID: string | null;
  secretKey: string | null;
  owner: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  participants: {
    __typename: "ModelCivilizationDraftParticipantConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipant";
      id: string;
      userID: string;
      civilizationDraftID: string;
      bannedLeaderID: string | null;
      bannedMapID: string | null;
      votedMapID: string | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      draftedLeaders: {
        __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
        items: Array<{
          __typename: "CivilizationDraftParticipantDraftedLeaders";
          id: string;
          civilizationDraftParticipantID: string;
          draftedLeaderID: string;
          selected: boolean | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  maps: {
    __typename: "ModelCivilizationDraftDraftedMapsConnection";
    items: Array<{
      __typename: "CivilizationDraftDraftedMaps";
      id: string;
      civilizationDraftID: string;
      mapId: string;
      selected: boolean | null;
      map: {
        __typename: "CivilizationMap";
        id: string;
        name: string;
        minPlayers: number | null;
        maxPlayers: number | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCivilizationDraftSubscription = {
  __typename: "CivilizationDraft";
  id: string;
  ownerID: string;
  date: string | null;
  isBlind: boolean | null;
  winnerID: string | null;
  secretKey: string | null;
  owner: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  participants: {
    __typename: "ModelCivilizationDraftParticipantConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipant";
      id: string;
      userID: string;
      civilizationDraftID: string;
      bannedLeaderID: string | null;
      bannedMapID: string | null;
      votedMapID: string | null;
      user: {
        __typename: "User";
        id: string;
        cognitoSub: string | null;
        displayName: string | null;
        ordersOfBattle: {
          __typename: "ModelCrusadeOrderOfBattleConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      draftedLeaders: {
        __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
        items: Array<{
          __typename: "CivilizationDraftParticipantDraftedLeaders";
          id: string;
          civilizationDraftParticipantID: string;
          draftedLeaderID: string;
          selected: boolean | null;
          createdAt: string;
          updatedAt: string;
          owner: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  maps: {
    __typename: "ModelCivilizationDraftDraftedMapsConnection";
    items: Array<{
      __typename: "CivilizationDraftDraftedMaps";
      id: string;
      civilizationDraftID: string;
      mapId: string;
      selected: boolean | null;
      map: {
        __typename: "CivilizationMap";
        id: string;
        name: string;
        minPlayers: number | null;
        maxPlayers: number | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCivilizationDraftParticipantSubscription = {
  __typename: "CivilizationDraftParticipant";
  id: string;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID: string | null;
  bannedMapID: string | null;
  votedMapID: string | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  draftedLeaders: {
    __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipantDraftedLeaders";
      id: string;
      civilizationDraftParticipantID: string;
      draftedLeaderID: string;
      selected: boolean | null;
      leader: {
        __typename: "CivilizationLeader";
        id: string;
        name: string;
        civilizationName: string;
        infoUrl: string | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnUpdateCivilizationDraftParticipantSubscription = {
  __typename: "CivilizationDraftParticipant";
  id: string;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID: string | null;
  bannedMapID: string | null;
  votedMapID: string | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  draftedLeaders: {
    __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipantDraftedLeaders";
      id: string;
      civilizationDraftParticipantID: string;
      draftedLeaderID: string;
      selected: boolean | null;
      leader: {
        __typename: "CivilizationLeader";
        id: string;
        name: string;
        civilizationName: string;
        infoUrl: string | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnDeleteCivilizationDraftParticipantSubscription = {
  __typename: "CivilizationDraftParticipant";
  id: string;
  userID: string;
  civilizationDraftID: string;
  bannedLeaderID: string | null;
  bannedMapID: string | null;
  votedMapID: string | null;
  user: {
    __typename: "User";
    id: string;
    cognitoSub: string | null;
    displayName: string | null;
    ordersOfBattle: {
      __typename: "ModelCrusadeOrderOfBattleConnection";
      items: Array<{
        __typename: "CrusadeOrderOfBattle";
        id: string;
        userId: string;
        name: string;
        faction: string;
        supplyLimit: number;
        supplyUsed: number;
        battleTally: number | null;
        battlesWon: number | null;
        requisitionPoints: number | null;
        notes: string | null;
        cards: {
          __typename: "ModelCrusadeCardConnection";
          nextToken: string | null;
        } | null;
        requisitions: {
          __typename: "ModelCrusadeRequisitionConnection";
          nextToken: string | null;
        } | null;
        user: {
          __typename: "ModelUserConnection";
          nextToken: string | null;
        } | null;
        battleParticipations: {
          __typename: "ModelCrusadeBattleParticipantConnection";
          nextToken: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    battleParticipations: {
      __typename: "ModelCrusadeBattleParticipantConnection";
      items: Array<{
        __typename: "CrusadeBattleParticipant";
        id: string;
        crusadeBattleRecordID: string;
        userID: string;
        orderOfBattleID: string | null;
        armyTotalPL: number | null;
        armyTotalCrusadePoints: number | null;
        bonusCommandPoints: number | null;
        warlordCrusadeCardID: string | null;
        isAttacker: boolean | null;
        isGoingFirst: boolean | null;
        battleRecord: {
          __typename: "CrusadeBattleRecord";
          id: string;
          gameSizeID: number;
          step: number;
          date: string | null;
          mission: string | null;
          description: string | null;
          twitchUrl: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        user: {
          __typename: "User";
          id: string;
          cognitoSub: string | null;
          displayName: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  draftedLeaders: {
    __typename: "ModelCivilizationDraftParticipantDraftedLeadersConnection";
    items: Array<{
      __typename: "CivilizationDraftParticipantDraftedLeaders";
      id: string;
      civilizationDraftParticipantID: string;
      draftedLeaderID: string;
      selected: boolean | null;
      leader: {
        __typename: "CivilizationLeader";
        id: string;
        name: string;
        civilizationName: string;
        infoUrl: string | null;
        createdAt: string;
        updatedAt: string;
        owner: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnCreateCivilizationDraftParticipantDraftedLeadersSubscription = {
  __typename: "CivilizationDraftParticipantDraftedLeaders";
  id: string;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected: boolean | null;
  leader: {
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnUpdateCivilizationDraftParticipantDraftedLeadersSubscription = {
  __typename: "CivilizationDraftParticipantDraftedLeaders";
  id: string;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected: boolean | null;
  leader: {
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnDeleteCivilizationDraftParticipantDraftedLeadersSubscription = {
  __typename: "CivilizationDraftParticipantDraftedLeaders";
  id: string;
  civilizationDraftParticipantID: string;
  draftedLeaderID: string;
  selected: boolean | null;
  leader: {
    __typename: "CivilizationLeader";
    id: string;
    name: string;
    civilizationName: string;
    infoUrl: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnCreateCivilizationDraftDraftedMapsSubscription = {
  __typename: "CivilizationDraftDraftedMaps";
  id: string;
  civilizationDraftID: string;
  mapId: string;
  selected: boolean | null;
  map: {
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnUpdateCivilizationDraftDraftedMapsSubscription = {
  __typename: "CivilizationDraftDraftedMaps";
  id: string;
  civilizationDraftID: string;
  mapId: string;
  selected: boolean | null;
  map: {
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnDeleteCivilizationDraftDraftedMapsSubscription = {
  __typename: "CivilizationDraftDraftedMaps";
  id: string;
  civilizationDraftID: string;
  mapId: string;
  selected: boolean | null;
  map: {
    __typename: "CivilizationMap";
    id: string;
    name: string;
    minPlayers: number | null;
    maxPlayers: number | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnCreateCivilizationLeaderSubscription = {
  __typename: "CivilizationLeader";
  id: string;
  name: string;
  civilizationName: string;
  infoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnUpdateCivilizationLeaderSubscription = {
  __typename: "CivilizationLeader";
  id: string;
  name: string;
  civilizationName: string;
  infoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnDeleteCivilizationLeaderSubscription = {
  __typename: "CivilizationLeader";
  id: string;
  name: string;
  civilizationName: string;
  infoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnCreateCivilizationMapSubscription = {
  __typename: "CivilizationMap";
  id: string;
  name: string;
  minPlayers: number | null;
  maxPlayers: number | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnUpdateCivilizationMapSubscription = {
  __typename: "CivilizationMap";
  id: string;
  name: string;
  minPlayers: number | null;
  maxPlayers: number | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnDeleteCivilizationMapSubscription = {
  __typename: "CivilizationMap";
  id: string;
  name: string;
  minPlayers: number | null;
  maxPlayers: number | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          cognitoSub
          displayName
          ordersOfBattle {
            __typename
            items {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          cognitoSub
          displayName
          ordersOfBattle {
            __typename
            items {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          cognitoSub
          displayName
          ordersOfBattle {
            __typename
            items {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateCrusadeOrderOfBattle(
    input: CreateCrusadeOrderOfBattleInput,
    condition?: ModelCrusadeOrderOfBattleConditionInput
  ): Promise<CreateCrusadeOrderOfBattleMutation> {
    const statement = `mutation CreateCrusadeOrderOfBattle($input: CreateCrusadeOrderOfBattleInput!, $condition: ModelCrusadeOrderOfBattleConditionInput) {
        createCrusadeOrderOfBattle(input: $input, condition: $condition) {
          __typename
          id
          userId
          name
          faction
          supplyLimit
          supplyUsed
          battleTally
          battlesWon
          requisitionPoints
          notes
          cards {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          requisitions {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              type
              description
              requisitionPointCost
              affectedCardCrusadePoints
              free
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrusadeOrderOfBattleMutation>(
      response.data.createCrusadeOrderOfBattle
    );
  }
  async UpdateCrusadeOrderOfBattle(
    input: UpdateCrusadeOrderOfBattleInput,
    condition?: ModelCrusadeOrderOfBattleConditionInput
  ): Promise<UpdateCrusadeOrderOfBattleMutation> {
    const statement = `mutation UpdateCrusadeOrderOfBattle($input: UpdateCrusadeOrderOfBattleInput!, $condition: ModelCrusadeOrderOfBattleConditionInput) {
        updateCrusadeOrderOfBattle(input: $input, condition: $condition) {
          __typename
          id
          userId
          name
          faction
          supplyLimit
          supplyUsed
          battleTally
          battlesWon
          requisitionPoints
          notes
          cards {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          requisitions {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              type
              description
              requisitionPointCost
              affectedCardCrusadePoints
              free
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrusadeOrderOfBattleMutation>(
      response.data.updateCrusadeOrderOfBattle
    );
  }
  async DeleteCrusadeOrderOfBattle(
    input: DeleteCrusadeOrderOfBattleInput,
    condition?: ModelCrusadeOrderOfBattleConditionInput
  ): Promise<DeleteCrusadeOrderOfBattleMutation> {
    const statement = `mutation DeleteCrusadeOrderOfBattle($input: DeleteCrusadeOrderOfBattleInput!, $condition: ModelCrusadeOrderOfBattleConditionInput) {
        deleteCrusadeOrderOfBattle(input: $input, condition: $condition) {
          __typename
          id
          userId
          name
          faction
          supplyLimit
          supplyUsed
          battleTally
          battlesWon
          requisitionPoints
          notes
          cards {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          requisitions {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              type
              description
              requisitionPointCost
              affectedCardCrusadePoints
              free
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrusadeOrderOfBattleMutation>(
      response.data.deleteCrusadeOrderOfBattle
    );
  }
  async CreateCrusadeCard(
    input: CreateCrusadeCardInput,
    condition?: ModelCrusadeCardConditionInput
  ): Promise<CreateCrusadeCardMutation> {
    const statement = `mutation CreateCrusadeCard($input: CreateCrusadeCardInput!, $condition: ModelCrusadeCardConditionInput) {
        createCrusadeCard(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          unitName
          powerRating
          crusadePoints
          experiencePoints
          battlefieldRole
          crusadeFaction
          selectableKeywords
          unitType
          equipment
          psychicPowers
          warlordTraits
          relics
          otherUpgrades
          canGainExperience
          isTitanic
          battleTally
          killTally
          markedForGreatnessTally
          sortOrder
          rank
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          honours {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              description
              crusadeHonourTypeID
              crusadeHonourSourceID
              crusadeHonourSourceIdentifier
              crusadePoints
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              card {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrusadeCardMutation>response.data.createCrusadeCard;
  }
  async UpdateCrusadeCard(
    input: UpdateCrusadeCardInput,
    condition?: ModelCrusadeCardConditionInput
  ): Promise<UpdateCrusadeCardMutation> {
    const statement = `mutation UpdateCrusadeCard($input: UpdateCrusadeCardInput!, $condition: ModelCrusadeCardConditionInput) {
        updateCrusadeCard(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          unitName
          powerRating
          crusadePoints
          experiencePoints
          battlefieldRole
          crusadeFaction
          selectableKeywords
          unitType
          equipment
          psychicPowers
          warlordTraits
          relics
          otherUpgrades
          canGainExperience
          isTitanic
          battleTally
          killTally
          markedForGreatnessTally
          sortOrder
          rank
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          honours {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              description
              crusadeHonourTypeID
              crusadeHonourSourceID
              crusadeHonourSourceIdentifier
              crusadePoints
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              card {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrusadeCardMutation>response.data.updateCrusadeCard;
  }
  async DeleteCrusadeCard(
    input: DeleteCrusadeCardInput,
    condition?: ModelCrusadeCardConditionInput
  ): Promise<DeleteCrusadeCardMutation> {
    const statement = `mutation DeleteCrusadeCard($input: DeleteCrusadeCardInput!, $condition: ModelCrusadeCardConditionInput) {
        deleteCrusadeCard(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          unitName
          powerRating
          crusadePoints
          experiencePoints
          battlefieldRole
          crusadeFaction
          selectableKeywords
          unitType
          equipment
          psychicPowers
          warlordTraits
          relics
          otherUpgrades
          canGainExperience
          isTitanic
          battleTally
          killTally
          markedForGreatnessTally
          sortOrder
          rank
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          honours {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              description
              crusadeHonourTypeID
              crusadeHonourSourceID
              crusadeHonourSourceIdentifier
              crusadePoints
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              card {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrusadeCardMutation>response.data.deleteCrusadeCard;
  }
  async CreateCrusadeRequisition(
    input: CreateCrusadeRequisitionInput,
    condition?: ModelCrusadeRequisitionConditionInput
  ): Promise<CreateCrusadeRequisitionMutation> {
    const statement = `mutation CreateCrusadeRequisition($input: CreateCrusadeRequisitionInput!, $condition: ModelCrusadeRequisitionConditionInput) {
        createCrusadeRequisition(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          type
          description
          requisitionPointCost
          affectedCardCrusadePoints
          free
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrusadeRequisitionMutation>(
      response.data.createCrusadeRequisition
    );
  }
  async UpdateCrusadeRequisition(
    input: UpdateCrusadeRequisitionInput,
    condition?: ModelCrusadeRequisitionConditionInput
  ): Promise<UpdateCrusadeRequisitionMutation> {
    const statement = `mutation UpdateCrusadeRequisition($input: UpdateCrusadeRequisitionInput!, $condition: ModelCrusadeRequisitionConditionInput) {
        updateCrusadeRequisition(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          type
          description
          requisitionPointCost
          affectedCardCrusadePoints
          free
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrusadeRequisitionMutation>(
      response.data.updateCrusadeRequisition
    );
  }
  async DeleteCrusadeRequisition(
    input: DeleteCrusadeRequisitionInput,
    condition?: ModelCrusadeRequisitionConditionInput
  ): Promise<DeleteCrusadeRequisitionMutation> {
    const statement = `mutation DeleteCrusadeRequisition($input: DeleteCrusadeRequisitionInput!, $condition: ModelCrusadeRequisitionConditionInput) {
        deleteCrusadeRequisition(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          type
          description
          requisitionPointCost
          affectedCardCrusadePoints
          free
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrusadeRequisitionMutation>(
      response.data.deleteCrusadeRequisition
    );
  }
  async CreateCrusadeHonour(
    input: CreateCrusadeHonourInput,
    condition?: ModelCrusadeHonourConditionInput
  ): Promise<CreateCrusadeHonourMutation> {
    const statement = `mutation CreateCrusadeHonour($input: CreateCrusadeHonourInput!, $condition: ModelCrusadeHonourConditionInput) {
        createCrusadeHonour(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          description
          crusadeHonourTypeID
          crusadeHonourSourceID
          crusadeHonourSourceIdentifier
          crusadePoints
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          card {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrusadeHonourMutation>response.data.createCrusadeHonour;
  }
  async UpdateCrusadeHonour(
    input: UpdateCrusadeHonourInput,
    condition?: ModelCrusadeHonourConditionInput
  ): Promise<UpdateCrusadeHonourMutation> {
    const statement = `mutation UpdateCrusadeHonour($input: UpdateCrusadeHonourInput!, $condition: ModelCrusadeHonourConditionInput) {
        updateCrusadeHonour(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          description
          crusadeHonourTypeID
          crusadeHonourSourceID
          crusadeHonourSourceIdentifier
          crusadePoints
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          card {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrusadeHonourMutation>response.data.updateCrusadeHonour;
  }
  async DeleteCrusadeHonour(
    input: DeleteCrusadeHonourInput,
    condition?: ModelCrusadeHonourConditionInput
  ): Promise<DeleteCrusadeHonourMutation> {
    const statement = `mutation DeleteCrusadeHonour($input: DeleteCrusadeHonourInput!, $condition: ModelCrusadeHonourConditionInput) {
        deleteCrusadeHonour(input: $input, condition: $condition) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          description
          crusadeHonourTypeID
          crusadeHonourSourceID
          crusadeHonourSourceIdentifier
          crusadePoints
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          card {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrusadeHonourMutation>response.data.deleteCrusadeHonour;
  }
  async CreateCrusadeBattleRecord(
    input: CreateCrusadeBattleRecordInput,
    condition?: ModelCrusadeBattleRecordConditionInput
  ): Promise<CreateCrusadeBattleRecordMutation> {
    const statement = `mutation CreateCrusadeBattleRecord($input: CreateCrusadeBattleRecordInput!, $condition: ModelCrusadeBattleRecordConditionInput) {
        createCrusadeBattleRecord(input: $input, condition: $condition) {
          __typename
          id
          gameSizeID
          step
          date
          mission
          description
          twitchUrl
          participants {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrusadeBattleRecordMutation>(
      response.data.createCrusadeBattleRecord
    );
  }
  async UpdateCrusadeBattleRecord(
    input: UpdateCrusadeBattleRecordInput,
    condition?: ModelCrusadeBattleRecordConditionInput
  ): Promise<UpdateCrusadeBattleRecordMutation> {
    const statement = `mutation UpdateCrusadeBattleRecord($input: UpdateCrusadeBattleRecordInput!, $condition: ModelCrusadeBattleRecordConditionInput) {
        updateCrusadeBattleRecord(input: $input, condition: $condition) {
          __typename
          id
          gameSizeID
          step
          date
          mission
          description
          twitchUrl
          participants {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrusadeBattleRecordMutation>(
      response.data.updateCrusadeBattleRecord
    );
  }
  async DeleteCrusadeBattleRecord(
    input: DeleteCrusadeBattleRecordInput,
    condition?: ModelCrusadeBattleRecordConditionInput
  ): Promise<DeleteCrusadeBattleRecordMutation> {
    const statement = `mutation DeleteCrusadeBattleRecord($input: DeleteCrusadeBattleRecordInput!, $condition: ModelCrusadeBattleRecordConditionInput) {
        deleteCrusadeBattleRecord(input: $input, condition: $condition) {
          __typename
          id
          gameSizeID
          step
          date
          mission
          description
          twitchUrl
          participants {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrusadeBattleRecordMutation>(
      response.data.deleteCrusadeBattleRecord
    );
  }
  async CreateCrusadeBattleParticipant(
    input: CreateCrusadeBattleParticipantInput,
    condition?: ModelCrusadeBattleParticipantConditionInput
  ): Promise<CreateCrusadeBattleParticipantMutation> {
    const statement = `mutation CreateCrusadeBattleParticipant($input: CreateCrusadeBattleParticipantInput!, $condition: ModelCrusadeBattleParticipantConditionInput) {
        createCrusadeBattleParticipant(input: $input, condition: $condition) {
          __typename
          id
          crusadeBattleRecordID
          userID
          orderOfBattleID
          armyTotalPL
          armyTotalCrusadePoints
          bonusCommandPoints
          warlordCrusadeCardID
          isAttacker
          isGoingFirst
          battleRecord {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrusadeBattleParticipantMutation>(
      response.data.createCrusadeBattleParticipant
    );
  }
  async UpdateCrusadeBattleParticipant(
    input: UpdateCrusadeBattleParticipantInput,
    condition?: ModelCrusadeBattleParticipantConditionInput
  ): Promise<UpdateCrusadeBattleParticipantMutation> {
    const statement = `mutation UpdateCrusadeBattleParticipant($input: UpdateCrusadeBattleParticipantInput!, $condition: ModelCrusadeBattleParticipantConditionInput) {
        updateCrusadeBattleParticipant(input: $input, condition: $condition) {
          __typename
          id
          crusadeBattleRecordID
          userID
          orderOfBattleID
          armyTotalPL
          armyTotalCrusadePoints
          bonusCommandPoints
          warlordCrusadeCardID
          isAttacker
          isGoingFirst
          battleRecord {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrusadeBattleParticipantMutation>(
      response.data.updateCrusadeBattleParticipant
    );
  }
  async DeleteCrusadeBattleParticipant(
    input: DeleteCrusadeBattleParticipantInput,
    condition?: ModelCrusadeBattleParticipantConditionInput
  ): Promise<DeleteCrusadeBattleParticipantMutation> {
    const statement = `mutation DeleteCrusadeBattleParticipant($input: DeleteCrusadeBattleParticipantInput!, $condition: ModelCrusadeBattleParticipantConditionInput) {
        deleteCrusadeBattleParticipant(input: $input, condition: $condition) {
          __typename
          id
          crusadeBattleRecordID
          userID
          orderOfBattleID
          armyTotalPL
          armyTotalCrusadePoints
          bonusCommandPoints
          warlordCrusadeCardID
          isAttacker
          isGoingFirst
          battleRecord {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrusadeBattleParticipantMutation>(
      response.data.deleteCrusadeBattleParticipant
    );
  }
  async CreateCivilizationDraft(
    input: CreateCivilizationDraftInput,
    condition?: ModelCivilizationDraftConditionInput
  ): Promise<CreateCivilizationDraftMutation> {
    const statement = `mutation CreateCivilizationDraft($input: CreateCivilizationDraftInput!, $condition: ModelCivilizationDraftConditionInput) {
        createCivilizationDraft(input: $input, condition: $condition) {
          __typename
          id
          ownerID
          date
          isBlind
          winnerID
          secretKey
          owner {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          participants {
            __typename
            items {
              __typename
              id
              userID
              civilizationDraftID
              bannedLeaderID
              bannedMapID
              votedMapID
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              draftedLeaders {
                __typename
                items {
                  __typename
                  id
                  civilizationDraftParticipantID
                  draftedLeaderID
                  selected
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          maps {
            __typename
            items {
              __typename
              id
              civilizationDraftID
              mapId
              selected
              map {
                __typename
                id
                name
                minPlayers
                maxPlayers
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCivilizationDraftMutation>(
      response.data.createCivilizationDraft
    );
  }
  async UpdateCivilizationDraft(
    input: UpdateCivilizationDraftInput,
    condition?: ModelCivilizationDraftConditionInput
  ): Promise<UpdateCivilizationDraftMutation> {
    const statement = `mutation UpdateCivilizationDraft($input: UpdateCivilizationDraftInput!, $condition: ModelCivilizationDraftConditionInput) {
        updateCivilizationDraft(input: $input, condition: $condition) {
          __typename
          id
          ownerID
          date
          isBlind
          winnerID
          secretKey
          owner {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          participants {
            __typename
            items {
              __typename
              id
              userID
              civilizationDraftID
              bannedLeaderID
              bannedMapID
              votedMapID
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              draftedLeaders {
                __typename
                items {
                  __typename
                  id
                  civilizationDraftParticipantID
                  draftedLeaderID
                  selected
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          maps {
            __typename
            items {
              __typename
              id
              civilizationDraftID
              mapId
              selected
              map {
                __typename
                id
                name
                minPlayers
                maxPlayers
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCivilizationDraftMutation>(
      response.data.updateCivilizationDraft
    );
  }
  async DeleteCivilizationDraft(
    input: DeleteCivilizationDraftInput,
    condition?: ModelCivilizationDraftConditionInput
  ): Promise<DeleteCivilizationDraftMutation> {
    const statement = `mutation DeleteCivilizationDraft($input: DeleteCivilizationDraftInput!, $condition: ModelCivilizationDraftConditionInput) {
        deleteCivilizationDraft(input: $input, condition: $condition) {
          __typename
          id
          ownerID
          date
          isBlind
          winnerID
          secretKey
          owner {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          participants {
            __typename
            items {
              __typename
              id
              userID
              civilizationDraftID
              bannedLeaderID
              bannedMapID
              votedMapID
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              draftedLeaders {
                __typename
                items {
                  __typename
                  id
                  civilizationDraftParticipantID
                  draftedLeaderID
                  selected
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          maps {
            __typename
            items {
              __typename
              id
              civilizationDraftID
              mapId
              selected
              map {
                __typename
                id
                name
                minPlayers
                maxPlayers
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCivilizationDraftMutation>(
      response.data.deleteCivilizationDraft
    );
  }
  async CreateCivilizationDraftParticipant(
    input: CreateCivilizationDraftParticipantInput,
    condition?: ModelCivilizationDraftParticipantConditionInput
  ): Promise<CreateCivilizationDraftParticipantMutation> {
    const statement = `mutation CreateCivilizationDraftParticipant($input: CreateCivilizationDraftParticipantInput!, $condition: ModelCivilizationDraftParticipantConditionInput) {
        createCivilizationDraftParticipant(input: $input, condition: $condition) {
          __typename
          id
          userID
          civilizationDraftID
          bannedLeaderID
          bannedMapID
          votedMapID
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          draftedLeaders {
            __typename
            items {
              __typename
              id
              civilizationDraftParticipantID
              draftedLeaderID
              selected
              leader {
                __typename
                id
                name
                civilizationName
                infoUrl
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCivilizationDraftParticipantMutation>(
      response.data.createCivilizationDraftParticipant
    );
  }
  async UpdateCivilizationDraftParticipant(
    input: UpdateCivilizationDraftParticipantInput,
    condition?: ModelCivilizationDraftParticipantConditionInput
  ): Promise<UpdateCivilizationDraftParticipantMutation> {
    const statement = `mutation UpdateCivilizationDraftParticipant($input: UpdateCivilizationDraftParticipantInput!, $condition: ModelCivilizationDraftParticipantConditionInput) {
        updateCivilizationDraftParticipant(input: $input, condition: $condition) {
          __typename
          id
          userID
          civilizationDraftID
          bannedLeaderID
          bannedMapID
          votedMapID
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          draftedLeaders {
            __typename
            items {
              __typename
              id
              civilizationDraftParticipantID
              draftedLeaderID
              selected
              leader {
                __typename
                id
                name
                civilizationName
                infoUrl
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCivilizationDraftParticipantMutation>(
      response.data.updateCivilizationDraftParticipant
    );
  }
  async DeleteCivilizationDraftParticipant(
    input: DeleteCivilizationDraftParticipantInput,
    condition?: ModelCivilizationDraftParticipantConditionInput
  ): Promise<DeleteCivilizationDraftParticipantMutation> {
    const statement = `mutation DeleteCivilizationDraftParticipant($input: DeleteCivilizationDraftParticipantInput!, $condition: ModelCivilizationDraftParticipantConditionInput) {
        deleteCivilizationDraftParticipant(input: $input, condition: $condition) {
          __typename
          id
          userID
          civilizationDraftID
          bannedLeaderID
          bannedMapID
          votedMapID
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          draftedLeaders {
            __typename
            items {
              __typename
              id
              civilizationDraftParticipantID
              draftedLeaderID
              selected
              leader {
                __typename
                id
                name
                civilizationName
                infoUrl
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCivilizationDraftParticipantMutation>(
      response.data.deleteCivilizationDraftParticipant
    );
  }
  async CreateCivilizationDraftParticipantDraftedLeaders(
    input: CreateCivilizationDraftParticipantDraftedLeadersInput,
    condition?: ModelCivilizationDraftParticipantDraftedLeadersConditionInput
  ): Promise<CreateCivilizationDraftParticipantDraftedLeadersMutation> {
    const statement = `mutation CreateCivilizationDraftParticipantDraftedLeaders($input: CreateCivilizationDraftParticipantDraftedLeadersInput!, $condition: ModelCivilizationDraftParticipantDraftedLeadersConditionInput) {
        createCivilizationDraftParticipantDraftedLeaders(input: $input, condition: $condition) {
          __typename
          id
          civilizationDraftParticipantID
          draftedLeaderID
          selected
          leader {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCivilizationDraftParticipantDraftedLeadersMutation>(
      response.data.createCivilizationDraftParticipantDraftedLeaders
    );
  }
  async UpdateCivilizationDraftParticipantDraftedLeaders(
    input: UpdateCivilizationDraftParticipantDraftedLeadersInput,
    condition?: ModelCivilizationDraftParticipantDraftedLeadersConditionInput
  ): Promise<UpdateCivilizationDraftParticipantDraftedLeadersMutation> {
    const statement = `mutation UpdateCivilizationDraftParticipantDraftedLeaders($input: UpdateCivilizationDraftParticipantDraftedLeadersInput!, $condition: ModelCivilizationDraftParticipantDraftedLeadersConditionInput) {
        updateCivilizationDraftParticipantDraftedLeaders(input: $input, condition: $condition) {
          __typename
          id
          civilizationDraftParticipantID
          draftedLeaderID
          selected
          leader {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCivilizationDraftParticipantDraftedLeadersMutation>(
      response.data.updateCivilizationDraftParticipantDraftedLeaders
    );
  }
  async DeleteCivilizationDraftParticipantDraftedLeaders(
    input: DeleteCivilizationDraftParticipantDraftedLeadersInput,
    condition?: ModelCivilizationDraftParticipantDraftedLeadersConditionInput
  ): Promise<DeleteCivilizationDraftParticipantDraftedLeadersMutation> {
    const statement = `mutation DeleteCivilizationDraftParticipantDraftedLeaders($input: DeleteCivilizationDraftParticipantDraftedLeadersInput!, $condition: ModelCivilizationDraftParticipantDraftedLeadersConditionInput) {
        deleteCivilizationDraftParticipantDraftedLeaders(input: $input, condition: $condition) {
          __typename
          id
          civilizationDraftParticipantID
          draftedLeaderID
          selected
          leader {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCivilizationDraftParticipantDraftedLeadersMutation>(
      response.data.deleteCivilizationDraftParticipantDraftedLeaders
    );
  }
  async CreateCivilizationDraftDraftedMaps(
    input: CreateCivilizationDraftDraftedMapsInput,
    condition?: ModelCivilizationDraftDraftedMapsConditionInput
  ): Promise<CreateCivilizationDraftDraftedMapsMutation> {
    const statement = `mutation CreateCivilizationDraftDraftedMaps($input: CreateCivilizationDraftDraftedMapsInput!, $condition: ModelCivilizationDraftDraftedMapsConditionInput) {
        createCivilizationDraftDraftedMaps(input: $input, condition: $condition) {
          __typename
          id
          civilizationDraftID
          mapId
          selected
          map {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCivilizationDraftDraftedMapsMutation>(
      response.data.createCivilizationDraftDraftedMaps
    );
  }
  async UpdateCivilizationDraftDraftedMaps(
    input: UpdateCivilizationDraftDraftedMapsInput,
    condition?: ModelCivilizationDraftDraftedMapsConditionInput
  ): Promise<UpdateCivilizationDraftDraftedMapsMutation> {
    const statement = `mutation UpdateCivilizationDraftDraftedMaps($input: UpdateCivilizationDraftDraftedMapsInput!, $condition: ModelCivilizationDraftDraftedMapsConditionInput) {
        updateCivilizationDraftDraftedMaps(input: $input, condition: $condition) {
          __typename
          id
          civilizationDraftID
          mapId
          selected
          map {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCivilizationDraftDraftedMapsMutation>(
      response.data.updateCivilizationDraftDraftedMaps
    );
  }
  async DeleteCivilizationDraftDraftedMaps(
    input: DeleteCivilizationDraftDraftedMapsInput,
    condition?: ModelCivilizationDraftDraftedMapsConditionInput
  ): Promise<DeleteCivilizationDraftDraftedMapsMutation> {
    const statement = `mutation DeleteCivilizationDraftDraftedMaps($input: DeleteCivilizationDraftDraftedMapsInput!, $condition: ModelCivilizationDraftDraftedMapsConditionInput) {
        deleteCivilizationDraftDraftedMaps(input: $input, condition: $condition) {
          __typename
          id
          civilizationDraftID
          mapId
          selected
          map {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCivilizationDraftDraftedMapsMutation>(
      response.data.deleteCivilizationDraftDraftedMaps
    );
  }
  async CreateCivilizationLeader(
    input: CreateCivilizationLeaderInput,
    condition?: ModelCivilizationLeaderConditionInput
  ): Promise<CreateCivilizationLeaderMutation> {
    const statement = `mutation CreateCivilizationLeader($input: CreateCivilizationLeaderInput!, $condition: ModelCivilizationLeaderConditionInput) {
        createCivilizationLeader(input: $input, condition: $condition) {
          __typename
          id
          name
          civilizationName
          infoUrl
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCivilizationLeaderMutation>(
      response.data.createCivilizationLeader
    );
  }
  async UpdateCivilizationLeader(
    input: UpdateCivilizationLeaderInput,
    condition?: ModelCivilizationLeaderConditionInput
  ): Promise<UpdateCivilizationLeaderMutation> {
    const statement = `mutation UpdateCivilizationLeader($input: UpdateCivilizationLeaderInput!, $condition: ModelCivilizationLeaderConditionInput) {
        updateCivilizationLeader(input: $input, condition: $condition) {
          __typename
          id
          name
          civilizationName
          infoUrl
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCivilizationLeaderMutation>(
      response.data.updateCivilizationLeader
    );
  }
  async DeleteCivilizationLeader(
    input: DeleteCivilizationLeaderInput,
    condition?: ModelCivilizationLeaderConditionInput
  ): Promise<DeleteCivilizationLeaderMutation> {
    const statement = `mutation DeleteCivilizationLeader($input: DeleteCivilizationLeaderInput!, $condition: ModelCivilizationLeaderConditionInput) {
        deleteCivilizationLeader(input: $input, condition: $condition) {
          __typename
          id
          name
          civilizationName
          infoUrl
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCivilizationLeaderMutation>(
      response.data.deleteCivilizationLeader
    );
  }
  async CreateCivilizationMap(
    input: CreateCivilizationMapInput,
    condition?: ModelCivilizationMapConditionInput
  ): Promise<CreateCivilizationMapMutation> {
    const statement = `mutation CreateCivilizationMap($input: CreateCivilizationMapInput!, $condition: ModelCivilizationMapConditionInput) {
        createCivilizationMap(input: $input, condition: $condition) {
          __typename
          id
          name
          minPlayers
          maxPlayers
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCivilizationMapMutation>response.data.createCivilizationMap;
  }
  async UpdateCivilizationMap(
    input: UpdateCivilizationMapInput,
    condition?: ModelCivilizationMapConditionInput
  ): Promise<UpdateCivilizationMapMutation> {
    const statement = `mutation UpdateCivilizationMap($input: UpdateCivilizationMapInput!, $condition: ModelCivilizationMapConditionInput) {
        updateCivilizationMap(input: $input, condition: $condition) {
          __typename
          id
          name
          minPlayers
          maxPlayers
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCivilizationMapMutation>response.data.updateCivilizationMap;
  }
  async DeleteCivilizationMap(
    input: DeleteCivilizationMapInput,
    condition?: ModelCivilizationMapConditionInput
  ): Promise<DeleteCivilizationMapMutation> {
    const statement = `mutation DeleteCivilizationMap($input: DeleteCivilizationMapInput!, $condition: ModelCivilizationMapConditionInput) {
        deleteCivilizationMap(input: $input, condition: $condition) {
          __typename
          id
          name
          minPlayers
          maxPlayers
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCivilizationMapMutation>response.data.deleteCivilizationMap;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          cognitoSub
          displayName
          ordersOfBattle {
            __typename
            items {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetCrusadeOrderOfBattle(
    id: string
  ): Promise<GetCrusadeOrderOfBattleQuery> {
    const statement = `query GetCrusadeOrderOfBattle($id: ID!) {
        getCrusadeOrderOfBattle(id: $id) {
          __typename
          id
          userId
          name
          faction
          supplyLimit
          supplyUsed
          battleTally
          battlesWon
          requisitionPoints
          notes
          cards {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          requisitions {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              type
              description
              requisitionPointCost
              affectedCardCrusadePoints
              free
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCrusadeOrderOfBattleQuery>response.data.getCrusadeOrderOfBattle;
  }
  async ListCrusadeOrderOfBattles(
    filter?: ModelCrusadeOrderOfBattleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCrusadeOrderOfBattlesQuery> {
    const statement = `query ListCrusadeOrderOfBattles($filter: ModelCrusadeOrderOfBattleFilterInput, $limit: Int, $nextToken: String) {
        listCrusadeOrderOfBattles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCrusadeOrderOfBattlesQuery>(
      response.data.listCrusadeOrderOfBattles
    );
  }
  async GetCrusadeCard(id: string): Promise<GetCrusadeCardQuery> {
    const statement = `query GetCrusadeCard($id: ID!) {
        getCrusadeCard(id: $id) {
          __typename
          id
          orderOfBattleID
          unitName
          powerRating
          crusadePoints
          experiencePoints
          battlefieldRole
          crusadeFaction
          selectableKeywords
          unitType
          equipment
          psychicPowers
          warlordTraits
          relics
          otherUpgrades
          canGainExperience
          isTitanic
          battleTally
          killTally
          markedForGreatnessTally
          sortOrder
          rank
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          honours {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              description
              crusadeHonourTypeID
              crusadeHonourSourceID
              crusadeHonourSourceIdentifier
              crusadePoints
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              card {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCrusadeCardQuery>response.data.getCrusadeCard;
  }
  async ListCrusadeCards(
    filter?: ModelCrusadeCardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCrusadeCardsQuery> {
    const statement = `query ListCrusadeCards($filter: ModelCrusadeCardFilterInput, $limit: Int, $nextToken: String) {
        listCrusadeCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCrusadeCardsQuery>response.data.listCrusadeCards;
  }
  async GetCrusadeRequisition(id: string): Promise<GetCrusadeRequisitionQuery> {
    const statement = `query GetCrusadeRequisition($id: ID!) {
        getCrusadeRequisition(id: $id) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          type
          description
          requisitionPointCost
          affectedCardCrusadePoints
          free
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCrusadeRequisitionQuery>response.data.getCrusadeRequisition;
  }
  async ListCrusadeRequisitions(
    filter?: ModelCrusadeRequisitionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCrusadeRequisitionsQuery> {
    const statement = `query ListCrusadeRequisitions($filter: ModelCrusadeRequisitionFilterInput, $limit: Int, $nextToken: String) {
        listCrusadeRequisitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            orderOfBattleID
            crusadeCardID
            type
            description
            requisitionPointCost
            affectedCardCrusadePoints
            free
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCrusadeRequisitionsQuery>response.data.listCrusadeRequisitions;
  }
  async GetCrusadeHonour(id: string): Promise<GetCrusadeHonourQuery> {
    const statement = `query GetCrusadeHonour($id: ID!) {
        getCrusadeHonour(id: $id) {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          description
          crusadeHonourTypeID
          crusadeHonourSourceID
          crusadeHonourSourceIdentifier
          crusadePoints
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          card {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCrusadeHonourQuery>response.data.getCrusadeHonour;
  }
  async ListCrusadeHonours(
    filter?: ModelCrusadeHonourFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCrusadeHonoursQuery> {
    const statement = `query ListCrusadeHonours($filter: ModelCrusadeHonourFilterInput, $limit: Int, $nextToken: String) {
        listCrusadeHonours(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            orderOfBattleID
            crusadeCardID
            description
            crusadeHonourTypeID
            crusadeHonourSourceID
            crusadeHonourSourceIdentifier
            crusadePoints
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            card {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCrusadeHonoursQuery>response.data.listCrusadeHonours;
  }
  async GetCrusadeBattleRecord(
    id: string
  ): Promise<GetCrusadeBattleRecordQuery> {
    const statement = `query GetCrusadeBattleRecord($id: ID!) {
        getCrusadeBattleRecord(id: $id) {
          __typename
          id
          gameSizeID
          step
          date
          mission
          description
          twitchUrl
          participants {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCrusadeBattleRecordQuery>response.data.getCrusadeBattleRecord;
  }
  async ListCrusadeBattleRecords(
    filter?: ModelCrusadeBattleRecordFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCrusadeBattleRecordsQuery> {
    const statement = `query ListCrusadeBattleRecords($filter: ModelCrusadeBattleRecordFilterInput, $limit: Int, $nextToken: String) {
        listCrusadeBattleRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCrusadeBattleRecordsQuery>(
      response.data.listCrusadeBattleRecords
    );
  }
  async GetCrusadeBattleParticipant(
    id: string
  ): Promise<GetCrusadeBattleParticipantQuery> {
    const statement = `query GetCrusadeBattleParticipant($id: ID!) {
        getCrusadeBattleParticipant(id: $id) {
          __typename
          id
          crusadeBattleRecordID
          userID
          orderOfBattleID
          armyTotalPL
          armyTotalCrusadePoints
          bonusCommandPoints
          warlordCrusadeCardID
          isAttacker
          isGoingFirst
          battleRecord {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCrusadeBattleParticipantQuery>(
      response.data.getCrusadeBattleParticipant
    );
  }
  async ListCrusadeBattleParticipants(
    filter?: ModelCrusadeBattleParticipantFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCrusadeBattleParticipantsQuery> {
    const statement = `query ListCrusadeBattleParticipants($filter: ModelCrusadeBattleParticipantFilterInput, $limit: Int, $nextToken: String) {
        listCrusadeBattleParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            crusadeBattleRecordID
            userID
            orderOfBattleID
            armyTotalPL
            armyTotalCrusadePoints
            bonusCommandPoints
            warlordCrusadeCardID
            isAttacker
            isGoingFirst
            battleRecord {
              __typename
              id
              gameSizeID
              step
              date
              mission
              description
              twitchUrl
              participants {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            user {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCrusadeBattleParticipantsQuery>(
      response.data.listCrusadeBattleParticipants
    );
  }
  async GetCivilizationDraft(id: string): Promise<GetCivilizationDraftQuery> {
    const statement = `query GetCivilizationDraft($id: ID!) {
        getCivilizationDraft(id: $id) {
          __typename
          id
          ownerID
          date
          isBlind
          winnerID
          secretKey
          owner {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          participants {
            __typename
            items {
              __typename
              id
              userID
              civilizationDraftID
              bannedLeaderID
              bannedMapID
              votedMapID
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              draftedLeaders {
                __typename
                items {
                  __typename
                  id
                  civilizationDraftParticipantID
                  draftedLeaderID
                  selected
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          maps {
            __typename
            items {
              __typename
              id
              civilizationDraftID
              mapId
              selected
              map {
                __typename
                id
                name
                minPlayers
                maxPlayers
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCivilizationDraftQuery>response.data.getCivilizationDraft;
  }
  async ListCivilizationDrafts(
    filter?: ModelCivilizationDraftFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCivilizationDraftsQuery> {
    const statement = `query ListCivilizationDrafts($filter: ModelCivilizationDraftFilterInput, $limit: Int, $nextToken: String) {
        listCivilizationDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            ownerID
            date
            isBlind
            winnerID
            secretKey
            owner {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            participants {
              __typename
              items {
                __typename
                id
                userID
                civilizationDraftID
                bannedLeaderID
                bannedMapID
                votedMapID
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                draftedLeaders {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            maps {
              __typename
              items {
                __typename
                id
                civilizationDraftID
                mapId
                selected
                map {
                  __typename
                  id
                  name
                  minPlayers
                  maxPlayers
                  createdAt
                  updatedAt
                  owner
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCivilizationDraftsQuery>response.data.listCivilizationDrafts;
  }
  async GetCivilizationDraftParticipant(
    id: string
  ): Promise<GetCivilizationDraftParticipantQuery> {
    const statement = `query GetCivilizationDraftParticipant($id: ID!) {
        getCivilizationDraftParticipant(id: $id) {
          __typename
          id
          userID
          civilizationDraftID
          bannedLeaderID
          bannedMapID
          votedMapID
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          draftedLeaders {
            __typename
            items {
              __typename
              id
              civilizationDraftParticipantID
              draftedLeaderID
              selected
              leader {
                __typename
                id
                name
                civilizationName
                infoUrl
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCivilizationDraftParticipantQuery>(
      response.data.getCivilizationDraftParticipant
    );
  }
  async ListCivilizationDraftParticipants(
    filter?: ModelCivilizationDraftParticipantFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCivilizationDraftParticipantsQuery> {
    const statement = `query ListCivilizationDraftParticipants($filter: ModelCivilizationDraftParticipantFilterInput, $limit: Int, $nextToken: String) {
        listCivilizationDraftParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userID
            civilizationDraftID
            bannedLeaderID
            bannedMapID
            votedMapID
            user {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            draftedLeaders {
              __typename
              items {
                __typename
                id
                civilizationDraftParticipantID
                draftedLeaderID
                selected
                leader {
                  __typename
                  id
                  name
                  civilizationName
                  infoUrl
                  createdAt
                  updatedAt
                  owner
                }
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCivilizationDraftParticipantsQuery>(
      response.data.listCivilizationDraftParticipants
    );
  }
  async GetCivilizationDraftParticipantDraftedLeaders(
    id: string
  ): Promise<GetCivilizationDraftParticipantDraftedLeadersQuery> {
    const statement = `query GetCivilizationDraftParticipantDraftedLeaders($id: ID!) {
        getCivilizationDraftParticipantDraftedLeaders(id: $id) {
          __typename
          id
          civilizationDraftParticipantID
          draftedLeaderID
          selected
          leader {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCivilizationDraftParticipantDraftedLeadersQuery>(
      response.data.getCivilizationDraftParticipantDraftedLeaders
    );
  }
  async ListCivilizationDraftParticipantDraftedLeaderss(
    filter?: ModelCivilizationDraftParticipantDraftedLeadersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCivilizationDraftParticipantDraftedLeaderssQuery> {
    const statement = `query ListCivilizationDraftParticipantDraftedLeaderss($filter: ModelCivilizationDraftParticipantDraftedLeadersFilterInput, $limit: Int, $nextToken: String) {
        listCivilizationDraftParticipantDraftedLeaderss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            civilizationDraftParticipantID
            draftedLeaderID
            selected
            leader {
              __typename
              id
              name
              civilizationName
              infoUrl
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCivilizationDraftParticipantDraftedLeaderssQuery>(
      response.data.listCivilizationDraftParticipantDraftedLeaderss
    );
  }
  async GetCivilizationDraftDraftedMaps(
    id: string
  ): Promise<GetCivilizationDraftDraftedMapsQuery> {
    const statement = `query GetCivilizationDraftDraftedMaps($id: ID!) {
        getCivilizationDraftDraftedMaps(id: $id) {
          __typename
          id
          civilizationDraftID
          mapId
          selected
          map {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCivilizationDraftDraftedMapsQuery>(
      response.data.getCivilizationDraftDraftedMaps
    );
  }
  async ListCivilizationDraftDraftedMapss(
    filter?: ModelCivilizationDraftDraftedMapsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCivilizationDraftDraftedMapssQuery> {
    const statement = `query ListCivilizationDraftDraftedMapss($filter: ModelCivilizationDraftDraftedMapsFilterInput, $limit: Int, $nextToken: String) {
        listCivilizationDraftDraftedMapss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            civilizationDraftID
            mapId
            selected
            map {
              __typename
              id
              name
              minPlayers
              maxPlayers
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCivilizationDraftDraftedMapssQuery>(
      response.data.listCivilizationDraftDraftedMapss
    );
  }
  async GetCivilizationLeader(id: string): Promise<GetCivilizationLeaderQuery> {
    const statement = `query GetCivilizationLeader($id: ID!) {
        getCivilizationLeader(id: $id) {
          __typename
          id
          name
          civilizationName
          infoUrl
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCivilizationLeaderQuery>response.data.getCivilizationLeader;
  }
  async ListCivilizationLeaders(
    filter?: ModelCivilizationLeaderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCivilizationLeadersQuery> {
    const statement = `query ListCivilizationLeaders($filter: ModelCivilizationLeaderFilterInput, $limit: Int, $nextToken: String) {
        listCivilizationLeaders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCivilizationLeadersQuery>response.data.listCivilizationLeaders;
  }
  async GetCivilizationMap(id: string): Promise<GetCivilizationMapQuery> {
    const statement = `query GetCivilizationMap($id: ID!) {
        getCivilizationMap(id: $id) {
          __typename
          id
          name
          minPlayers
          maxPlayers
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCivilizationMapQuery>response.data.getCivilizationMap;
  }
  async ListCivilizationMaps(
    filter?: ModelCivilizationMapFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCivilizationMapsQuery> {
    const statement = `query ListCivilizationMaps($filter: ModelCivilizationMapFilterInput, $limit: Int, $nextToken: String) {
        listCivilizationMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCivilizationMapsQuery>response.data.listCivilizationMaps;
  }
  OnCreateUserListener: Observable<
    SubscriptionResponse<OnCreateUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          id
          cognitoSub
          displayName
          ordersOfBattle {
            __typename
            items {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateUserSubscription>>;

  OnUpdateUserListener: Observable<
    SubscriptionResponse<OnUpdateUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          id
          cognitoSub
          displayName
          ordersOfBattle {
            __typename
            items {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateUserSubscription>>;

  OnDeleteUserListener: Observable<
    SubscriptionResponse<OnDeleteUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          id
          cognitoSub
          displayName
          ordersOfBattle {
            __typename
            items {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteUserSubscription>>;

  OnCreateCrusadeOrderOfBattleListener: Observable<
    SubscriptionResponse<OnCreateCrusadeOrderOfBattleSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCrusadeOrderOfBattle {
        onCreateCrusadeOrderOfBattle {
          __typename
          id
          userId
          name
          faction
          supplyLimit
          supplyUsed
          battleTally
          battlesWon
          requisitionPoints
          notes
          cards {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          requisitions {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              type
              description
              requisitionPointCost
              affectedCardCrusadePoints
              free
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateCrusadeOrderOfBattleSubscription>
  >;

  OnUpdateCrusadeOrderOfBattleListener: Observable<
    SubscriptionResponse<OnUpdateCrusadeOrderOfBattleSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCrusadeOrderOfBattle {
        onUpdateCrusadeOrderOfBattle {
          __typename
          id
          userId
          name
          faction
          supplyLimit
          supplyUsed
          battleTally
          battlesWon
          requisitionPoints
          notes
          cards {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          requisitions {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              type
              description
              requisitionPointCost
              affectedCardCrusadePoints
              free
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateCrusadeOrderOfBattleSubscription>
  >;

  OnDeleteCrusadeOrderOfBattleListener: Observable<
    SubscriptionResponse<OnDeleteCrusadeOrderOfBattleSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCrusadeOrderOfBattle {
        onDeleteCrusadeOrderOfBattle {
          __typename
          id
          userId
          name
          faction
          supplyLimit
          supplyUsed
          battleTally
          battlesWon
          requisitionPoints
          notes
          cards {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              unitName
              powerRating
              crusadePoints
              experiencePoints
              battlefieldRole
              crusadeFaction
              selectableKeywords
              unitType
              equipment
              psychicPowers
              warlordTraits
              relics
              otherUpgrades
              canGainExperience
              isTitanic
              battleTally
              killTally
              markedForGreatnessTally
              sortOrder
              rank
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              honours {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  description
                  crusadeHonourTypeID
                  crusadeHonourSourceID
                  crusadeHonourSourceIdentifier
                  crusadePoints
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          requisitions {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              type
              description
              requisitionPointCost
              affectedCardCrusadePoints
              free
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              cognitoSub
              displayName
              ordersOfBattle {
                __typename
                items {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          battleParticipations {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteCrusadeOrderOfBattleSubscription>
  >;

  OnCreateCrusadeCardListener: Observable<
    SubscriptionResponse<OnCreateCrusadeCardSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCrusadeCard {
        onCreateCrusadeCard {
          __typename
          id
          orderOfBattleID
          unitName
          powerRating
          crusadePoints
          experiencePoints
          battlefieldRole
          crusadeFaction
          selectableKeywords
          unitType
          equipment
          psychicPowers
          warlordTraits
          relics
          otherUpgrades
          canGainExperience
          isTitanic
          battleTally
          killTally
          markedForGreatnessTally
          sortOrder
          rank
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          honours {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              description
              crusadeHonourTypeID
              crusadeHonourSourceID
              crusadeHonourSourceIdentifier
              crusadePoints
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              card {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCrusadeCardSubscription>>;

  OnUpdateCrusadeCardListener: Observable<
    SubscriptionResponse<OnUpdateCrusadeCardSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCrusadeCard {
        onUpdateCrusadeCard {
          __typename
          id
          orderOfBattleID
          unitName
          powerRating
          crusadePoints
          experiencePoints
          battlefieldRole
          crusadeFaction
          selectableKeywords
          unitType
          equipment
          psychicPowers
          warlordTraits
          relics
          otherUpgrades
          canGainExperience
          isTitanic
          battleTally
          killTally
          markedForGreatnessTally
          sortOrder
          rank
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          honours {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              description
              crusadeHonourTypeID
              crusadeHonourSourceID
              crusadeHonourSourceIdentifier
              crusadePoints
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              card {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCrusadeCardSubscription>>;

  OnDeleteCrusadeCardListener: Observable<
    SubscriptionResponse<OnDeleteCrusadeCardSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCrusadeCard {
        onDeleteCrusadeCard {
          __typename
          id
          orderOfBattleID
          unitName
          powerRating
          crusadePoints
          experiencePoints
          battlefieldRole
          crusadeFaction
          selectableKeywords
          unitType
          equipment
          psychicPowers
          warlordTraits
          relics
          otherUpgrades
          canGainExperience
          isTitanic
          battleTally
          killTally
          markedForGreatnessTally
          sortOrder
          rank
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          honours {
            __typename
            items {
              __typename
              id
              orderOfBattleID
              crusadeCardID
              description
              crusadeHonourTypeID
              crusadeHonourSourceID
              crusadeHonourSourceIdentifier
              crusadePoints
              isDeactivated
              orderOfBattle {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              card {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCrusadeCardSubscription>>;

  OnCreateCrusadeRequisitionListener: Observable<
    SubscriptionResponse<OnCreateCrusadeRequisitionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCrusadeRequisition {
        onCreateCrusadeRequisition {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          type
          description
          requisitionPointCost
          affectedCardCrusadePoints
          free
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCrusadeRequisitionSubscription>>;

  OnUpdateCrusadeRequisitionListener: Observable<
    SubscriptionResponse<OnUpdateCrusadeRequisitionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCrusadeRequisition {
        onUpdateCrusadeRequisition {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          type
          description
          requisitionPointCost
          affectedCardCrusadePoints
          free
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCrusadeRequisitionSubscription>>;

  OnDeleteCrusadeRequisitionListener: Observable<
    SubscriptionResponse<OnDeleteCrusadeRequisitionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCrusadeRequisition {
        onDeleteCrusadeRequisition {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          type
          description
          requisitionPointCost
          affectedCardCrusadePoints
          free
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCrusadeRequisitionSubscription>>;

  OnCreateCrusadeHonourListener: Observable<
    SubscriptionResponse<OnCreateCrusadeHonourSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCrusadeHonour {
        onCreateCrusadeHonour {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          description
          crusadeHonourTypeID
          crusadeHonourSourceID
          crusadeHonourSourceIdentifier
          crusadePoints
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          card {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCrusadeHonourSubscription>>;

  OnUpdateCrusadeHonourListener: Observable<
    SubscriptionResponse<OnUpdateCrusadeHonourSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCrusadeHonour {
        onUpdateCrusadeHonour {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          description
          crusadeHonourTypeID
          crusadeHonourSourceID
          crusadeHonourSourceIdentifier
          crusadePoints
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          card {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCrusadeHonourSubscription>>;

  OnDeleteCrusadeHonourListener: Observable<
    SubscriptionResponse<OnDeleteCrusadeHonourSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCrusadeHonour {
        onDeleteCrusadeHonour {
          __typename
          id
          orderOfBattleID
          crusadeCardID
          description
          crusadeHonourTypeID
          crusadeHonourSourceID
          crusadeHonourSourceIdentifier
          crusadePoints
          isDeactivated
          orderOfBattle {
            __typename
            id
            userId
            name
            faction
            supplyLimit
            supplyUsed
            battleTally
            battlesWon
            requisitionPoints
            notes
            cards {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                unitName
                powerRating
                crusadePoints
                experiencePoints
                battlefieldRole
                crusadeFaction
                selectableKeywords
                unitType
                equipment
                psychicPowers
                warlordTraits
                relics
                otherUpgrades
                canGainExperience
                isTitanic
                battleTally
                killTally
                markedForGreatnessTally
                sortOrder
                rank
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                honours {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            requisitions {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                type
                description
                requisitionPointCost
                affectedCardCrusadePoints
                free
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          card {
            __typename
            id
            orderOfBattleID
            unitName
            powerRating
            crusadePoints
            experiencePoints
            battlefieldRole
            crusadeFaction
            selectableKeywords
            unitType
            equipment
            psychicPowers
            warlordTraits
            relics
            otherUpgrades
            canGainExperience
            isTitanic
            battleTally
            killTally
            markedForGreatnessTally
            sortOrder
            rank
            isDeactivated
            orderOfBattle {
              __typename
              id
              userId
              name
              faction
              supplyLimit
              supplyUsed
              battleTally
              battlesWon
              requisitionPoints
              notes
              cards {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                nextToken
              }
              requisitions {
                __typename
                items {
                  __typename
                  id
                  orderOfBattleID
                  crusadeCardID
                  type
                  description
                  requisitionPointCost
                  affectedCardCrusadePoints
                  free
                  createdAt
                  updatedAt
                }
                nextToken
              }
              user {
                __typename
                items {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                nextToken
              }
              battleParticipations {
                __typename
                items {
                  __typename
                  id
                  crusadeBattleRecordID
                  userID
                  orderOfBattleID
                  armyTotalPL
                  armyTotalCrusadePoints
                  bonusCommandPoints
                  warlordCrusadeCardID
                  isAttacker
                  isGoingFirst
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            honours {
              __typename
              items {
                __typename
                id
                orderOfBattleID
                crusadeCardID
                description
                crusadeHonourTypeID
                crusadeHonourSourceID
                crusadeHonourSourceIdentifier
                crusadePoints
                isDeactivated
                orderOfBattle {
                  __typename
                  id
                  userId
                  name
                  faction
                  supplyLimit
                  supplyUsed
                  battleTally
                  battlesWon
                  requisitionPoints
                  notes
                  createdAt
                  updatedAt
                }
                card {
                  __typename
                  id
                  orderOfBattleID
                  unitName
                  powerRating
                  crusadePoints
                  experiencePoints
                  battlefieldRole
                  crusadeFaction
                  selectableKeywords
                  unitType
                  equipment
                  psychicPowers
                  warlordTraits
                  relics
                  otherUpgrades
                  canGainExperience
                  isTitanic
                  battleTally
                  killTally
                  markedForGreatnessTally
                  sortOrder
                  rank
                  isDeactivated
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCrusadeHonourSubscription>>;

  OnCreateCrusadeBattleRecordListener: Observable<
    SubscriptionResponse<OnCreateCrusadeBattleRecordSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCrusadeBattleRecord {
        onCreateCrusadeBattleRecord {
          __typename
          id
          gameSizeID
          step
          date
          mission
          description
          twitchUrl
          participants {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateCrusadeBattleRecordSubscription>
  >;

  OnUpdateCrusadeBattleRecordListener: Observable<
    SubscriptionResponse<OnUpdateCrusadeBattleRecordSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCrusadeBattleRecord {
        onUpdateCrusadeBattleRecord {
          __typename
          id
          gameSizeID
          step
          date
          mission
          description
          twitchUrl
          participants {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateCrusadeBattleRecordSubscription>
  >;

  OnDeleteCrusadeBattleRecordListener: Observable<
    SubscriptionResponse<OnDeleteCrusadeBattleRecordSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCrusadeBattleRecord {
        onDeleteCrusadeBattleRecord {
          __typename
          id
          gameSizeID
          step
          date
          mission
          description
          twitchUrl
          participants {
            __typename
            items {
              __typename
              id
              crusadeBattleRecordID
              userID
              orderOfBattleID
              armyTotalPL
              armyTotalCrusadePoints
              bonusCommandPoints
              warlordCrusadeCardID
              isAttacker
              isGoingFirst
              battleRecord {
                __typename
                id
                gameSizeID
                step
                date
                mission
                description
                twitchUrl
                participants {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteCrusadeBattleRecordSubscription>
  >;

  OnCreateCrusadeBattleParticipantListener: Observable<
    SubscriptionResponse<OnCreateCrusadeBattleParticipantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCrusadeBattleParticipant {
        onCreateCrusadeBattleParticipant {
          __typename
          id
          crusadeBattleRecordID
          userID
          orderOfBattleID
          armyTotalPL
          armyTotalCrusadePoints
          bonusCommandPoints
          warlordCrusadeCardID
          isAttacker
          isGoingFirst
          battleRecord {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateCrusadeBattleParticipantSubscription>
  >;

  OnUpdateCrusadeBattleParticipantListener: Observable<
    SubscriptionResponse<OnUpdateCrusadeBattleParticipantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCrusadeBattleParticipant {
        onUpdateCrusadeBattleParticipant {
          __typename
          id
          crusadeBattleRecordID
          userID
          orderOfBattleID
          armyTotalPL
          armyTotalCrusadePoints
          bonusCommandPoints
          warlordCrusadeCardID
          isAttacker
          isGoingFirst
          battleRecord {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateCrusadeBattleParticipantSubscription>
  >;

  OnDeleteCrusadeBattleParticipantListener: Observable<
    SubscriptionResponse<OnDeleteCrusadeBattleParticipantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCrusadeBattleParticipant {
        onDeleteCrusadeBattleParticipant {
          __typename
          id
          crusadeBattleRecordID
          userID
          orderOfBattleID
          armyTotalPL
          armyTotalCrusadePoints
          bonusCommandPoints
          warlordCrusadeCardID
          isAttacker
          isGoingFirst
          battleRecord {
            __typename
            id
            gameSizeID
            step
            date
            mission
            description
            twitchUrl
            participants {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteCrusadeBattleParticipantSubscription>
  >;

  OnCreateCivilizationDraftListener: Observable<
    SubscriptionResponse<OnCreateCivilizationDraftSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCivilizationDraft {
        onCreateCivilizationDraft {
          __typename
          id
          ownerID
          date
          isBlind
          winnerID
          secretKey
          owner {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          participants {
            __typename
            items {
              __typename
              id
              userID
              civilizationDraftID
              bannedLeaderID
              bannedMapID
              votedMapID
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              draftedLeaders {
                __typename
                items {
                  __typename
                  id
                  civilizationDraftParticipantID
                  draftedLeaderID
                  selected
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          maps {
            __typename
            items {
              __typename
              id
              civilizationDraftID
              mapId
              selected
              map {
                __typename
                id
                name
                minPlayers
                maxPlayers
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCivilizationDraftSubscription>>;

  OnUpdateCivilizationDraftListener: Observable<
    SubscriptionResponse<OnUpdateCivilizationDraftSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCivilizationDraft {
        onUpdateCivilizationDraft {
          __typename
          id
          ownerID
          date
          isBlind
          winnerID
          secretKey
          owner {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          participants {
            __typename
            items {
              __typename
              id
              userID
              civilizationDraftID
              bannedLeaderID
              bannedMapID
              votedMapID
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              draftedLeaders {
                __typename
                items {
                  __typename
                  id
                  civilizationDraftParticipantID
                  draftedLeaderID
                  selected
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          maps {
            __typename
            items {
              __typename
              id
              civilizationDraftID
              mapId
              selected
              map {
                __typename
                id
                name
                minPlayers
                maxPlayers
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCivilizationDraftSubscription>>;

  OnDeleteCivilizationDraftListener: Observable<
    SubscriptionResponse<OnDeleteCivilizationDraftSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCivilizationDraft {
        onDeleteCivilizationDraft {
          __typename
          id
          ownerID
          date
          isBlind
          winnerID
          secretKey
          owner {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          participants {
            __typename
            items {
              __typename
              id
              userID
              civilizationDraftID
              bannedLeaderID
              bannedMapID
              votedMapID
              user {
                __typename
                id
                cognitoSub
                displayName
                ordersOfBattle {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              draftedLeaders {
                __typename
                items {
                  __typename
                  id
                  civilizationDraftParticipantID
                  draftedLeaderID
                  selected
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          maps {
            __typename
            items {
              __typename
              id
              civilizationDraftID
              mapId
              selected
              map {
                __typename
                id
                name
                minPlayers
                maxPlayers
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCivilizationDraftSubscription>>;

  OnCreateCivilizationDraftParticipantListener: Observable<
    SubscriptionResponse<OnCreateCivilizationDraftParticipantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCivilizationDraftParticipant {
        onCreateCivilizationDraftParticipant {
          __typename
          id
          userID
          civilizationDraftID
          bannedLeaderID
          bannedMapID
          votedMapID
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          draftedLeaders {
            __typename
            items {
              __typename
              id
              civilizationDraftParticipantID
              draftedLeaderID
              selected
              leader {
                __typename
                id
                name
                civilizationName
                infoUrl
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateCivilizationDraftParticipantSubscription>
  >;

  OnUpdateCivilizationDraftParticipantListener: Observable<
    SubscriptionResponse<OnUpdateCivilizationDraftParticipantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCivilizationDraftParticipant {
        onUpdateCivilizationDraftParticipant {
          __typename
          id
          userID
          civilizationDraftID
          bannedLeaderID
          bannedMapID
          votedMapID
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          draftedLeaders {
            __typename
            items {
              __typename
              id
              civilizationDraftParticipantID
              draftedLeaderID
              selected
              leader {
                __typename
                id
                name
                civilizationName
                infoUrl
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateCivilizationDraftParticipantSubscription>
  >;

  OnDeleteCivilizationDraftParticipantListener: Observable<
    SubscriptionResponse<OnDeleteCivilizationDraftParticipantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCivilizationDraftParticipant {
        onDeleteCivilizationDraftParticipant {
          __typename
          id
          userID
          civilizationDraftID
          bannedLeaderID
          bannedMapID
          votedMapID
          user {
            __typename
            id
            cognitoSub
            displayName
            ordersOfBattle {
              __typename
              items {
                __typename
                id
                userId
                name
                faction
                supplyLimit
                supplyUsed
                battleTally
                battlesWon
                requisitionPoints
                notes
                cards {
                  __typename
                  nextToken
                }
                requisitions {
                  __typename
                  nextToken
                }
                user {
                  __typename
                  nextToken
                }
                battleParticipations {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            battleParticipations {
              __typename
              items {
                __typename
                id
                crusadeBattleRecordID
                userID
                orderOfBattleID
                armyTotalPL
                armyTotalCrusadePoints
                bonusCommandPoints
                warlordCrusadeCardID
                isAttacker
                isGoingFirst
                battleRecord {
                  __typename
                  id
                  gameSizeID
                  step
                  date
                  mission
                  description
                  twitchUrl
                  createdAt
                  updatedAt
                }
                user {
                  __typename
                  id
                  cognitoSub
                  displayName
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          draftedLeaders {
            __typename
            items {
              __typename
              id
              civilizationDraftParticipantID
              draftedLeaderID
              selected
              leader {
                __typename
                id
                name
                civilizationName
                infoUrl
                createdAt
                updatedAt
                owner
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteCivilizationDraftParticipantSubscription>
  >;

  OnCreateCivilizationDraftParticipantDraftedLeadersListener: Observable<
    SubscriptionResponse<
      OnCreateCivilizationDraftParticipantDraftedLeadersSubscription
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCivilizationDraftParticipantDraftedLeaders {
        onCreateCivilizationDraftParticipantDraftedLeaders {
          __typename
          id
          civilizationDraftParticipantID
          draftedLeaderID
          selected
          leader {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      OnCreateCivilizationDraftParticipantDraftedLeadersSubscription
    >
  >;

  OnUpdateCivilizationDraftParticipantDraftedLeadersListener: Observable<
    SubscriptionResponse<
      OnUpdateCivilizationDraftParticipantDraftedLeadersSubscription
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCivilizationDraftParticipantDraftedLeaders {
        onUpdateCivilizationDraftParticipantDraftedLeaders {
          __typename
          id
          civilizationDraftParticipantID
          draftedLeaderID
          selected
          leader {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      OnUpdateCivilizationDraftParticipantDraftedLeadersSubscription
    >
  >;

  OnDeleteCivilizationDraftParticipantDraftedLeadersListener: Observable<
    SubscriptionResponse<
      OnDeleteCivilizationDraftParticipantDraftedLeadersSubscription
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCivilizationDraftParticipantDraftedLeaders {
        onDeleteCivilizationDraftParticipantDraftedLeaders {
          __typename
          id
          civilizationDraftParticipantID
          draftedLeaderID
          selected
          leader {
            __typename
            id
            name
            civilizationName
            infoUrl
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      OnDeleteCivilizationDraftParticipantDraftedLeadersSubscription
    >
  >;

  OnCreateCivilizationDraftDraftedMapsListener: Observable<
    SubscriptionResponse<OnCreateCivilizationDraftDraftedMapsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCivilizationDraftDraftedMaps {
        onCreateCivilizationDraftDraftedMaps {
          __typename
          id
          civilizationDraftID
          mapId
          selected
          map {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateCivilizationDraftDraftedMapsSubscription>
  >;

  OnUpdateCivilizationDraftDraftedMapsListener: Observable<
    SubscriptionResponse<OnUpdateCivilizationDraftDraftedMapsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCivilizationDraftDraftedMaps {
        onUpdateCivilizationDraftDraftedMaps {
          __typename
          id
          civilizationDraftID
          mapId
          selected
          map {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateCivilizationDraftDraftedMapsSubscription>
  >;

  OnDeleteCivilizationDraftDraftedMapsListener: Observable<
    SubscriptionResponse<OnDeleteCivilizationDraftDraftedMapsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCivilizationDraftDraftedMaps {
        onDeleteCivilizationDraftDraftedMaps {
          __typename
          id
          civilizationDraftID
          mapId
          selected
          map {
            __typename
            id
            name
            minPlayers
            maxPlayers
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteCivilizationDraftDraftedMapsSubscription>
  >;

  OnCreateCivilizationLeaderListener: Observable<
    SubscriptionResponse<OnCreateCivilizationLeaderSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCivilizationLeader {
        onCreateCivilizationLeader {
          __typename
          id
          name
          civilizationName
          infoUrl
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCivilizationLeaderSubscription>>;

  OnUpdateCivilizationLeaderListener: Observable<
    SubscriptionResponse<OnUpdateCivilizationLeaderSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCivilizationLeader {
        onUpdateCivilizationLeader {
          __typename
          id
          name
          civilizationName
          infoUrl
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCivilizationLeaderSubscription>>;

  OnDeleteCivilizationLeaderListener: Observable<
    SubscriptionResponse<OnDeleteCivilizationLeaderSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCivilizationLeader {
        onDeleteCivilizationLeader {
          __typename
          id
          name
          civilizationName
          infoUrl
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCivilizationLeaderSubscription>>;

  OnCreateCivilizationMapListener: Observable<
    SubscriptionResponse<OnCreateCivilizationMapSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCivilizationMap {
        onCreateCivilizationMap {
          __typename
          id
          name
          minPlayers
          maxPlayers
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCivilizationMapSubscription>>;

  OnUpdateCivilizationMapListener: Observable<
    SubscriptionResponse<OnUpdateCivilizationMapSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCivilizationMap {
        onUpdateCivilizationMap {
          __typename
          id
          name
          minPlayers
          maxPlayers
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCivilizationMapSubscription>>;

  OnDeleteCivilizationMapListener: Observable<
    SubscriptionResponse<OnDeleteCivilizationMapSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCivilizationMap {
        onDeleteCivilizationMap {
          __typename
          id
          name
          minPlayers
          maxPlayers
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCivilizationMapSubscription>>;
}
