import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";
import { LoginComponent } from "./login.component";

@NgModule({
   imports: [CommonModule, RouterModule, AmplifyUIAngularModule],
   declarations: [LoginComponent],
})
export class LoginModule {}
