import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthenticationRequiredGuard } from "../authentication-required.guard";
import { CurrentUserService } from "../current-user.service";

@Component({
   selector: "fun-login",
   templateUrl: "./login.component.html",
   styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
   private unsubscribe = new Subject<void>();
   private returnUrl: string = "/";

   constructor(private route: ActivatedRoute, private router: Router, private currentUserService: CurrentUserService) {}

   ngOnInit() {
      this.currentUserService.updateLoggingIn(true);
      this.returnUrl = this.route.snapshot.queryParams[AuthenticationRequiredGuard.returnUrlQueryParamName] || "/";

      this.currentUserService.userChanged.pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
         if (user) {
            this.router.navigateByUrl(this.returnUrl);
         }
      });
   }

   ngOnDestroy(): void {
      this.currentUserService.updateLoggingIn(false);
      this.unsubscribe.next();
   }
}
