import { CreateCrusadeRequisitionInput, UpdateCrusadeRequisitionInput } from "src/app/API.service";
import { CrusadeRequisition } from "src/types/crusade-requisition";
import { CrusadeRequisitionTypes } from "./crusade-requisition-type.enum";

export class CrusadeRequisitionModel {
   id: string | null = null;
   orderOfBattleID: string | null = null;
   crusadeCardID: string | null = null;
   type: CrusadeRequisitionTypes = CrusadeRequisitionTypes.IncreaseSupplyLimit;
   description: string = "";
   requisitionPointCost: number = 1;
   affectedCardCrusadePoints: number | null = 0;
   free: boolean = false;

   affectedCardIdentifier: string | null = null;
   delete: boolean = false;

   static fromQueryModel(item: CrusadeRequisition): CrusadeRequisitionModel {
      const result = new CrusadeRequisitionModel();

      result.id = item.id;
      result.orderOfBattleID = item.orderOfBattleID;
      result.crusadeCardID = item.crusadeCardID;
      result.type = item.type;
      result.description = item.description;
      result.requisitionPointCost = item.requisitionPointCost;
      result.affectedCardCrusadePoints = item.affectedCardCrusadePoints;
      result.free = item.free ?? false;
      return result;
   }

   static toCreateModel(item: CrusadeRequisitionModel): CreateCrusadeRequisitionInput {
      if (!item.orderOfBattleID) {
         throw new Error("Cannot save requisition  - unknown order of battle");
      }
      const result: CreateCrusadeRequisitionInput = {
         id: item.id,
         orderOfBattleID: item.orderOfBattleID,
         crusadeCardID: item.crusadeCardID,
         type: item.type,
         description: item.description,
         requisitionPointCost: item.requisitionPointCost,
         affectedCardCrusadePoints: item.affectedCardCrusadePoints,
         free: item.free,
      };
      return result;
   }

   static toUpdateModel(item: CrusadeRequisitionModel): UpdateCrusadeRequisitionInput {
      if (!item.orderOfBattleID) {
         throw new Error("Cannot save requisition  - unknown order of battle");
      }
      if (!item.id) {
         throw new Error("Cannot save requisition  - unknown id");
      }
      const result: UpdateCrusadeRequisitionInput = {
         id: item.id,
         orderOfBattleID: item.orderOfBattleID,
         crusadeCardID: item.crusadeCardID,
         type: item.type,
         description: item.description,
         requisitionPointCost: item.requisitionPointCost,
         affectedCardCrusadePoints: item.affectedCardCrusadePoints,
         free: item.free,
      };
      return result;
   }
}
