import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentUserService } from "src/app/authentication/current-user.service";
import { OrderOfBattleModel } from "../models/order-of-battle.model";
import { OrderOfBattleSaveService } from "../services/order-of-battle-save.service";

@Component({
   selector: "fun-add-crusade-order-of-battle",
   templateUrl: "./add-crusade-order-of-battle.component.html",
   styleUrls: ["./add-crusade-order-of-battle.component.scss"],
   providers: [OrderOfBattleSaveService],
})
export class AddCrusadeOrderOfBattleComponent implements OnInit, OnDestroy {
   formBusy = false;

   formDisabled = false;

   constructor(
      private router: Router,
      private currentUserService: CurrentUserService,
      private orderOfBattleSaveService: OrderOfBattleSaveService
   ) {}

   ngOnDestroy(): void {}

   ngOnInit() {}

   onFormSubmitted(orderOfBattle: OrderOfBattleModel) {
      this.formBusy = true;
      if (!this.currentUserService.currentUser || !this.currentUserService.currentUser.id) {
         alert("Unable to save Order Of Battle. Unable to determine logged in user.");
         return;
      }
      orderOfBattle.userId = this.currentUserService.currentUser.id;
      this.orderOfBattleSaveService
         .createOrderOfBattle(orderOfBattle)
         .then((event) => {
            alert("Saved Successfully");
            this.router.navigate([`crusade/orders-of-battle/${event.id}`]);
         })
         .catch((e) => {
            console.log("error creating Order Of Battle Card...", e);
            alert(`Error creating Order Of Battle: \r\n${e}`);
         });
   }
}
