import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { APIService } from "src/app/API.service";
import { User } from "src/types/user";

@Component({
   selector: "fun-add-user",
   templateUrl: "./add-user.component.html",
   styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
   createForm: FormGroup | null = null;

   constructor(private apiService: APIService, private formBuilder: FormBuilder) {}

   ngOnInit() {
      this.createForm = this.formBuilder.group({
         name: ["", Validators.required],
      });
   }

   onCreate(user: User) {
      this.apiService
         .CreateUser(user)
         .then((event) => {
            console.log("item created!");
            if (this.createForm) {
               this.createForm.reset();
            }
         })
         .catch((e) => {
            console.log("error creating user...", e);
            alert(`error creating user: \r\n${e}`);
         });
   }
}
