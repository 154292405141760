import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormHelper } from "src/app/forms/form-helper";
import { User } from "src/types/user";
import { CrusadeBattleRecordParticipantModel } from "../../models/battle-record/crusade-battle-record-participant.model";
import { CrusadeBattleRecordModel } from "../../models/battle-record/crusade-battle-record.model";
import { CrusadeBattleSizes } from "../../models/battle-record/crusade-battle-sizes.enum";

@Component({
   selector: "fun-battle-record-challenge",
   templateUrl: "./battle-record-challenge-form.component.html",
   styleUrls: ["./battle-record-challenge-form.component.scss"],
})
export class BattleRecordChallengeFormComponent implements OnInit {
   @Input()
   formBusy: boolean = true;

   @Input()
   formDisabled: boolean = true;

   @Input()
   users: Array<User> = new Array<User>();

   @Input()
   set currentUser(val: User | null) {
      if (this.formGroup) {
         if (val) {
            FormHelper.getControl(this.formGroup, "participant1Id").setValue(val.id);
         } else {
            FormHelper.getControl(this.formGroup, "participant1Id").setValue(null);
         }
      }
   }

   @Output()
   formSubmitted = new EventEmitter<CrusadeBattleRecordModel>();

   crusadeBattleSizes = CrusadeBattleSizes;

   formGroup: FormGroup = this.formBuilder.group({
      participant1Id: [""],
      participant2Id: [""],
      gameSizeID: [CrusadeBattleSizes.CombatPatrol],
   });

   constructor(private formBuilder: FormBuilder) {}

   ngOnInit() {}

   onSubmit() {
      const battleRecord = new CrusadeBattleRecordModel();
      battleRecord.gameSizeID = +FormHelper.getControl(this.formGroup, "gameSizeID").value;
      const participant1 = new CrusadeBattleRecordParticipantModel();
      participant1.userID = FormHelper.getControl(this.formGroup, "participant1Id").value;
      battleRecord.participants.push(participant1);

      const participant2 = new CrusadeBattleRecordParticipantModel();
      participant2.userID = FormHelper.getControl(this.formGroup, "participant2Id").value;
      battleRecord.participants.push(participant2);

      this.formSubmitted.next(battleRecord);
   }
}
