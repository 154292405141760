import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormHelper } from "src/app/forms/form-helper";
import { CivilizationLeader } from "src/types/civilization-drafter/civilization-leader";
import { CivilizationMap } from "src/types/civilization-drafter/civilization-map";
import { User } from "src/types/user";
import { DraftFormModel } from "../draft-form/models/draft-form.model";
import { DraftParticipantModel } from "../draft-form/models/draft-participant.model";
import { DraftStatuses } from "./models/draft-statuses.enum";

@Component({
   selector: "fun-draft-details-form",
   templateUrl: "./draft-details-form.component.html",
   styleUrls: ["./draft-details-form.component.scss"],
})
export class DraftDetailsFormComponent implements OnInit, OnChanges {
   @Input()
   formBusy: boolean = true;

   @Input()
   formDisabled: boolean = true;

   @Input()
   set draft(val: DraftFormModel | null) {
      this._draft = val;
      if (val) {
         this.formGroup = this.buildForm(val);
         this.checkAccess();
      }
   }

   get draft(): DraftFormModel | null {
      return this._draft;
   }

   @Input()
   set currentUser(val: User | null) {
      this._currentUser = val;
      this.checkAccess();
   }

   get currentUser(): User | null {
      return this._currentUser;
   }

   @Input()
   inviteLink: string = "";

   @Input()
   joinDraftCode: string = "";

   @Input()
   leaders = new Array<CivilizationLeader>();

   @Input()
   maps = new Array<CivilizationMap>();

   @Output()
   formSubmitted = new EventEmitter<DraftFormModel>();

   @Output()
   joinDraftClicked = new EventEmitter<string>();

   @Output()
   participationFormSubmitted = new EventEmitter<DraftParticipantModel>();

   @Output()
   draftMapsClicked = new EventEmitter<void>();

   @Output()
   draftLeadersClicked = new EventEmitter<void>();

   formGroup: FormGroup = this.buildForm(new DraftFormModel());

   currentParticipationForm: FormGroup | null = null;

   draftStatus: DraftStatuses = DraftStatuses.Unknown;

   draftStatusMessage = "";

   draftStatuses = DraftStatuses;

   currentUserOwnsDraft = false;

   draftedMaps: Array<string> | null = null;

   currentUserParticipation: DraftParticipantModel | null = null;

   inviteLinkText = "copy invite link to clipboard";

   disabledInviteLinkText = "save form before creating link";

   private _currentUser: User | null = null;

   private _draft: DraftFormModel | null = null;

   constructor() {}

   ngOnInit() {}

   ngOnChanges(changes: SimpleChanges): void {
      if (changes.leaders) {
         this.updateCurrentParticipantLeaderNames();
      }
   }

   onSubmit() {
      this.formSubmitted.next(this.formGroup.getRawValue() as DraftFormModel);
   }

   onSubmitParticipation() {
      if (this.currentParticipationForm) {
         this.participationFormSubmitted.next(this.currentParticipationForm.getRawValue() as DraftParticipantModel);
      }
   }

   joinDraft() {
      this.joinDraftClicked.next(this.joinDraftCode);
   }

   draftMaps() {
      this.draftMapsClicked.next();
   }

   draftLeaders() {
      this.draftLeadersClicked.next();
   }

   private buildForm(formData: DraftFormModel): FormGroup {
      const formGroup = FormHelper.buildForm(formData);

      if (formData.maps.length === 0) {
         this.draftedMaps = new Array<string>();
      } else {
         const draftedMaps = new Array<string>();
         for (const map of formData.maps) {
            const name = map.name;
            const voteCount = formData.participants.filter((p) => p.votedMapID != null && p.votedMapID === map.mapID)
               .length;
            draftedMaps.push(`${name} - ${voteCount} votes`);
         }
         this.draftedMaps = draftedMaps;
      }

      this.draftStatus = this.calculateDraftStatus(formData);
      return formGroup;
   }

   private calculateDraftStatus(draft: DraftFormModel): DraftStatuses {
      if (draft.maps.length === 0) {
         this.draftStatusMessage = `Waiting for players. ${draft.participants.length} so far!`;
         return DraftStatuses.WaitingForMapBans;
      }

      const participantsWithLeaders = draft.participants.filter((p) => p.leaders.length > 0);
      if (participantsWithLeaders.length === 0) {
         this.draftStatusMessage = `Waiting for map votes!`;
         return DraftStatuses.WaitingForMapVotes;
      } else {
         this.draftStatusMessage = `Draft Complete! Have a fun time conquering the world!`;
         return DraftStatuses.DraftReady;
      }
   }

   private checkAccess() {
      if (!this.formGroup || !this._currentUser) {
         this.currentUserOwnsDraft = false;
      } else {
         if (FormHelper.getControl(this.formGroup, "ownerID").value === this._currentUser.id) {
            this.currentUserOwnsDraft = true;
         } else {
            this.currentUserOwnsDraft = false;
         }
      }

      if (!this.currentUserOwnsDraft) {
         FormHelper.disableForm(this.formGroup);
      } else {
         FormHelper.enableForm(this.formGroup);
      }

      if (this._draft) {
         if (!this.currentUser?.id) {
            this.currentUserParticipation = null;
            this.currentParticipationForm = null;
         } else {
            const matchingParticipants = this._draft.participants.filter((p) => p.userID === this.currentUser?.id);

            this.currentUserParticipation = null;
            this.currentParticipationForm = null;
            if (matchingParticipants.length > 0) {
               setTimeout(() => {
                  this.buildCurrentParticipationForm(matchingParticipants[0]);
                  this.updateSetupForm();
               });
            }
         }
      }
   }

   private buildCurrentParticipationForm(participant: DraftParticipantModel) {
      this.currentUserParticipation = participant;

      this.updateCurrentParticipantLeaderNames();

      this.currentParticipationForm = FormHelper.buildForm(this.currentUserParticipation);
      if (this.draftStatus === DraftStatuses.WaitingForMapBans) {
         FormHelper.getControl(this.currentParticipationForm, "votedMapID").disable();
      } else if (this.draftStatus === DraftStatuses.WaitingForMapVotes) {
         FormHelper.getControl(this.currentParticipationForm, "bannedMapID").disable();
      } else if (this.draftStatus === DraftStatuses.DraftReady) {
         FormHelper.getControl(this.currentParticipationForm, "bannedMapID").disable();
         FormHelper.getControl(this.currentParticipationForm, "bannedLeaderID").disable();
      }
   }

   private updateSetupForm() {
      if (this.draftStatus === DraftStatuses.DraftReady) {
         FormHelper.getControl(this.formGroup, "secretKey").disable();
      } else {
         FormHelper.getControl(this.formGroup, "secretKey").enable();
      }
   }

   private updateCurrentParticipantLeaderNames() {
      if (!this.draft?.participants || this.leaders.length === 0) {
         return;
      }
      const leaderMap = new Map<string, CivilizationLeader>();
      for (const leader of this.leaders) {
         if (leader.id) {
            leaderMap.set(leader.id, leader);
         }
      }
      for (const participant of this.draft.participants) {
         for (const leader of participant.leaders) {
            const matchingLeader = leaderMap.get(leader.leaderID);
            if (matchingLeader) {
               leader.name = `${matchingLeader.name} - ${matchingLeader.civilizationName}`;
               leader.infoUrl = matchingLeader.infoUrl;
            }
         }
      }
   }
}
