import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";
import { AddUserComponent } from "./add-user/add-user.component";
import { UserListComponent } from "./user-list/user-list.component";
import { ViewUserComponent } from "./view-user/view-user.component";

@NgModule({
  imports: [
    CommonModule,
    AmplifyUIAngularModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [AddUserComponent, ViewUserComponent, UserListComponent]
})
export class UsersModule { }
