<h1 class="page-header">Who are we?</h1>
<p>Nerds with too much time on our hands.</p>

<h1 class="page-header">
   Stuff
</h1>
<a [routerLink]="['/fundapocalypse']" class="button-link legacy">Warhammer 40k Fundapocalypse</a>
<a [routerLink]="['/civ-drafter']" class="button-link legacy">Civ Drafter (beta)</a>
<a [routerLink]="['/crusade/orders-of-battle']" class="button-link legacy">40k Crusade (alpha)</a>

<br /><br />
<h1 class="page-header">
   Events
</h1>
<p>
   MAYPOCALYPSE WAS AWESOME!!!!!
</p>

<h1 class="page-header">Follow us on Twitch!</h1>
<a href="https://twitch.tv/normanthesquid" class="twitch-badge" target="_blank">
   <img src="assets/TwitchGlitchWhite.svg" alt="twitch logo" />
   normanthesquid
</a>
<br />
<a href="https://twitch.tv/theedca" class="twitch-badge" target="_blank">
   <img src="assets/TwitchGlitchWhite.svg" alt="twitch logo" />
   theedca
</a>
<br />
<a href="https://twitch.tv/heinzz07" class="twitch-badge" target="_blank">
   <img src="assets/TwitchGlitchWhite.svg" alt="twitch logo" />
   heinzz07
</a>

<h1 class="page-header">Check out our bestest friends!!!</h1>

<a href="https://boltersatdawn.com/"  target="_blank">
   <img style="height:200px;" src="https://boltersatdawn.com/assets/images/bolters_at_dawn_small.png" />
</a>
