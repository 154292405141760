<h1 class="page-header">Create Order of Battle</h1>
<fun-order-of-battle-form
   [formBusy]="formBusy"
   [formDisabled]="formDisabled"
   (formSubmitted)="onFormSubmitted($event)"
></fun-order-of-battle-form>
<br />
<a [routerLink]="['/crusade/orders-of-battle']" class="button-link legacy">
   <i class="material-icons"> keyboard_backspace </i>
   back to list
</a>
