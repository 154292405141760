import { OrderOfBattle } from "src/types/order-of-battle";

export class OrderOfBattleTableModel {
   id: string = "";
   userId: string = "";
   userDisplayName: string = "";
   name: string = "";
   faction: string = "";

   static fromQueryModel(item: OrderOfBattle) {
      const result = new OrderOfBattleTableModel();
      if (!item) {
         throw new Error("Cannot load data - unable to load ID for Order of Battle");
      }
      if (!item.id) {
         throw new Error("Cannot load data - unable to load ID for Order of Battle");
      }
      result.id = item.id;
      result.userId = item.userId;
      result.name = item.name;
      result.faction = item.faction;

      if (
         item.user &&
         item.user?.items &&
         item.user?.items?.length > 0 &&
         item.user?.items[0] &&
         item.user?.items[0].displayName
      ) {
         result.userDisplayName = item.user?.items[0].displayName;
      } else {
         result.userDisplayName = "Random Randy";
      }

      return result;
   }
}
