<h1 class="page-header">Battle Record</h1>
<hr />
<h1 class="page-header">Pre Game</h1>
<ul>
   <li>Participants</li>
   <li>Game Size / Power Level</li>
</ul>
<hr />
<h1 class="page-header">Muster Armies</h1>
<div>
   <ul>
      <li>Ability to "Prep" roster secretly, then reveal both when both users are ready</li>
      <li>Ability to view opponents Order of Battle? Was explicitly a thing in killteam, not 100% sure here</li>
      <li>List of selected Cards</li>
      <li>Total PL</li>
      <li>Total Crusade Points</li>
      <li>Starting Command Points</li>
      <li>Bonus Command Points from Crusade Blessings (calculated after reveal)</li>
      <li>Warlord Selection</li>
   </ul>
</div>
<div>
   Does Not Include:
   <ul>
      <li>Detachments</li>
   </ul>
</div>

<hr />
<h1 class="page-header">Mission and Agendas</h1>
<div>
   <ul>
      <li>Mission (textbox)</li>
      <li>
         Ability to select agendas secretly, then reveal both when both users are ready
         <ul>
            <li>Count based on battle size</li>
         </ul>
      </li>
      <li>Attacker/Defender</li>
      <li>First Turn Rolloff Winner</li>
      <li>First Turn</li>
   </ul>
</div>

<hr />
<h1 class="page-header">During Game</h1>
<div>
   <ul>
      <li>Recording performance per Crusade Card</li>
      <ul>
         <li>Unit Kills</li>
         <li>Unit getting killed</li>
         <li>Progress for each agenda</li>
      </ul>
   </ul>
</div>

<hr />
<h1 class="page-header">Post Game</h1>
<div>
   <ul>
      <li>
         Victor Bonus (based on mission)
         <ul>
            <li>Bonus Marked for Greatness</li>
            <li>Bonus RP</li>
            <li>Bonus Battle Trait</li>
            <li>Bonus Relic</li>
            <li>Bonus Psychic Fortitude</li>
            <li>Bonus Weapon Enhancement</li>
            <li>Bonus Warlord Trait</li>
            <li>Bonus Character w\ Free Requisition(Warlord Trait, Relic, Specialist Reinforcement)</li>
            <li>Bonus Requisition (Supply Limit) x2</li>
            <li>Bonus Battle Honour (for warlord)</li>
            <li>
               Beyond the Veil
               <ul>
                  <li>Investigation Point (probably just gonna make an "other")</li>
                  <li>Bonus XP(3)</li>
                  <li>Bonus Requisition(Supply Limit) + Bonus Requisition(Rearm and Resupply)</li>
                  <li>Bonus Requisition (Repair and Recuperate) x2 OR bonus XP(1)</li>
                  <li>Bonus XP(3 or 5)</li>
                  <li>Bonus Marked for Greatness x2</li>
               </ul>
            </li>
         </ul>
      </li>
      <li>Recording Out of Action / Devastating Blow / Battle Scar</li>
      <li>
         Updating XP
         <ul>
            <li>Participation XP (1 xp per card)</li>
            <li>Marked for Greatness (1 card, 3 xp) </li>
            <li>Total Kills (1 per 3 kills, already on Order of Battle page)</li>
            <li>Agenda Results</li>
         </ul>
      </li>
      <li>
         Battle honors (probably gets added to Order of Battle page, not here)
      </li>
      <li>
         Combat Tallies (will be handled automatically on the Order of Battle page)
      </li>
      <li>
         Order of Battle updates
         <ul>
            <li>RP up by 1 - already calculated on Order of Battle</li>
         </ul>
      </li>
   </ul>
</div>
