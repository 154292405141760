import { CreateCrusadeHonourInput, UpdateCrusadeHonourInput } from "src/app/API.service";
import { CrusadeHonour } from "src/types/crusade-honour";
import { CrusadeHonourSources } from "./crusade-honour-sources.enum";
import { CrusadeHonourTypes } from "./crusade-honour-types.enum";

export class CrusadeHonourModel {
   id: string | null = null;
   crusadeCardID: string = "";
   orderOfBattleID: string = "";
   description: string = "";
   crusadeHonourTypeID: CrusadeHonourTypes = CrusadeHonourTypes.Other;
   crusadeHonourSourceID: CrusadeHonourSources = CrusadeHonourSources.Rank;
   crusadeHonourSourceIdentifier: string = "";
   crusadePoints: number = 0;
   isDeactivated: boolean = false;

   delete: boolean = false;

   static fromQueryModel(item: CrusadeHonour): CrusadeHonourModel {
      const result = new CrusadeHonourModel();

      result.id = item.id;
      result.crusadeCardID = item.crusadeCardID;
      result.orderOfBattleID = item.orderOfBattleID;
      result.description = item.description;
      result.crusadeHonourTypeID = item.crusadeHonourTypeID;
      result.crusadeHonourSourceID = item.crusadeHonourSourceID;
      result.crusadeHonourSourceIdentifier = item.crusadeHonourSourceIdentifier ?? "";
      result.crusadePoints = item.crusadePoints;
      result.isDeactivated = item.isDeactivated ?? false;
      return result;
   }

   static toCreateModel(item: CrusadeHonourModel): CreateCrusadeHonourInput {
    if (!item.orderOfBattleID) {
       throw new Error("Cannot save honour  - unknown order of battle");
    }
    if (!item.crusadeCardID) {
       throw new Error("Cannot save honour  - unknown card");
    }
    const result: CreateCrusadeHonourInput = {
       id: item.id,
       crusadeCardID: item.crusadeCardID,
       orderOfBattleID: item.orderOfBattleID,
       description: item.description,
       crusadeHonourTypeID: item.crusadeHonourTypeID,
       crusadeHonourSourceID: item.crusadeHonourSourceID,
       crusadeHonourSourceIdentifier: item.crusadeHonourSourceIdentifier,
       crusadePoints: item.crusadePoints,
       isDeactivated: item.isDeactivated,
    };
    return result;
 }

 static toUpdateModel(item: CrusadeHonourModel): UpdateCrusadeHonourInput {
    if (!item.orderOfBattleID) {
       throw new Error("Cannot save honour  - unknown order of battle");
    }
    if (!item.crusadeCardID) {
       throw new Error("Cannot save honour  - unknown card");
    }
    if (!item.id) {
       throw new Error("Cannot save honour  - unknown id");
    }
    const result: UpdateCrusadeHonourInput = {
        id: item.id,
        crusadeCardID: item.crusadeCardID,
        orderOfBattleID: item.orderOfBattleID,
        description: item.description,
        crusadeHonourTypeID: item.crusadeHonourTypeID,
        crusadeHonourSourceID: item.crusadeHonourSourceID,
        crusadeHonourSourceIdentifier: item.crusadeHonourSourceIdentifier,
        crusadePoints: item.crusadePoints,
        isDeactivated: item.isDeactivated,
    };
    return result;
 }
}
