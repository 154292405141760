import { Injectable } from "@angular/core";
import {
   APIService,
   CreateCrusadeBattleParticipantMutation,
   UpdateCrusadeBattleParticipantMutation
} from "src/app/API.service";
import { CrusadeBattleRecordParticipantModel } from "../../models/battle-record/crusade-battle-record-participant.model";
import { CrusadeBattleRecordModel } from "../../models/battle-record/crusade-battle-record.model";

@Injectable()
export class CrusadeBattleRecordSaveService {
   constructor(private apiService: APIService) {}

   createBattleRecord(crusadeBattle: CrusadeBattleRecordModel): Promise<CrusadeBattleRecordModel> {
      return this.apiService
         .CreateCrusadeBattleRecord(CrusadeBattleRecordModel.toCreateModel(crusadeBattle))
         .then((event) => {
            if (!event) {
               throw new Error("Error occurred while saving.");
            }
            crusadeBattle.id = event.id;
            return this.saveDependentObjects(crusadeBattle);
         });
   }

   private saveDependentObjects(crusadeBattle: CrusadeBattleRecordModel): Promise<CrusadeBattleRecordModel> {
      if (!crusadeBattle.id) {
         throw new Error("Error occurred while saving.");
      }
      const battleRecordID = crusadeBattle.id;
      if (crusadeBattle.participants) {
         return this.saveParticipants(battleRecordID, crusadeBattle.participants).then(() => {
            return crusadeBattle;
         });
      } else {
         return Promise.resolve(crusadeBattle);
      }
   }

   private saveParticipants(
      crusadeBattleRecordID: string,
      participants: Array<CrusadeBattleRecordParticipantModel> | null
   ): Promise<Array<CreateCrusadeBattleParticipantMutation | UpdateCrusadeBattleParticipantMutation> | null> {
      if (!participants || participants.length === 0) {
         return Promise.resolve(null);
      }
      const participantSavePromises = new Array<
         Promise<CreateCrusadeBattleParticipantMutation | UpdateCrusadeBattleParticipantMutation>
      >();
      for (const participant of participants) {
         participant.crusadeBattleRecordID = crusadeBattleRecordID;
         if (participant.id) {
            participantSavePromises.push(
               this.apiService.UpdateCrusadeBattleParticipant(
                  CrusadeBattleRecordParticipantModel.toUpdateModel(participant)
               )
            );
         } else {
            participant.orderOfBattleID = crusadeBattleRecordID;
            participantSavePromises.push(
               this.apiService.CreateCrusadeBattleParticipant(
                  CrusadeBattleRecordParticipantModel.toCreateModel(participant)
               )
            );
         }
      }
      return Promise.all(participantSavePromises);
   }
}
