import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { Subject } from "rxjs";
import { pairwise, takeUntil } from "rxjs/operators";
import { FormHelper } from "src/app/forms/form-helper";
import { CrusadeCardRanks } from "../models/crusade-card-rank.enum";
import { CrusadeCardModel } from "../models/crusade-card.model";
import { CrusadeHonourSources } from "../models/crusade-honour-sources.enum";
import { CrusadeHonourTypes } from "../models/crusade-honour-types.enum";
import { CrusadeHonourModel } from "../models/crusade-honour.model";
import { CrusadeRequisitionTypes } from "../models/crusade-requisition-type.enum";
import { CrusadeRequisitionModel } from "../models/crusade-requisition.model";
import { OrderOfBattleModel } from "../models/order-of-battle.model";

@Component({
   selector: "fun-order-of-battle-form",
   templateUrl: "./order-of-battle-form.component.html",
   styleUrls: ["./order-of-battle-form.component.scss"],
})
export class OrderOfBattleFormComponent implements OnInit, OnDestroy {
   @Input() set orderOfBattle(value: OrderOfBattleModel | null) {
      if (value) {
         while (this.cardsFormArray.length !== 0) {
            this.cardsFormArray.removeAt(0);
         }
         if (value.cards) {
            for (const card of value.cards) {
               this.addCard(card);
            }
         } else {
            this.addCard();
         }

         while (this.requisitionsFormArray.length !== 0) {
            this.requisitionsFormArray.removeAt(0);
         }
         if (value.requisitions) {
            for (const requisition of value.requisitions) {
               this.addRequisition(requisition);
            }
         } else {
            this.addRequisition();
         }
         this.formGroup.setValue(value);
      }
   }

   @Input()
   formBusy = true;

   @Input()
   formDisabled: boolean = true;

   @Output()
   formSubmitted = new EventEmitter<OrderOfBattleModel>();

   formGroup: FormGroup;

   cardsFormArray: FormArray;

   requisitionsFormArray: FormArray;

   formHelper = FormHelper;

   crusadeRequisitionTypes = CrusadeRequisitionTypes;

   crusadeCardRanks = CrusadeCardRanks;

   crusadeHonourTypes = CrusadeHonourTypes;

   crusadeHonourSources = CrusadeHonourSources;

   private expandMoreIcon = "expand_more";

   private expandLessIcon = "expand_less";

   private unsubscribe = new Subject<void>();

   constructor() {
      this.formGroup = FormHelper.buildForm(new OrderOfBattleModel());
      this.cardsFormArray = this.formGroup.get("cards") as FormArray;
      this.requisitionsFormArray = this.formGroup.get("requisitions") as FormArray;
      this.wireupValidators();
      this.addCard();
      this.addRequisition();
   }

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onSubmit() {
      this.formSubmitted.next(this.formGroup.getRawValue() as OrderOfBattleModel);
   }

   addCard(card?: CrusadeCardModel) {
      let cardForm: FormGroup;
      if (card) {
         cardForm = FormHelper.buildForm(card);
      } else {
         card = new CrusadeCardModel();
         cardForm = FormHelper.buildForm(card);
      }
      cardForm.addControl("rowExpanded", new FormControl(this.expandMoreIcon));

      FormHelper.getControl(cardForm, "isDeactivated")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe((value) => {
            if (value && !FormHelper.getControl(this.formGroup, "showInactiveCards").value) {
               this.toggleCardRow(cardForm);
            }
            this.calculateSupplyUsed();
         });

      FormHelper.getControl(cardForm, "delete")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => this.calculateSupplyUsed());

      FormHelper.getControl(cardForm, "powerRating")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.calculateSupplyUsed();
            if (!card) {
               throw new Error("Cant update crusade points - unknown card");
            }
            this.updateCardCrusadePoints(card.uniqueIdentifier);
         });

      FormHelper.getControl(cardForm, "crusadePoints").disable();

      FormHelper.getControl(cardForm, "canGainExperience")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.calculateXP(cardForm);
         });

      FormHelper.getControl(cardForm, "battleTally")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.calculateXP(cardForm);
         });
      FormHelper.getControl(cardForm, "killTally")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.calculateXP(cardForm);
         });
      FormHelper.getControl(cardForm, "markedForGreatnessTally")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.calculateXP(cardForm);
         });

      this.cardsFormArray.push(cardForm);

      for (let i = 0; i < card.honours.length; i++) {
         this.wireupHonourEvents(cardForm, FormHelper.getControl(cardForm, "honours", i.toString()) as FormGroup);
      }
   }

   toggleCardRow(cardRowForm: AbstractControl | null) {
      if (!cardRowForm) {
         console.log("Can't toggle null row");
         return;
      }
      const rowExpanded = FormHelper.getControl(cardRowForm, "rowExpanded");
      if (rowExpanded.value === this.expandMoreIcon) {
         rowExpanded.setValue(this.expandLessIcon);
      } else {
         rowExpanded.setValue(this.expandMoreIcon);
      }
   }

   moveUp(index: number) {
      this.swapCards(index.toString(), (index - 1).toString());
   }

   moveDown(index: number) {
      this.swapCards(index.toString(), (index + 1).toString());
   }

   addRequisition(requisition?: CrusadeRequisitionModel) {
      let requisitionForm: FormGroup;
      if (requisition) {
         requisitionForm = FormHelper.buildForm(requisition);
      } else {
         requisition = new CrusadeRequisitionModel();
         requisitionForm = FormHelper.buildForm(requisition);
      }

      const powerRatingControl = FormHelper.getControl(requisitionForm, "type");
      powerRatingControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
         const type = +value as CrusadeRequisitionTypes;
         this.toggleRequisitionControls(requisitionForm, type);
      });

      this.toggleRequisitionControls(requisitionForm, requisition?.type);

      const affectedCardControl = FormHelper.getControl(requisitionForm, "affectedCardIdentifier");

      affectedCardControl.valueChanges
         .pipe(pairwise(), takeUntil(this.unsubscribe))
         .subscribe(([previousIdentifer, currentIdentifier]) => {
            if (previousIdentifer) {
               this.updateCardCrusadePoints(previousIdentifer);
            }
            if (currentIdentifier) {
               this.updateCardCrusadePoints(currentIdentifier);
            }
         });

      FormHelper.getControl(requisitionForm, "affectedCardCrusadePoints")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.updateCardCrusadePoints(affectedCardControl.value);
         });

      FormHelper.getControl(requisitionForm, "delete")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.updateCardCrusadePoints(affectedCardControl.value);
         });

      this.requisitionsFormArray.push(requisitionForm);
   }

   addHonour(cardForm: AbstractControl | null, honour?: CrusadeHonourModel) {
      if (!cardForm) {
         throw new Error("Can't add honour - bad card form.");
      }

      let honourForm: FormGroup;
      if (honour) {
         honourForm = FormHelper.buildForm(honour);
      } else {
         honour = new CrusadeHonourModel();
         honourForm = FormHelper.buildForm(honour);
      }
      this.wireupHonourEvents(cardForm, honourForm);

      (FormHelper.getControl(cardForm, "honours") as FormArray).push(honourForm);
   }

   private wireupHonourEvents(cardForm: AbstractControl, honourForm: FormGroup) {
      const identifier = FormHelper.getControl(cardForm, "uniqueIdentifier").value;

      FormHelper.getControl(honourForm, "crusadeHonourSourceID")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe((value) => {
            const honourSourceControl = FormHelper.getControl(honourForm, "crusadeHonourSourceIdentifier");
            if (+value === CrusadeHonourSources.Battle) {
               honourSourceControl.setValue("");
               honourSourceControl.disable();
            } else {
               honourSourceControl.enable();
            }
         });

      FormHelper.getControl(honourForm, "crusadeHonourTypeID")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.updateCardCrusadePoints(identifier);
         });

      FormHelper.getControl(honourForm, "crusadePoints").disable();

      FormHelper.getControl(honourForm, "isDeactivated")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.updateCardCrusadePoints(identifier);
         });

      FormHelper.getControl(honourForm, "delete")
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.updateCardCrusadePoints(identifier);
         });
   }

   private updateCardCrusadePoints(affectedCardIdentifier: string | null) {
      if (!affectedCardIdentifier) {
         return;
      }

      const matchingCards = this.cardsFormArray.controls.filter(
         (c) => FormHelper.getControl(c, "uniqueIdentifier").value === affectedCardIdentifier
      );
      if (matchingCards.length !== 1) {
         return;
      }
      const matchingCard = matchingCards[0];

      const matchingRequisitions = this.requisitionsFormArray.controls.filter(
         (c) => FormHelper.getControl(c, "affectedCardIdentifier").value === affectedCardIdentifier
      );

      let crusadePoints = 0;

      for (const requisition of matchingRequisitions) {
         const deleting = FormHelper.getControl(requisition, "delete").value;
         if (deleting) {
            continue;
         }
         crusadePoints += +FormHelper.getControl(requisition, "affectedCardCrusadePoints").value;
      }

      for (const honour of FormHelper.getControlAsArray(matchingCard, "honours").controls) {
         const deleting = FormHelper.getControl(honour, "delete").value;
         if (deleting) {
            continue;
         }
         const isDeactivated = FormHelper.getControl(honour, "isDeactivated").value;
         if (isDeactivated) {
            continue;
         }
         const type = +FormHelper.getControl(honour, "crusadeHonourTypeID").value as CrusadeHonourTypes;
         const powerRating = +FormHelper.getControl(matchingCard, "powerRating").value;
         const pointValue = powerRating >= 11 ? 2 : 1;

         if (type === CrusadeHonourTypes.BattleHonour) {
            FormHelper.getControl(honour, "crusadePoints").setValue(pointValue);
            crusadePoints += pointValue;
         } else if (type === CrusadeHonourTypes.BattleScar) {
            FormHelper.getControl(honour, "crusadePoints").setValue(pointValue * -1);
            crusadePoints -= pointValue;
         } else {
            FormHelper.getControl(honour, "crusadePoints").setValue(0);
         }
      }

      FormHelper.getControl(matchingCard, "crusadePoints").setValue(crusadePoints);
   }

   private toggleRequisitionControls(requisitionForm: FormGroup, type: CrusadeRequisitionTypes) {
      const affectedCardControl = FormHelper.getControl(requisitionForm, "affectedCardIdentifier");
      const affectedCardCrusadePointsControl = FormHelper.getControl(requisitionForm, "affectedCardCrusadePoints");
      if (type === CrusadeRequisitionTypes.AffectsCrusadeCard) {
         affectedCardControl.enable();
         affectedCardCrusadePointsControl.enable();
      } else {
         affectedCardControl.setValue(null);
         affectedCardControl.disable();

         affectedCardCrusadePointsControl.setValue(null);
         affectedCardCrusadePointsControl.disable();
      }
   }

   private swapCards(index1: string, index2: string) {
      if (!this.cardsFormArray.get(index1) || !this.cardsFormArray.get(index2)) {
         return;
      }
      const firstControl = FormHelper.getControl(this.cardsFormArray, index1.toString()) as FormGroup;
      const firstControlValue = firstControl.getRawValue();
      const secondControl = FormHelper.getControl(this.cardsFormArray, index2.toString()) as FormGroup;
      const secondControlValue = secondControl.getRawValue();

      firstControl.setValue(secondControlValue);
      secondControl.setValue(firstControlValue);
   }

   private wireupValidators() {
      const battleTallyControl = FormHelper.getControl(this.formGroup, "battleTally");
      battleTallyControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.calculateRequisitionPoints(this.requisitionsFormArray);
      });

      const battlesWonControl = FormHelper.getControl(this.formGroup, "battlesWon");
      battlesWonControl.disable();

      const requisitionPointControl = FormHelper.getControl(this.formGroup, "requisitionPoints");
      requisitionPointControl.disable();
      const requisitionPointUsedControl = FormHelper.getControl(this.formGroup, "requisitionPointsUsed");
      requisitionPointUsedControl.disable();

      const supplyUsedControl = FormHelper.getControl(this.formGroup, "supplyUsed");
      supplyUsedControl.disable();
      const supplyLimitControl = FormHelper.getControl(this.formGroup, "supplyLimit");
      supplyLimitControl.disable();

      const supplyLimitExceededValidator: ValidatorFn = () => {
         const supplyLimitExceeded = +supplyUsedControl.value > +supplyLimitControl.value;
         return supplyLimitExceeded
            ? {
                 lessThan: {
                    supplyUsed: supplyUsedControl.value,
                    message: "Supply Used must be less than Supply Limit",
                 },
              }
            : null;
      };

      this.requisitionsFormArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.calculateRequisitionPoints(this.requisitionsFormArray);
         this.calculateSupply(this.requisitionsFormArray);
      });

      this.formGroup.setValidators(supplyLimitExceededValidator);

      const requisitionPointExceededValidator: ValidatorFn = () => {
         const requisitionPointExceeded = +requisitionPointUsedControl.value > +requisitionPointControl.value;
         return requisitionPointExceeded
            ? {
                 lessThan: {
                    requisitionPointsUsed: requisitionPointUsedControl.value,
                    message: "RP Used must be less than RP",
                 },
              }
            : null;
      };
      this.formGroup.setValidators(requisitionPointExceededValidator);
   }

   private calculateSupplyUsed() {
      let supplyUsed = 0;
      for (const row of this.cardsFormArray.controls) {
         const deactivated = FormHelper.getControl(row, "isDeactivated").value;
         if (deactivated) {
            continue;
         }
         const deleting = FormHelper.getControl(row, "delete").value;
         if (deleting) {
            continue;
         }
         const powerRatingControl = FormHelper.getControl(row, "powerRating");
         supplyUsed += +powerRatingControl.value;
      }
      FormHelper.getControl(this.formGroup, "supplyUsed").setValue(supplyUsed);
   }

   private calculateXP(cardForm: FormGroup) {
      let xp = 0;

      const canGainXPControl = FormHelper.getControl(cardForm, "canGainExperience");
      if (canGainXPControl.value) {
         const battleTally = +FormHelper.getControl(cardForm, "battleTally").value;
         const killTally = +FormHelper.getControl(cardForm, "killTally").value;
         const markedForGreatnessTally = +FormHelper.getControl(cardForm, "markedForGreatnessTally").value;

         xp = battleTally + Math.floor(killTally / 3) + markedForGreatnessTally * 3;
      }

      FormHelper.getControl(cardForm, "experiencePoints").setValue(xp);
   }

   private calculateSupply(requisitionForm: FormArray) {
      let supplyLimit = 50;

      for (const row of requisitionForm.controls) {
         const deleting = FormHelper.getControl(row, "delete").value;
         if (deleting) {
            continue;
         }
         const type = FormHelper.getControl(row, "type").value as CrusadeRequisitionTypes;
         if (type === CrusadeRequisitionTypes.IncreaseSupplyLimit) {
            supplyLimit += 5;
         }
      }

      FormHelper.getControl(this.formGroup, "supplyLimit").setValue(supplyLimit);
   }

   private calculateRequisitionPoints(requisitionForm: FormArray) {
      const requisitionPoints = 5 + +FormHelper.getControl(this.formGroup, "battleTally").value;

      FormHelper.getControl(this.formGroup, "requisitionPoints").setValue(requisitionPoints);

      let requisitionPointsUsed = 0;
      for (const row of requisitionForm.controls) {
         const deactivated = FormHelper.getControl(row, "free").value;
         if (deactivated) {
            continue;
         }
         const deleting = FormHelper.getControl(row, "delete").value;
         if (deleting) {
            continue;
         }
         const cost = +FormHelper.getControl(row, "requisitionPointCost").value;
         requisitionPointsUsed += cost;
      }

      FormHelper.getControl(this.formGroup, "requisitionPointsUsed").setValue(requisitionPointsUsed);
   }
}
