import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { BehaviorSubject, Observable } from "rxjs";
import { CognitoUser } from "src/types/cognito-user";
import { User } from "src/types/user";
import { APIService } from "../API.service";

@Injectable()
export class CurrentUserService {
   currentUser: User | null = null;

   loggingIn: Observable<boolean>;

   userChanged: Observable<User | null>;

   private cognitoUser: CognitoUser | null = null;

   private _loggingIn = new BehaviorSubject<boolean>(false);

   private _userChanged = new BehaviorSubject<User | null>(null);

   constructor(private apiService: APIService) {
      this.userChanged = this._userChanged.asObservable();
      this.loggingIn = this._loggingIn.asObservable();
   }

   loadCurrentUser() {
      Auth.currentAuthenticatedUser()
         .then((data) => {
            this.cognitoUser = data;
            console.log(data);

            this.apiService.ListUsers({ cognitoSub: { eq: this.cognitoUser?.attributes.sub } }).then((event) => {
               const users = event.items;
               if (!users) {
                  throw new Error("Failed to load users");
               }
               if (!this.cognitoUser) {
                  throw new Error("Failed to load cognito user");
               }
               if (users.length === 0) {
                  const user: User = {
                     cognitoSub: this.cognitoUser.attributes.sub,
                     displayName: this.cognitoUser.username,
                  };
                  this.apiService.CreateUser(user).then((result) => {
                     this.setCurrentUser();
                  });
               } else {
                  this.setCurrentUser();
               }
            });
         })
         .catch((err) => console.log(err));
   }

   clearCurrentUser() {
      this.currentUser = null;
      this.cognitoUser = null;
      this._userChanged.next(null);
   }

   updateLoggingIn(loggingIn: boolean){
      this._loggingIn.next(loggingIn);
   }

   private setCurrentUser() {
      this.apiService.ListUsers({ cognitoSub: { eq: this.cognitoUser?.attributes.sub } }).then((event) => {
         const users = event.items;
         if (!users || users.length !== 1) {
            throw new Error("Failed to find correct user");
         }
         this.currentUser = users[0];
         this._userChanged.next(this.currentUser);
      });
   }
}
