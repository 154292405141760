export class CivilizationLeaderModel {
   id: string | null = null;
   name: string = "";
   civilizationName: string = "";
   infoUrl: string | null = null;

   public static sortByName(leader1: CivilizationLeaderModel, leader2: CivilizationLeaderModel) {
      if (leader1.name > leader2.name) {
         return 1;
      }

      if (leader1.name < leader2.name) {
         return -1;
      }

      if (leader1.civilizationName > leader2.civilizationName) {
         return 1;
      }

      if (leader1.civilizationName < leader2.civilizationName) {
         return -1;
      }

      return 0;
   }
}
