<form class="legacy" [class.disabled]="formDisabled" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
   <div class="header-section">
      <div class="form-row">
         <label>Force Name:</label>
         <input type="text" [formControlName]="'name'" />
      </div>
      <div class="form-row">
         <label>Faction:</label>
         <input type="text" [formControlName]="'faction'" />
      </div>
   </div>
   <div class="record-of-battle-section">
      <div class="record-of-battle-item">
         <label>Battles</label>
         <input type="text" [formControlName]="'battleTally'" />
      </div>
      <div class="record-of-battle-item">
         <label>Won</label>
         <input type="text" [formControlName]="'battlesWon'" />
      </div>
      <div class="record-of-battle-item">
         <label>RP</label>
         <input type="text" [formControlName]="'requisitionPoints'" />
      </div>
      <div class="record-of-battle-item">
         <label>Used</label>
         <input type="text" [formControlName]="'requisitionPointsUsed'" />
      </div>
      <div class="record-of-battle-item">
         <label>Supply</label>
         <input type="text" [formControlName]="'supplyLimit'" />
      </div>
      <div class="record-of-battle-item">
         <label>Used</label>
         <input type="text" [formControlName]="'supplyUsed'" />
      </div>
   </div>
   <div class="crusade-cards-section">
      <table>
         <tr>
            <th class="expander-column">
               <input class="layout-control" type="checkbox" title="Show Inactive" [formControlName]="'showInactiveCards'" />
            </th>
            <th class="card-name-column">Crusade Cards</th>
            <th class="unit-type-column">Unit</th>
            <th class="sort-order-column"></th>
            <th class="power-rating-column">PR</th>
            <th class="experience-points-column">XP</th>
            <th class="crusade-points-column">CrP</th>
         </tr>
         <ng-container formArrayName="cards" *ngFor="let group of cardsFormArray.controls; let i = index">
            <ng-container [formGroupName]="i">
               <tr
                  [hidden]="
                     !formHelper.getControl(formGroup, 'showInactiveCards').value &&
                     cardsFormArray.get(i.toString())?.get('isDeactivated')?.value
                  "
               >
                  <td class="expander-column" (click)="toggleCardRow(cardsFormArray?.get(i.toString()))">
                     <i class="material-icons layout-control">
                        {{ cardsFormArray.get(i.toString())?.get("rowExpanded")?.value }}
                     </i>
                  </td>
                  <td class="card-name-column"> <input type="text" [formControlName]="'name'" /></td>
                  <td class="unit-type-column"> <input type="text" [formControlName]="'unitType'" /></td>
                  <td class="sort-order-column">
                     <i class="material-icons" (click)="moveUp(i)">arrow_upward</i>
                     <i class="material-icons" (click)="moveDown(i)">arrow_downward</i>
                  </td>
                  <td class="power-rating-column"> <input type="text" [formControlName]="'powerRating'" /></td>
                  <td class="experience-points-column">
                     <input type="text" [formControlName]="'experiencePoints'" />
                  </td>
                  <td class="crusade-points-column"> <input type="text" [formControlName]="'crusadePoints'" /></td>
               </tr>
               <tr class="expanded-card-section {{ cardsFormArray.get(i.toString())?.get('rowExpanded')?.value }}">
                  <td colspan="7">
                     <div class="expanded-card-wrapper">
                        <div class="top-section">
                           <div class="left-column">
                              <div class="crusade-card-field">
                                 <label>Role:</label>
                                 <input type="text" [formControlName]="'battlefieldRole'" />
                              </div>
                              <div class="crusade-card-field">
                                 <label>Faction:</label>
                                 <input type="text" [formControlName]="'crusadeFaction'" />
                              </div>
                              <div class="crusade-card-field">
                                 <label>Keywords:</label>
                                 <input type="text" [formControlName]="'selectableKeywords'" />
                              </div>
                              <div class="crusade-card-field">
                                 <label>Equipment:</label>
                                 <input type="text" [formControlName]="'equipment'" />
                              </div>
                              <div class="crusade-card-field">
                                 <label>Powers:</label>
                                 <input type="text" [formControlName]="'psychicPowers'" />
                              </div>
                              <div class="crusade-card-field">
                                 <label>Traits:</label>
                                 <input type="text" [formControlName]="'warlordTraits'" />
                              </div>
                              <div class="crusade-card-field">
                                 <label>Relics:</label>
                                 <input type="text" [formControlName]="'relics'" />
                              </div>
                              <div class="crusade-card-field">
                                 <label>Rank:</label>
                                 <select [formControlName]="'rank'">
                                    <option [value]="crusadeCardRanks.Newb"></option>
                                    <option [value]="crusadeCardRanks.Blooded">Blooded</option>
                                    <option [value]="crusadeCardRanks.BattleHardened">Battle-Hardened</option>
                                    <option [value]="crusadeCardRanks.Heroic">Heroic</option>
                                    <option [value]="crusadeCardRanks.Legendary">Legendary</option>
                                 </select>
                              </div>
                           </div>
                           <div class="right-column">
                              <div class="crusade-card-field">
                                 <label>Other Upgrades</label>
                                 <textarea [formControlName]="'otherUpgrades'"> </textarea>
                              </div>

                              <div class="combat-stats">
                                 <div class="crusade-card-field">
                                    <label>Battles</label>
                                    <input type="text" [formControlName]="'battleTally'" />
                                 </div>
                                 <div class="crusade-card-field">
                                    <label>Kills</label>
                                    <input type="text" [formControlName]="'killTally'" />
                                 </div>
                                 <div class="crusade-card-field">
                                    <label>Greatness</label>
                                    <input type="text" [formControlName]="'markedForGreatnessTally'" />
                                 </div>
                              </div>
                              <div class="other-settings">
                                 <div class="crusade-card-field">
                                    <input type="checkbox" [formControlName]="'canGainExperience'" />
                                    <label>XP Gain</label>
                                 </div>
                                 <div class="crusade-card-field">
                                    <input type="checkbox" [formControlName]="'isDeactivated'" />
                                    <label>Deactivate</label>
                                 </div>
                                 <div class="crusade-card-field">
                                    <input type="checkbox" [formControlName]="'delete'" />
                                    <label>Delete</label>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="combat-tallies">
                           <table>
                              <tr>
                                 <th class="description-column">Honour</th>
                                 <th class="type-column">type</th>
                                 <th class="source-column">Source</th>
                                 <th class="crusade-points-column">CrP</th>
                                 <th class="deactivate-column">Deact</th>
                                 <th class="delete-column">Delete</th>
                              </tr>
                              <ng-container
                                 formArrayName="honours"
                                 *ngFor="
                                    let honour of formHelper.getControlAsArray(cardsFormArray, i, 'honours').controls;
                                    let i = index
                                 "
                              >
                                 <ng-container [formGroupName]="i">
                                    <tr>
                                       <td class="description-column">
                                          <input type="text" [formControlName]="'description'" />
                                       </td>
                                       <td class="type-column">
                                          <select [formControlName]="'crusadeHonourTypeID'">
                                             <option [value]="crusadeHonourTypes.BattleHonour"> Battle Honour</option>
                                             <option [value]="crusadeHonourTypes.BattleScar"> Battle Scar</option>
                                             <option [value]="crusadeHonourTypes.Other"> Other</option>
                                          </select>
                                       </td>
                                       <td class="source-column">
                                          <select [formControlName]="'crusadeHonourSourceID'">
                                             <option [value]="crusadeHonourSources.Rank">Rank Up</option>
                                             <option [value]="crusadeHonourSources.Battle">Battle</option>
                                          </select>

                                          <select
                                             *ngIf="
                                                formHelper.getControl(honour, 'crusadeHonourSourceID').value ==
                                                crusadeHonourSources.Rank
                                             "
                                             [formControlName]="'crusadeHonourSourceIdentifier'"
                                          >
                                             <option [value]="crusadeCardRanks.Newb"></option>
                                             <option [value]="crusadeCardRanks.Blooded">Blooded</option>
                                             <option [value]="crusadeCardRanks.BattleHardened">Battle-Hardened</option>
                                             <option [value]="crusadeCardRanks.Heroic">Heroic</option>
                                             <option [value]="crusadeCardRanks.Legendary">Legendary</option>
                                          </select>
                                          <input
                                             *ngIf="
                                                formHelper.getControl(honour, 'crusadeHonourSourceID').value ==
                                                crusadeHonourSources.Battle
                                             "
                                             type="text"
                                             [formControlName]="'crusadeHonourSourceIdentifier'"
                                          />
                                       </td>
                                       <td class="crusade-points-column">
                                          <input type="text" [formControlName]="'crusadePoints'" />
                                       </td>
                                       <td class="deactivate-column">
                                          <input type="checkbox" [formControlName]="'isDeactivated'" />
                                       </td>
                                       <td class="delete-column">
                                          <input type="checkbox" [formControlName]="'delete'" />
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </table>
                           <div class="add-icon" (click)="addHonour(cardsFormArray.get(i.toString()))">
                              <i class="material-icons">playlist_add</i> Add Honour / Scar
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
            </ng-container>
         </ng-container>
      </table>
      <div class="add-icon" (click)="addCard()"> <i class="material-icons">playlist_add</i>Add Card </div>
   </div>

   <div class="crusade-requisitions-section">
      <table>
         <tr>
            <th class="description-column">Requisition</th>
            <th class="type-column">type</th>
            <th class="affected-card-column">Card</th>
            <th class="crusade-points-column">CrP+</th>
            <th class="free-requisition-column">Free</th>
            <th class="delete-requisition-column">Delete</th>
         </tr>
         <ng-container formArrayName="requisitions" *ngFor="let group of requisitionsFormArray.controls; let i = index">
            <ng-container [formGroupName]="i">
               <tr>
                  <td class="description-column">
                     <input type="text" [formControlName]="'description'" />
                  </td>
                  <td class="type-column">
                     <select [formControlName]="'type'">
                        <option [value]="crusadeRequisitionTypes.IncreaseSupplyLimit">Increase Supply Limit</option>
                        <option [value]="crusadeRequisitionTypes.AffectsCrusadeCard">Affects Crusade Card</option>
                     </select>
                  </td>
                  <td class="affected-card-column">
                     <select [formControlName]="'affectedCardIdentifier'">
                        <option disabled selected>-- select a card --</option>
                        <option
                           *ngFor="let card of cardsFormArray.controls; let i = index"
                           [value]="formHelper.getControl(card, 'uniqueIdentifier').value"
                        >
                           {{ formHelper.getControl(card, "name").value }}
                        </option>
                     </select>
                  </td>
                  <td class="crusade-points-column">
                     <input type="text" [formControlName]="'affectedCardCrusadePoints'" />
                  </td>
                  <td class="free-requisition-column">
                     <input type="checkbox" [formControlName]="'free'" />
                  </td>
                  <td class="delete-requisition-column">
                     <input type="checkbox" [formControlName]="'delete'" />
                  </td>
               </tr>
            </ng-container>
         </ng-container>
      </table>
      <div class="add-icon" (click)="addRequisition()"> <i class="material-icons">playlist_add</i>Add Requisition </div>
   </div>

   <div class="error-panel" *ngIf="formGroup.invalid">
      <h1 class="page-header">Errors:</h1>
      <p *ngFor="let error of formHelper.getErrors(formGroup)" class="form-error">{{ error }}</p>
   </div>
   <div>
      <button class="legacy" type="submit" [disabled]="formGroup.invalid || formBusy">
         <span *ngIf="!formBusy">Save</span>
         <i class="material-icons" *ngIf="formBusy">access_time</i>
      </button>
   </div>
</form>
