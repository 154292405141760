import { CreateCivilizationLeaderInput, UpdateCivilizationLeaderInput } from "src/app/API.service";
import { CivilizationLeader } from "src/types/civilization-drafter/civilization-leader";
import { CivilizationLeaderModel } from "../../models/civilization-leader.model";

export class CivilizationLeaderAdminModel extends CivilizationLeaderModel {
   delete: boolean = false;

   static FromQueryModel(leader: CivilizationLeader): any {
      const result = new CivilizationLeaderAdminModel();

      result.id = leader.id;
      result.name = leader.name;
      result.civilizationName = leader.civilizationName;
      result.infoUrl = leader.infoUrl;

      return result;
   }

   static toCreateModel(leader: CivilizationLeaderAdminModel): CreateCivilizationLeaderInput {
      const result: CreateCivilizationLeaderInput = {
         id: leader.id,
         name: leader.name,
         civilizationName: leader.civilizationName,
         infoUrl: leader.infoUrl,
      };
      return result;
   }

   static toUpdateModel(leader: CivilizationLeaderAdminModel): UpdateCivilizationLeaderInput {
      if (!leader.id) {
         throw new Error("Cannot save Leader  - unknown id");
      }
      const result: UpdateCivilizationLeaderInput = {
         id: leader.id,
         name: leader.name,
         civilizationName: leader.civilizationName,
         infoUrl: leader.infoUrl,
      };
      return result;
   }
}
