import { CrusadeBattleRecordSteps } from "src/app/crusade/models/battle-record/crusade-battle-record-steps.enum";
import { CrusadeBattleSizes } from "src/app/crusade/models/battle-record/crusade-battle-sizes.enum";
import { CrusadeBattleRecord } from "src/types/crusade-battle-record";

export class CrusadeBattleRecordListModel {
   id: string = "";
   description: string | null = null;
   gameSizeID: CrusadeBattleSizes = CrusadeBattleSizes.CombatPatrol;
   step: CrusadeBattleRecordSteps = CrusadeBattleRecordSteps.ChallengeIssued;
   mission: string | null = null;
   twitchUrl: string | null = null;

   participants: string | null = null;

   static fromQueryModel(item: CrusadeBattleRecord) {
      const result = new CrusadeBattleRecordListModel();
      if (!item) {
         throw new Error("Cannot load data - unable to load ID for Order of Battle");
      }
      if (!item.id) {
         throw new Error("Cannot load data - unable to load ID for Order of Battle");
      }
      result.id = item.id;
      result.description = item.description;
      result.gameSizeID = item.gameSizeID;
      result.step = item.step;
      result.mission = item.mission;
      result.twitchUrl = item.twitchUrl;

      if (item.participants && item.participants.items) {
         const names = new Array<string>();

         for (const participant of item.participants.items) {
            if (participant?.user?.displayName) {
               names.push(participant?.user?.displayName);
            }
         }

         result.participants = names.join(", ");
      }
      return result;
   }
}
