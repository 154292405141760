import { DraftedLeader } from "src/types/civilization-drafter/drafted-leader";

export class DraftedLeaderModel {
   id: string | null = null;
   participantID: string;
   leaderID: string;
   selected: boolean = false;

   name: string = "Mystery!";
   infoUrl: string | null = null;

   constructor(participantID: string, leaderID: string) {
      this.participantID = participantID;
      this.leaderID = leaderID;
   }

   static fromQueryModel(item: DraftedLeader): DraftedLeaderModel {
      const result = new DraftedLeaderModel(item.civilizationDraftParticipantID, item.draftedLeaderID);

      result.id = item.id;
      result.selected = item.selected ?? false;

      return result;
   }
}
