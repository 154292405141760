<h1 class="page-header">Orders of Battle</h1>
<div *ngIf="ordersOfBattle?.length < 1">No Orders of Battle on record</div>

<table>
   <tr>
      <th>Name</th>
      <th>Faction</th>
      <th>Owner</th>
      <th class="view-column"> </th>
   </tr>

   <tr *ngFor="let oob of ordersOfBattle" class="body-row">
      <td>{{ oob.name }}</td>
      <td>{{ oob.faction }}</td>
      <td>{{ oob.userDisplayName }}</td>
      <td>
         <a [routerLink]="['/crusade/orders-of-battle/' + oob.id]" class="button-link legacy">
            <i class="material-icons"> arrow_forward </i> view
         </a>
      </td>
   </tr>
</table>
<a [routerLink]="['add']" class="button-link legacy">Create Order Of Battle</a>
