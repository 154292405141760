import { Component, OnInit } from "@angular/core";
import { Auth } from "aws-amplify";
import { APIService } from "src/app/API.service";
import { CognitoUser } from "src/types/cognito-user";
import { User } from "src/types/user";

@Component({
   selector: "fun-user-list",
   templateUrl: "./user-list.component.html",
   styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
   users: Array<User | null> = new Array<User | null>();

   currentUser: CognitoUser | null = null;

   currentUsername: string | null = null;

   constructor(private apiService: APIService) {
      Auth.currentAuthenticatedUser()
         .then((data) => {
            const user = data as CognitoUser;
            this.currentUser = data;
            this.currentUsername = data.username;
            console.log(data);
         })
         .catch((err) => console.log(err));
   }

   ngOnInit() {
      this.apiService.ListUsers().then((event) => {
         if (!event.items) {
            throw new Error("Failed to load users");
         }
         this.users = event.items;
         if (this.currentUser) {
            if (this.users.filter((user) => user?.cognitoSub === this.currentUser?.attributes.sub).length === 0) {
               const user: User = {
                  cognitoSub: this.currentUser.attributes.sub,
                  displayName: this.currentUser.username,
               };
               this.apiService.CreateUser(user).then((result) => {});
            }
         }
      });
   }
}
