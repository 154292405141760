<h1 class="page-header">Draft Details</h1>
<fun-draft-details-form
   [formBusy]="formBusy"
   [formDisabled]="formDisabled"
   [draft]="draft"
   [currentUser]="currentUser | async"
   [inviteLink]="inviteLink"
   [joinDraftCode]="joinCodeFromUrl"
   [maps]="maps"
   [leaders]="leaders"
   (formSubmitted)="onFormSubmitted($event)"
   (joinDraftClicked)="onJoinDraftClicked($event)"
   (participationFormSubmitted)="onParticipationFormSubmitted($event)"
   (draftMapsClicked)="onDraftMapsClicked()"
   (draftLeadersClicked)="onDraftLeadersClicked()"
></fun-draft-details-form>
<br />
<a mat-raised-button color="primary" [routerLink]="['/civ-drafter/']">
   <i class="material-icons"> keyboard_backspace </i>
   back to list
</a>