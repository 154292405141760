import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FundamegapocalypseComponent } from "./fundamegapocalypse/fundamegapocalypse.component";
import { FundamegapocalypseComponent_1_0_0 } from "./fundamegapocalypse_1_0_0/fundamegapocalypse_1_0_0.component";
import { FundapocalypseComponent } from "./fundapocalypse.component";

@NgModule({
   imports: [CommonModule, RouterModule],
   declarations: [FundapocalypseComponent, FundamegapocalypseComponent, FundamegapocalypseComponent_1_0_0],
})
export class FundapocalypseModule {}
