import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticationRequiredGuard } from "./authentication/authentication-required.guard";
import { LoginComponent } from "./authentication/login/login.component";
import { AddDraftComponent } from "./civ-drafter/add-draft/add-draft.component";
import { DraftAdminComponent } from "./civ-drafter/draft-admin/draft-admin.component";
import { DraftDetailsComponent } from "./civ-drafter/draft-details/draft-details.component";
import { DraftListComponent } from "./civ-drafter/draft-list/draft-list.component";
import { AddCrusadeOrderOfBattleComponent } from "./crusade/add-crusade-order-of-battle/add-crusade-order-of-battle.component";
import { AddBattleRecordComponent } from "./crusade/battle-record/add-battle-record/add-battle-record.component";
import { CrusadeBattleRecordListComponent } from "./crusade/battle-record/crusade-battle-list/crusade-battle-record-list.component";
import { CrusadeBattleComponent } from "./crusade/crusade-battle/crusade-battle.component";
import { CrusadeOrderOfBattleListComponent } from "./crusade/crusade-order-of-battle-list/crusade-order-of-battle-list.component";
import { CrusadeOrderOfBattleComponent } from "./crusade/crusade-order-of-battle/crusade-order-of-battle.component";
import { FundamegapocalypseComponent } from "./fundapocalypse/fundamegapocalypse/fundamegapocalypse.component";
import { FundamegapocalypseComponent_1_0_0 } from "./fundapocalypse/fundamegapocalypse_1_0_0/fundamegapocalypse_1_0_0.component";
import { FundapocalypseComponent } from "./fundapocalypse/fundapocalypse.component";
import { HomeComponent } from "./home/home.component";
import { AddUserComponent } from "./users/add-user/add-user.component";
import { UserListComponent } from "./users/user-list/user-list.component";
import { ViewUserComponent } from "./users/view-user/view-user.component";

const routes: Routes = [
   {
      path: "crusade",
      canActivate: [AuthenticationRequiredGuard],
      children: [
         { path: "", redirectTo: "orders-of-battle", pathMatch: "full" },
         {
            path: "orders-of-battle",
            component: CrusadeOrderOfBattleListComponent,
            data: { title: "Orders Of Battle" },
         },
         {
            path: "orders-of-battle/add",
            component: AddCrusadeOrderOfBattleComponent,
            data: { title: "Add Order Of Battle" },
         },
         { path: "orders-of-battle/:id", redirectTo: "orders-of-battle/:id/details", pathMatch: "full" },
         {
            path: "orders-of-battle/:id/details",
            component: CrusadeOrderOfBattleComponent,
            data: { title: "Order Of Battle Details" },
         },

         {
            path: "battle-records",
            component: CrusadeBattleRecordListComponent,

            data: { title: "Orders Of Battle" },
         },
         {
            path: "battle-records/details",
            component: CrusadeBattleComponent,
            data: { title: "TODO List" },
         },
         {
            path: "battle-records/add",
            component: AddBattleRecordComponent,
            data: { title: "Add Battle Record" },
         },
         { path: "battle-records/:id", redirectTo: "battle-records/:id/details", pathMatch: "full" },
         {
            path: "battle-records/:id/details",
            component: CrusadeBattleComponent,
            data: { title: "Order Of Battle Details" },
         },
      ],
   },
   {
      path: "users",
      canActivate: [AuthenticationRequiredGuard],
      children: [
         { path: "", redirectTo: "list", pathMatch: "full" },
         {
            path: "list",
            component: UserListComponent,
            data: { title: "Users" },
         },
         { path: "add", component: AddUserComponent, data: { title: "Add User" } },
         { path: ":id", redirectTo: ":id/view", pathMatch: "full" },
         { path: ":id/view", component: ViewUserComponent, data: { title: "View User" } },
      ],
   },
   {
      path: "civ-drafter",
      canActivate: [AuthenticationRequiredGuard],
      children: [
         { path: "", component: DraftListComponent, pathMatch: "full" },
         { path: "add", component: AddDraftComponent, data: { title: "Add Draft" } },
         { path: "admin", component: DraftAdminComponent, data: { title: "Draft Admin" } },
         { path: ":id", redirectTo: ":id/details", pathMatch: "full" },
         { path: ":id/details", component: DraftDetailsComponent, data: { title: "Draft Details" } },
      ],
   },
   {
      path: "fundapocalypse",
      children: [
         { path: "", component: FundapocalypseComponent, data: { title: "Fundapocalypse" }, pathMatch: "full" },
         { path: "FUNDAMEGAPOCALYPSE", component: FundamegapocalypseComponent, data: { title: "FUNDAMEGAPOCALYPSE" } },
         {
            path: "FUNDAMEGAPOCALYPSE_1_0_0",
            component: FundamegapocalypseComponent_1_0_0,
            data: { title: "FUNDAMEGAPOCALYPSE 1.0.0" },
         },
         { path: "admin", component: DraftAdminComponent, data: { title: "Draft Admin" } },
         { path: ":id", redirectTo: ":id/details", pathMatch: "full" },
         { path: ":id/details", component: DraftDetailsComponent, data: { title: "Draft Details" } },
      ],
   },
   {
      path: "login",
      component: LoginComponent,
      data: { title: "Login" },
   },
   {
      path: "",
      component: HomeComponent,
      data: { title: "Home" },
   },
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
