import { CreateCrusadeBattleRecordInput } from "src/app/API.service";
import { CrusadeBattleRecordParticipantAgendaModel } from "./crusade-battle-record-participant-agenda.model";
import { CrusadeBattleRecordParticipantModel } from "./crusade-battle-record-participant.model";
import { CrusadeBattleRecordSteps } from "./crusade-battle-record-steps.enum";
import { CrusadeBattleSizes } from "./crusade-battle-sizes.enum";

export class CrusadeBattleRecordModel {
   id: string | null = null;
   description: string | null = null;
   gameSizeID: CrusadeBattleSizes = CrusadeBattleSizes.CombatPatrol;
   step: CrusadeBattleRecordSteps = CrusadeBattleRecordSteps.ChallengeIssued;
   mission: string | null = null;

   twitchUrl: string | null = null;

   participants: Array<CrusadeBattleRecordParticipantModel> = new Array<CrusadeBattleRecordParticipantModel>();

   agendas: Array<CrusadeBattleRecordParticipantAgendaModel> = new Array<CrusadeBattleRecordParticipantAgendaModel>();

   static toCreateModel(item: CrusadeBattleRecordModel): CreateCrusadeBattleRecordInput {
      const result: CreateCrusadeBattleRecordInput = {
         id: item.id,
         gameSizeID: item.gameSizeID,
         step: item.step,
         mission: item.mission,
         twitchUrl: item.twitchUrl,
      };
      return result;
   }
}
