import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";

@Component({
  selector: "fun-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy  {
  liveStatusMessage = "Live Now!";
  offlineStatusMessage = "Offline";


  normanthesquidIsLive = false;
  theEdcaIsLive = false;
  Heinzz07IsLive = false;

  private unsubscribe = new Subject<void>();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
     this.unsubscribe.next();
  }

}
