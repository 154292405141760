import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule } from "@angular/router";
import { AddDraftComponent } from "./add-draft/add-draft.component";
import { DraftAdminComponent } from "./draft-admin/draft-admin.component";
import { DraftDetailsFormComponent } from "./draft-details-form/draft-details-form.component";
import { DraftDetailsComponent } from "./draft-details/draft-details.component";
import { DraftFormComponent } from "./draft-form/draft-form.component";
import { DraftListComponent } from "./draft-list/draft-list.component";

@NgModule({
   imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      ClipboardModule,
      MatTabsModule,
      MatCheckboxModule,
   ],
   declarations: [
      DraftAdminComponent,
      DraftFormComponent,
      AddDraftComponent,
      DraftAdminComponent,
      DraftDetailsComponent,
      DraftDetailsFormComponent,
      DraftListComponent,
   ],
})
export class CivDrafterModule {}
