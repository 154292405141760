<form class="legacy"  [class.disabled]="formDisabled" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
   <div class="civilization-leader-section">
      <table>
         <tr>
            <th class="name-column">Leader Name</th>
            <th class="civilization-column">Civilization</th>
            <th class="info-url-column">Url</th>
            <th class="delete-column">Delete</th>
         </tr>
         <ng-container formArrayName="leaders" *ngFor="let group of leadersFormArray.controls; let i = index">
            <ng-container [formGroupName]="i">
               <tr>
                  <td class="name-column">
                     <input type="text" [formControlName]="'name'" />
                  </td>
                  <td class="civilization-column">
                     <input type="text" [formControlName]="'civilizationName'" />
                  </td>
                  <td class="info-url-column">
                     <input type="text" [formControlName]="'infoUrl'" />
                  </td>
                  <td class="delete-column">
                     <input type="checkbox" [formControlName]="'delete'" />
                  </td>
               </tr>
            </ng-container>
         </ng-container>
      </table>
      <div class="add-icon" (click)="addLeader()"> <i class="material-icons">playlist_add</i>Add Leader</div>
   </div>
   <div class="civilization-map-section">
      <table>
         <tr>
            <th class="name-column">Map Name</th>
            <th class="min-players-column">Min Players</th>
            <th class="max-players-column">Max Players</th>
            <th class="delete-column">Delete</th>
         </tr>
         <ng-container formArrayName="maps" *ngFor="let group of mapsFormArray.controls; let i = index">
            <ng-container [formGroupName]="i">
               <tr>
                  <td class="name-column">
                     <input type="text" [formControlName]="'name'" />
                  </td>
                  <td class="min-players-column">
                     <input type="text" [formControlName]="'minPlayers'" />
                  </td>
                  <td class="max-players-column">
                     <input type="text" [formControlName]="'maxPlayers'" />
                  </td>
                  <td class="delete-column">
                     <input type="checkbox" [formControlName]="'delete'" />
                  </td>
               </tr>
            </ng-container>
         </ng-container>
      </table>
      <div class="add-icon" (click)="addMap()"> <i class="material-icons">playlist_add</i>Add Map </div>
   </div>
   <div class="error-panel" *ngIf="formGroup.invalid">
      <h1 class="page-header">Errors:</h1>
      <p *ngFor="let error of formHelper.getErrors(formGroup)" class="form-error">{{ error }}</p>
   </div>
   <div>
      <button type="submit" [disabled]="formGroup.invalid || formBusy">
         <span *ngIf="!formBusy">Save</span>
         <i class="material-icons" *ngIf="formBusy">access_time</i>
      </button>
   </div>
</form>
