import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
   APIService,
   DeleteCrusadeCardMutation,
   DeleteCrusadeHonourMutation,
   DeleteCrusadeOrderOfBattleMutation,
   DeleteCrusadeRequisitionMutation
} from "src/app/API.service";
import { CurrentUserService } from "src/app/authentication/current-user.service";
import { OrderOfBattleModel } from "../models/order-of-battle.model";
import { OrderOfBattleSaveService } from "../services/order-of-battle-save.service";

@Component({
   selector: "fun-crusade-order-of-battle",
   templateUrl: "crusade-order-of-battle.component.html",
   styleUrls: ["./crusade-order-of-battle.component.scss"],
   providers: [OrderOfBattleSaveService],
})
export class CrusadeOrderOfBattleComponent implements OnInit, OnDestroy {
   orderOfBattle: OrderOfBattleModel | null = null;

   formBusy = false;

   formDisabled = true;

   confirmDelete = false;

   private id: string | null = null;

   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: APIService,
      private activatedRoute: ActivatedRoute,
      private currentUserService: CurrentUserService,
      private router: Router,
      private orderOfBattleSaveService: OrderOfBattleSaveService
   ) {}

   ngOnInit() {
      this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
         this.id = params["id"];
         if (!this.id) {
            throw new Error("Unable to load order of battle details - bad id");
         }
         this.load(this.id);
      });

      this.currentUserService.userChanged.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.checkAccess();
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onFormSubmitted(orderOfBattle: OrderOfBattleModel) {
      this.formBusy = true;
      if (!this.currentUserService.currentUser || !this.currentUserService.currentUser.id) {
         alert("Unable to save Order Of Battle. Unable to determine logged in user.");
         return;
      }

      if (this.currentUserService.currentUser.id !== orderOfBattle.userId) {
         throw new Error("Editing someone else's order of battle is not allowed");
      }

      orderOfBattle.userId = this.currentUserService.currentUser.id;
      this.orderOfBattleSaveService
         .updateOrderOfBattle(orderOfBattle)
         .then((event) => {
            if (!event.id) {
               throw new Error("Error occurred while saving");
            }
            this.load(event.id);
            alert("Saved Successfully");
         })
         .catch((e) => {
            console.log("error creating Order Of Battle Card...", e);
            alert(`Error creating Order Of Battle: \r\n${e}`);
         });
   }

   delete() {
      if (!this.id) {
         return;
      }
      if (!this.currentUserService.currentUser || !this.currentUserService.currentUser.id) {
         alert("Unable to delete Order Of Battle. Unable to determine logged in user.");
         return;
      }

      if (this.currentUserService.currentUser.id !== this.orderOfBattle?.userId) {
         throw new Error("Deleting someone else's order of battle is not allowed");
      }
      if (!this.confirmDelete) {
         setTimeout(() => {
            this.confirmDelete = true;
         }, 100);
      } else {
         const deletionPromises = new Array<
            Promise<
               | DeleteCrusadeOrderOfBattleMutation
               | DeleteCrusadeCardMutation
               | DeleteCrusadeRequisitionMutation
               | DeleteCrusadeHonourMutation
            >
         >();
         deletionPromises.push(this.apiService.DeleteCrusadeOrderOfBattle({ id: this.id }));
         if (this.orderOfBattle?.cards) {
            for (const card of this.orderOfBattle.cards) {
               if (card.id) {
                  deletionPromises.push(this.apiService.DeleteCrusadeCard({ id: card.id }));
               }
               for (const honour of card.honours) {
                  if (honour.id) {
                     deletionPromises.push(this.apiService.DeleteCrusadeHonour({ id: honour.id }));
                  }
               }
            }
         }
         if (this.orderOfBattle?.requisitions) {
            for (const requisition of this.orderOfBattle.requisitions) {
               if (requisition.id) {
                  deletionPromises.push(this.apiService.DeleteCrusadeRequisition({ id: requisition.id }));
               }
            }
         }
         Promise.all(deletionPromises)
            .then(() => {
               alert("Deleted Successfully");
               this.router.navigate([`crusade/orders-of-battle`]);
            })
            .catch((err) => {
               alert("An error occurred and deletion failed.");
               console.log(err);
            });
      }
   }

   private load(id: string) {
      const getOrderOfBattle = this.apiService.GetCrusadeOrderOfBattle(id);

      const getCards = this.apiService.ListCrusadeCards({ orderOfBattleID: { eq: id } });

      const getRequisitions = this.apiService.ListCrusadeRequisitions({ orderOfBattleID: { eq: id } });

      const getHonours = this.apiService.ListCrusadeHonours({ orderOfBattleID: { eq: id } });

      Promise.all([getOrderOfBattle, getCards, getRequisitions, getHonours])
         .then(([orderOfBattleResult, cardResult, requisitionResult, honoursResult]) => {
            const orderOfBattle = OrderOfBattleModel.fromQueryModel(
               orderOfBattleResult,
               cardResult.items,
               requisitionResult.items,
               honoursResult.items
            );

            this.orderOfBattle = orderOfBattle;
            this.formBusy = false;

            this.checkAccess();
         })
         .catch((err) => console.log(err));
   }

   private checkAccess() {
      if (!this.orderOfBattle || this.orderOfBattle.userId !== this.currentUserService.currentUser?.id) {
         this.formDisabled = true;
      } else {
         this.formDisabled = false;
      }
   }
}
