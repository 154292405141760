import { Injectable } from "@angular/core";
import { API, graphqlOperation } from "aws-amplify";
import {
   ListCivilizationDraftsQuery,
   ListCrusadeBattleRecordsQuery,
   ListCrusadeOrderOfBattlesQuery,
   ModelCivilizationDraftFilterInput,
   ModelCrusadeBattleRecordFilterInput,
   ModelCrusadeOrderOfBattleFilterInput
} from "./app/API.service";

@Injectable({
   providedIn: "root",
})
export class FundamalleusAPIService {
   async ListOrdersOfBattleForTable(
      filter?: ModelCrusadeOrderOfBattleFilterInput,
      limit?: number,
      nextToken?: string
   ): Promise<ListCrusadeOrderOfBattlesQuery> {
      const statement = `query ListOrdersOfBattleForTable($filter: ModelCrusadeOrderOfBattleFilterInput, $limit: Int, $nextToken: String) {
        listCrusadeOrderOfBattles(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              user {
                items {
                  id
                  displayName
                }
              }
              faction
              name
              id
            }
          }
        }`;
      const gqlAPIServiceArguments: any = {};
      if (filter) {
         gqlAPIServiceArguments.filter = filter;
      }
      if (limit) {
         gqlAPIServiceArguments.limit = limit;
      }
      if (nextToken) {
         gqlAPIServiceArguments.nextToken = nextToken;
      }
      const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
      return response.data.listCrusadeOrderOfBattles as ListCrusadeOrderOfBattlesQuery;
   }

   async ListBattleRecordsForTable(
      filter?: ModelCrusadeBattleRecordFilterInput,
      limit?: number,
      nextToken?: string
   ): Promise<ListCrusadeBattleRecordsQuery> {
      const statement = `query ListBattleRecordsForTable {
         listCrusadeBattleRecords {
           items {
             participants {
               items {
                 id
                 user {
                   displayName
                 }
               }
             }
             id
             description
             gameSizeID
             step
             mission
             twitchUrl
           }
         }
       }`;
      const gqlAPIServiceArguments: any = {};
      if (filter) {
         gqlAPIServiceArguments.filter = filter;
      }
      if (limit) {
         gqlAPIServiceArguments.limit = limit;
      }
      if (nextToken) {
         gqlAPIServiceArguments.nextToken = nextToken;
      }
      const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
      return response.data.listCrusadeBattleRecords as ListCrusadeBattleRecordsQuery;
   }

   async ListDraftsForTable(
      filter?: ModelCivilizationDraftFilterInput,
      limit?: number,
      nextToken?: string
   ): Promise<ListCivilizationDraftsQuery> {
      const statement = `query ListCivilizationDraftsForTable($filter: ModelCivilizationDraftFilterInput, $limit: Int, $nextToken: String) {
         listCivilizationDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              date
              owner {
                id
                displayName
              }
              ownerID
            }
          }
        }`;
      const gqlAPIServiceArguments: any = {};
      if (filter) {
         gqlAPIServiceArguments.filter = filter;
      }
      if (limit) {
         gqlAPIServiceArguments.limit = limit;
      }
      if (nextToken) {
         gqlAPIServiceArguments.nextToken = nextToken;
      }
      const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
      return response.data.listCivilizationDrafts as ListCivilizationDraftsQuery;
   }
}
