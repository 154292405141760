<h1 class="page-header">
   FUNDAMEGAPOCALYPSE
</h1>
<h2 class="page-header">
   Revision 1.0.0
</h2>
<p>
   <a [routerLink]="['/fundapocalypse/FUNDAMEGAPOCALYPSE/']">Newest Revision</a>   
</p>
<p>
   The FUNDAMEGAPOCALYPSE rules are designed to further optimize and streamline
   <a [routerLink]="['/fundapocalypse/']">Fundapocalypse</a> games for large numbers of players, roughly 10 or more.
</p>
<p>
   These rules are in progress, and may change at any time.
</p>
<h2 class="page-header">
   1. Game Size - Two teams maximum
</h2>

<div class="rules-section">
   <p>
      Reasoning: The game really bogs down when you have more than 2 people on each of 4 teams activating detachments -
      the game will only move as quickly as the slowest person on each team.
   </p>
</div>
<h2 class="page-header">
   2. Command Asset Arrays - Replaces Command Asset Decks
</h2>
<div class="rules-section">
   <p>
      Reasoning: Deckbuilding, tutoring, and drawing really slow the game down, especially when everyone min-maxes their
      drawing capability. Instead of a Command Asset Deck and Hand, these rules have players build 9 card Command Asset
      Arrays, which they activate using generated command points.
   </p>
   <p class="rules-replacement-directions">
      Replace the contents of the “Command Assets” section on page 25 with the following:
   </p>
   <div class="rules-replacement-section">
      <p>
         Command assets represent a wide variety of tactical ploys, strategic gambits, psychic powers and more, which
         you can use to affect the course of the battle. When mustering an army, each player must construct a Command
         Asset Array, and spends Command Points to play the Command Asset Cards in their Array.
      </p>
      <p>
         Each player will construct a Command Asset Array consisting of 9 Command Asset Cards, split between 6 Core
         Assets and 3 Tactical Assets:
      </p>
      <ul>
         <li>
            Core
            <ol>
               <li>
                  Insane Bravery - Autopass Morale
               </li>
               <li>
                  Miraculous Escape - Remove a blast marker from a unit
               </li>
               <li>
                  Divine Intervention - Change a dice result to one of your choosing
               </li>
               <li>
                  Tactical Flexibility - Change order for a detachment
               </li>
               <li>
                  Trophy Kill - Choose Super-Heavy or Warmaster - reroll all hits against that unit
               </li>
               <li>
                  Strategic Reallocation - Share CP
               </li>
            </ol>
         </li>
         <li>
            Tactical
            <ol>
               <li>
                  Behind Enemy Lines - Remove an Out of Command marker
               </li>
               <li>
                  Demolitions - Blow up terrain
               </li>
               <li>
                  Go to Ground - Double saves for Infantry units
               </li>
            </ol>
         </li>
      </ul>
      <p>
         Any or all Tactical Asset cards may be replaced by other Command Asset Cards, with the exception of Banned
         Command Asset Cards. For new players, or exceptionally big games, using the standard set may be preferable.
      </p>
      <p>
         Each player maintains a separate record of their current Command Point total, which should always be available
         to your opponent. In team games, Command Point totals are kept separate per player.
      </p>
      <p>
         Each Command Asset card details when it can be played and its effects. Unless specified otherwise, Command
         Assets cannot be played during an action or while another Command Asset is being resolved. Some Command Asset
         Cards have two different effects; When you play such a Command Asset card, choose which of the effects to
         resolve.
      </p>
      <p>
         To play a Command Asset Card you must pay 1 command point. After you have played a Command Asset card and
         resolved its effects, it is returned the the array. If it was previously hidden, it should now remain revealed
         for the remainder of the game.
      </p>
      <p>
         If a player uses the same Command Asset card more than once in any phase, you must pay an additional Command
         Point for each additional instance. For example, if you use the same Command Asset Card three times in the
         Action Phase, you would need to pay a total of 6 Command Points - 1 for the first instance, 2 for the second,
         and 3 for the third. Using a dice or counters to keep track of how many times a player has played a Command
         Asset card during the current phase may be helpful - just remember to reset the indicators at the end of each
         phase
      </p>
   </div>
   <p class="rules-replacement-directions">
      Remove the last sentence of the “Warlords” section on page 26.
   </p>
   <p class="rules-replacement-directions">
      Replace the contents of the “Preparing Command Assets” section on page 26 with the following:
   </p>
   <div class="rules-replacement-section">
      <p>
         Each player should place their Command Asset Array beside the battlefield. The Core Assets should be face-up,
         though the players may choose to keep any Tactical Assets face-down until the first time they use them.
      </p>
      <p>
         Then, each player generates their starting command points. Each player 1 command point for every 50 PL in their
         army.
      </p>
   </div>
   <p class="rules-replacement-directions">
      Make the following change to the “Orders Phase” section on page 27:
   </p>
   <ul class="rules-replacement-section">
      <li>
         Old: then they generate their Command Assets
      </li>
      <li>
         New: then they generate Command Points
      </li>
   </ul>
   <p class="rules-replacement-directions">
      Replace “C) Generate Command Assets” on page 28 with the following:
   </p>
   <ul class="rules-replacement-section">
      <p>
         In this step, each player generates 1 command point.
      </p>
      <p>
         In team games with an uneven number of players, the team with fewer players generates additional command points
         equal to the difference in the number of players. For instance, in a 5 vs 3 game, the team with 3 players would
         generate an additional 2 command points. Any command points generated in this way should be immediately
         allocated to one of the players on that team.
      </p>
   </ul>
</div>
<h2 class="page-header">
   3. New Command Asset Cards
</h2>
<ol>
   <li>
      Auxiliary Missile Systems
   </li>
   <ul>
      <li>
         You can only include this Command Asset in your Array if your army includes at least one
         <span class="rules-keyword">Vehicle</span> unit
      </li>
      <li>
         This command asset can be used after a VEHICLE from your army has made a Shoot action. When used, select one
         enemy unit that is visible to that unit and roll one D6. If the result is equal to or greater than your unit’s
         Ballistic Skill characteristic, place one Blast Marker next to that enemy unit - if that unit is
         <span class="rules-keyword">HEAVY</span> or <span class="rules-keyword">SUPER-HEAVY</span>, place two blast
         markers next to it instead.
      </li>
   </ul>
   <li>
      Strategic Reallocation
   </li>
   <ul>
      <li>
         Distribute any number of your command points among your team members, decreasing your Command Point Total and
         increasing their Command Point Totals as needed.
      </li>
      <li>
         Note that this Command Asset card still costs 1 Command Point to play - that command point is spent normally,
         it does not get added to any other players Command Point Total.
      </li>
   </ul>
</ol>
<h2 class="page-header">
   4. Command Asset Card Errata
</h2>
<ol>
   <li>
      Change any card that references the “Command Asset Deck” to reference the “Command Asset Array” instead.
   </li>
</ol>
<h2 class="page-header">
   5. Banned Command Asset Cards
</h2>
<ul>
   <li>
      Tutors, like Strategic Brilliance
   </li>
   <li>
      Things that are hard to keep track of
      <ul>
         <li>
            Hold at all costs - adds obsec to a unit
         </li>
      </ul>
   </li>
   <li>
      The various cards which counter/respond to other actions and slow down gameplay, like:
      <ul>
         <li>
            Agents of Vect
         </li>
         <li>
            Perils of the Warp
         </li>
         <li>
            Deny the Witch
         </li>
         <li>
            Seize the initiative - interrupts normal activation order
         </li>
         <li>
            Minefield - Triggered by opponent activating with a specific order
         </li>
      </ul>
   </li>
   <li>
      “Orbital Strike” style effects
   </li>
   <li>
      Other
      <ul>
         <li>
            Hunter-Killer Missiles - replaced by Auxiliary Missile Systems
         </li>
      </ul>
   </li>
</ul>
<h2 class="page-header">
   6. Datasheet Errata
</h2>
<ol>
   <li>
      Generating Additional Command Assets
      <ol>
         <li>
            Any ability that generates additional command assets instead generates additional command points.
         </li>
         <li>
            For Example, the Astra Militarum “Officer” rule:
            <ul>
               <li>
                  Officer (old): At the start of the Generate Command Assets step, if this unit is a Warlord and is on
                  the battlefield, you generate one extra Command Asset.
               </li>
               <li>
                  Officer (new): At the start of the Generate Command Points step, if this unit is a Warlord and is on
                  the battlefield, you generate one extra Command Point.
               </li>
            </ul>
         </li>
      </ol>
   </li>
</ol>
